<template>
    <div class="toolbar">
        <div class="columns ">
            <div class="column">
                <!--<b-button icon-pack="fas" icon-left="plus" class="is-success" @click="add" title="Dodaj termine"></b-button>-->
                <b-button icon-pack="fas" icon-left="clipboard-list" class="is-success" @click="schedule" title="Uporabi tedenski urnik" v-if="$store.getters.currentClinicRole > 1">Tedenski urnik</b-button>
                <b-button icon-pack="fas" icon-left="print" class="is-info" @click="print" title="Natisni teden">Natisni teden</b-button>
                <b-button icon-pack="fas" icon-left="envelope" class="is-info" @click="notify" title="Obvesti paciente za ta teden">Obvesti paciente</b-button>
                <!--<b-button icon-pack="fas" icon-left="trash-alt" class="is-danger" @click="ccc" title="Odpovej termine"></b-button>-->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Toolbar",
        methods: {
            notify() {
                this.$store.commit('notify', this.$store.getters.currentWeekRange);
                this.$store.commit('modal', 'notify');
            },
            add() {
                this.$store.commit('modal', 'timespanPicker');
            },
            schedule() {
                this.$store.commit('modal', 'scheduleSelect');
            },
            print() {
                this.$store.dispatch('print', null).then(() => window.print());
            }
        }
    }
</script>

<style scoped lang="scss">
.toolbar {
    position: absolute;
    display: flex;
    /*left: 0;*/
    right: 0;
    width: fit-content;
    margin: auto;

    .button {
        margin: 0 0.25em;
    }
}
@media screen and (max-width: 768px){
    .toolbar {
        position: relative;
        margin-bottom: 1em;
    }
}
</style>

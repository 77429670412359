<template>
<Modal>
    <template #header>
        <h1 class="title is-5 no-space-after">
            <span v-if="clinic && clinic.id">Uredi ambulanto</span>
            <span v-else>Nova ambulanta</span>
        </h1>
        <div v-if="clinic.id"><span>{{clinic.title}}</span></div>
    </template>

    <b-field label="Ime" label-position="on-border" v-if="!clinic.id">
        <b-input type="text" v-model="clinic.title" expanded required></b-input>
    </b-field>
    <b-field label="Enota" label-position="on-border">
        <b-select placeholder="Izberite enoto" v-model="clinic.unitId" expanded :disabled="clinic.unitId">
            <option v-for="unit in $store.state.units" :key="unit.id" :value="unit.id">{{unit.title}}</option>
        </b-select>
    </b-field>
    <b-field label="Oddelek" label-position="on-border">
        <b-select placeholder="Izberite oddelek" v-model="clinic.departmentId" expanded required>
            <option v-for="dept in departments" :key="dept.id" :value="dept.id">{{dept.title}}</option>
        </b-select>
    </b-field>

    <hr/>
    <div style="position: relative; min-height: 50px;">
        <h2 class="title is-6 level-left">Dostop</h2>
        <br/>
        <b-field label="Dodaj uporabnika v ambulanto" label-position="on-border">
            <b-autocomplete
                    v-model="user_search_text"
                    :data="users"
                    field="searchName"
                    open-on-focus
                    @select="option => userAdd(option)"
                    :keep-first="true"
                    placeholder="išči po imenu ali priimku"
                    expanded>
                <template slot-scope="props">
                    <div class="">
                        <span class="">{{ props.option.name }} {{ props.option.surname }} </span>
                        <span class="title is-7">
                            {{ props.option.username }}
                            <span v-if="props.option.email">
                                ({{ props.option.email }})
                            </span>
                        </span>
                    </div>
                </template>
            </b-autocomplete>
        </b-field>

        <div class="user-card card" v-for="user in clinicUsers" :key="user.id">
            <div>
                <h2 class="card-title">{{user.name}} {{user.surname}}</h2>
                <h4 class="card-subtitle">
                    {{user.username}}
                    <span v-if="user.email">
                        ({{user.email}})
                    </span>
                </h4>
            </div>
            <div class="card-icons">
                <a @click="userRemove(user.id)">
                    <b-icon pack="fas" icon="user-times" type="is-danger" class="faded on-hover-show" title="Odvzemi dostop"></b-icon>
                </a>
                <a @click="userAdmin(user.id)">
                    <b-icon pack="fas" icon="crown" type="is-info" title="Administrator ambulante" class="faded on-hover-show" v-if="user.role < 2"></b-icon>
                    <b-icon pack="fas" icon="crown" type="is-info" title="Administrator ambulante" v-else></b-icon>
                </a>
            </div>
        </div>
        <b-loading :active.sync="$store.state.loadingClinicUsers" :is-full-page="false"></b-loading>
    </div>
    <br/>
    <br/>
    <br/>

    <template #footer>
        <button class="button " type="button" @click="$parent.close()">Prekliči</button>
        <b-button class="button is-danger" icon-pack="fas" icon-left="trash" type="button" @click="remove">Izbriši</b-button>
        <button class="button is-primary" @click="send">Shrani</button>
    </template>
    <b-loading :active.sync="saveInProgress" :is-full-page="false"></b-loading>
</Modal>
</template>

<script>
    import Modal from "../modals/Modal";
    import Toaster from "../mixins/Toaster";
    import BSelect from "buefy/src/components/select/Select";

    export default {
        extends: Modal,
        name: 'ClinicAdministrationModal',
        components: {BSelect, Modal},
        mixins: [Toaster],
        data() {
            return {
                clinic: this.$store.state.administratingClinic,
                clinicUsers: [],
                user: null,
                user_search_text: '',
                saveInProgress: false,
                clinicUsersToSend: []
            }
        },
        methods: {
            send() {
                if (this.valid) {
                    this.saveInProgress = true;

                    let clinic = {
                        id: this.clinic.id,
                        departmentId: this.clinic.departmentId,
                        title: this.clinic.title,
                        users: [],
                        admins: []
                    };

                    if (this.clinicUsers) {
                        this.clinicUsers.forEach(u => {
                            u.role === 2 ? clinic.admins.push(u.id) : clinic.users.push(u.id)
                        });
                    }

                    this.$store.state.api.post_clinicAdmin(clinic.id, clinic)
                        .then(resp => {
                            if (!resp.error) {
                                this.$store.commit('clinic', resp);
                                this.$parent.close();
                                this.$store.dispatch('reloadUnits');
                                this.toastSavingSuccess();
                            } else {
                                this.toastError("Nekaj je šlo narobe. Morda ambulanta že obstaja.");
                            }
                            this.saveInProgress = false;
                        })
                } else {
                    this.formIncompleteError();
                }
            },
            remove() {
                this.$buefy.dialog.confirm({
                    confirmText: 'Sprosti',
                    cancelText: 'Prekliči',
                    type: 'is-danger',
                    message: 'Ali ste prepričani, da želite izbrisati to ambulanto? Tega dejanja ni mogoče razveljaviti.',
                    onConfirm: () => this.$store.state.api.delete_clinic(this.clinic.id)
                        .then(resp => {
                            if (!resp.error) {
                                this.$parent.close();
                                this.$store.dispatch('reloadUnits');
                            }
                        })
                });
            },
            userAdd(selected) {
                if (selected && this.clinicUsers.findIndex(u => u.id === selected.id) < 0) {
                    selected.role = 1;
                    this.clinicUsers.push(selected);
                }
            },
            userRemove(id) {
                this.clinicUsers = this.clinicUsers.filter((v) => {return v.id !== parseInt(id)});
            },
            userAdmin(id) {
                let u = this.clinicUsers.findIndex(u => u.id === parseInt(id));
                let user = this.clinicUsers[u];
                user.role = user.role === 2 ? 1 : 2;
                this.clinicUsers.splice(u, 1, user);
            }
        },
        computed: {
            users() {
                return this.$store.state.users_search_list.filter(u => {
                    return u.searchName.indexOf(this.user_search_text.toLowerCase()) >= 0
                });
            },
            departments() {
                return this.clinic.unitId ? this.$store.state.departments_search_list.filter(d => { return (d.unitId === this.clinic.unitId)}) : []
            },
            valid() {
                return this.clinic.departmentId && this.clinic.unitId && this.clinic.title;
            }
        },
        mounted() {
            if (this.clinic.id) {
                this.$store.commit('loadingClinicUsers', true);
                this.$store.state.api.fetch_clinic_users(this.clinic.id).then(resp => {
                    this.clinicUsers = resp;
                    this.$store.commit('loadingClinicUsers', false);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .title.is-6 {
        margin-bottom: 0.5em;
    }
    .modal.is-active .modal-card {
        /*width: 20em !important;*/
    }
    .user-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 1em;
        margin: 1em 0;
        line-height: 1.2em;

        .card-subtitle {
            font-weight: 500;
            font-size: 0.9em;
        }

        .card-icons {
            span {
                margin: 0 0.4em;
                cursor: pointer;
            }
            .faded {
                opacity: 0.2;
            }
        }

        .on-hover-show {
            display: inline;
        }

        & .on-hover-show:hover {
            opacity: 1;
        }
    }
</style>

<template>
<Modal>
    <template #header>
        <h1 class="title is-5 no-space-after">
            <span v-if="department && department.id">Uredi oddelek</span>
            <span v-else>Nov oddelek</span>
        </h1>
    </template>
    <b-field label="Ime" label-position="on-border" expanded>
        <b-input type="text" v-model="department.title" min="1"></b-input>
    </b-field>

    <b-field label="Enota" label-position="on-border">
        <b-select placeholder="Izberite enoto" v-model="department.unitId" expanded required>
            <option v-for="unit in $store.state.units" :key="unit.id" :value="unit.id">{{unit.title}}</option>
        </b-select>
    </b-field>

    <template #footer>
        <button class="button " type="button" @click="$parent.close()">Prekliči</button>
        <b-button class="button is-danger" icon-pack="fas" icon-left="trash" @click="remove" v-show="department.id">Izbriši</b-button>
        <button class="button is-primary" @click="send">Shrani</button>
    </template>
    <b-loading :active.sync="saveInProgress" :is-full-page="false"></b-loading>
</Modal>
</template>

<script>
    import Modal from "../modals/Modal";
    import Toaster from "../mixins/Toaster";
    import BSelect from "buefy/src/components/select/Select";


    export default {
        extends: Modal,
        name: 'DepartmentAdministrationModal',
        components: {BSelect, Modal},
        mixins: [Toaster],
        data() {
            return {
                department: this.$store.state.administratingDepartment,
                saveInProgress: false
            }
        },
        methods: {
            send() {
                this.saveInProgress = true;
                this.$store.state.api.post_department(this.department)
                    .then(resp => {
                        if (!resp.error) {
                            this.$emit('reload');
                            this.$parent.close();
                            this.toastSavingSuccess();
                        }
                        this.saveInProgress = false;
                    })
            },
            remove() {
                this.$buefy.dialog.confirm({
                    confirmText: 'Sprosti',
                    cancelText: 'Prekliči',
                    type: 'is-danger',
                    message: 'Ali ste prepričani, da želite izbrisati ta oddelek? Tega dejanja ni mogoče razveljaviti.',
                    onConfirm: () => this.$store.state.api.delete_department(this.department.id)
                        .then(resp => {
                            if (!resp.error) {
                                this.$emit('reload');
                                this.$parent.close();
                            }
                        })
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .title.is-6 {
        margin-bottom: 0.5em;
    }
    .modal.is-active .modal-card {
        /*width: 20em !important;*/
    }
    .user-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 1em;
        margin: 1em 0;
        line-height: 1.2em;

        .card-title {

        }
        .card-subtitle {
            font-weight: 500;
            font-size: 0.9em;
        }
        .card-icons {
            span {
                margin: 0 0.4em;
                cursor: pointer;
            }
            .faded {
                opacity: 0.2;
            }
            .on-hover-show {
                display: none;
            }
        }
        &:hover .on-hover-show {
            display: inline;
        }
    }
</style>

<template>
    <Modal>
        <template #header>
            <div class="columns">
                <div class="column is-1 pl-0">
                    <b-button
                        class="no-print"
                        icon-left="angle-left"
                        @click="backToAllReservations">
                    </b-button>
                </div>
                <div class="column" style="margin: auto;">
                    <div>
                        <b>Termin pregleda: </b>
                        <span>{{ event.startMoment.format('DD. MM. YYYY') }}</span>
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;Ura: </b>
                        <span>{{ event.startMoment.format('HH:mm') }} - {{ event.endMoment.format('HH:mm') }}</span>
                    </div>
                    <div>
                        <b>Ambulanta: </b>
                        <span>{{ $store.state.clinic.title }}</span>
                    </div>
                </div>
            </div>
        </template>
        <form @submit="send" class="free-slot">
            <b-tabs position="is-centered" type="is-toggle">
                <b-tab-item label="Prost termin">
                    <b-field label="Napis za paciente" label-position="on-border">
                        <b-input type="text" v-model="title" disabled></b-input>
                    </b-field>

                    <b-field label="Št. razpisanih mest" label-position="on-border">
                        <b-input
                            class="is-inline-block"
                            type="number"
                            v-model="freeSlotCount"
                            required
                            min="1"
                            max="60"
                            style="width: 130px;">
                        </b-input>
                    </b-field>
                </b-tab-item>
            </b-tabs>
        </form>

        <template #footer>
            <b-button class="button" type="button" @click="backToAllReservations">Prekliči</b-button>
            <b-button
                v-show="!$store.getters.bookingDisabled"
                class="button is-primary"
                @click="send"
                :disabled="loading">
                Shrani termin
                </b-button>
        </template>
    </Modal>
</template>

<script>
import Modal from "./Modal";
import Toaster from "../mixins/Toaster";

export default {
    extends: Modal,
    name: 'BookMultiEditModal',
    components: {Modal},
    mixins: [Toaster],
    props: [],
    data() {
        let slot = this.$store.state.event.extendedProps.slot;

        return {
            freeSlotCount: slot.freeSlotCount || 1,
            loading: false,
            title: slot.title ? slot.title : this.$store.state.clinic.eventFree
        }
    },
    methods: {
        backToAllReservations() {
            this.$parent.close();
            this.$store.commit('modal', 'reservation');
        },
        send(e) {
            e.preventDefault();
            if (!this.formValid) {
                this.formIncompleteError();
                return;
            }

            this.sendBooking();
        },
        sendBooking() {
            this.loading = true;
            let data = {
                slotId: this.slot.id,
                title: this.title,
                freeSlotCount: Number(this.freeSlotCount)
            };

            this.$store.state.api.post_booking(this.$store.getters.isUserAuthenticated, data)
                .then(resp => {
                    if (resp.error) {
                        this.toastError("Nekaj je šlo narobe.");
                    } else {
                        // Update whole reservations array in the event
                        this.$store.dispatch('updateEvent', [undefined, resp.slot]);
                        this.toastSuccess("Termin je bil uspešno posodobljen.");
                        this.backToAllReservations();
                    }
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    computed: {
        event() {
            return this.$store.state.event.extendedProps;
        },
        slot() {
            return this.event.slot;
        },
        formValid() {
            return this.title.length > 0;
        }
    }
}
</script>

<style lang="scss">
.star-field {
    font-size: 20px;
    line-height: 16px;
    font-weight: bold;
}

/* This is ugly hack, to fix hidden calendar popup.*/
.modal .has-overflow.modal-card {
    overflow: visible !important;
}

.modal .has-overflow .modal-card-body {
    overflow: visible !important;
}
</style>

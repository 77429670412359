<template>
    <div class="fc" v-if="$store.getters.isReady">
        <div class="fc-toolbar fc-header-toolbar fc-custom">
            <div class="fc-left">
                <b-tooltip label="Nazaj 4 tedne" position="is-top">
                    <button type="button" class="fc-prev-month-button fc-button fc-button-primary" aria-label="prev month" @click="prevMonth">
                        <span class="fc-icon fc-icon-chevrons-left"></span>
                    </button>
                </b-tooltip>
                <b-tooltip label="Prejšnji teden" position="is-top">
                    <button type="button" class="fc-prev-button fc-button fc-button-primary" :disabled="!isPreviousVisible" aria-label="prev" @click="prev">
                        <span class="fc-icon fc-icon-chevron-left"></span>
                    </button>
                </b-tooltip>
                <button type="button" class="fc-today-button fc-button fc-button-primary" :disabled="isTodayVisible" @click="today">danes</button>
                <b-tooltip label="Naslednji teden" position="is-top">
                    <button type="button" class="fc-next-button fc-button fc-button-primary" aria-label="next" @click="next">
                        <span class="fc-icon fc-icon-chevron-right"></span>
                    </button>
                </b-tooltip>
                <b-tooltip label="Naprej 4 tedne" position="is-top">
                    <button type="button" class="fc-next-month-button fc-button fc-button-primary" aria-label="next month" @click="nextMonth">
                        <span class="fc-icon fc-icon-chevrons-right"></span>
                    </button>
                </b-tooltip>
            </div>
            <div class="fc-center"></div>
        </div>
        <div class="calendar-head-wrapper">
            <table v-if="calendarInit">
                <thead class="fc-head">
                <tr>
                    <td class="fc-head-container fc-widget-header">
                        <div class="fc-row fc-widget-header" style="margin-right: 16px;">
                            <table class="">
                                <thead>
                                <tr>
                                    <th class="fc-axis fc-widget-header" style="width: 40px;"></th>
                                    <th class="fc-day-header fc-widget-header fc-mon">{{calDays[0].format('ddd, D. MMM')}}<DayToolbar :day="calDays[0]"></DayToolbar></th>
                                    <th class="fc-day-header fc-widget-header fc-tue">{{calDays[1].format('ddd, D. MMM')}}<DayToolbar :day="calDays[1]"></DayToolbar></th>
                                    <th class="fc-day-header fc-widget-header fc-wed">{{calDays[2].format('ddd, D. MMM')}}<DayToolbar :day="calDays[2]"></DayToolbar></th>
                                    <th class="fc-day-header fc-widget-header fc-thu">{{calDays[3].format('ddd, D. MMM')}}<DayToolbar :day="calDays[3]"></DayToolbar></th>
                                    <th class="fc-day-header fc-widget-header fc-fri">{{calDays[4].format('ddd, D. MMM')}}<DayToolbar :day="calDays[4]"></DayToolbar></th>
                                    <th class="fc-day-header fc-widget-header fc-sat" v-if="weekends">{{calDays[5].format('ddd, D. MMM')}}<DayToolbar :day="calDays[5]"></DayToolbar></th>
                                    <th class="fc-day-header fc-widget-header fc-sun" v-if="weekends">{{calDays[6].format('ddd, D. MMM')}}<DayToolbar :day="calDays[6]"></DayToolbar></th>
                                </tr>
                                </thead>
                            </table>
                        </div>
                    </td>
                </tr>
                </thead>
            </table>
            <div class="calendar-wrapper">
                <CalendarComponent :editable="editable"></CalendarComponent>
            </div>
        </div>
    </div>
</template>

<script>
    import Toaster from '../mixins/Toaster'
    import DayToolbar from "../DayToolbar";
    import Calendar from "../mixins/Calendar";
    import CalendarComponent from "../calendar/CalendarComponent";

    export default {
        name: "ClinicCalendar",
        components: {
            DayToolbar,
            CalendarComponent
        },
        mixins: [ Toaster, Calendar ],
        data() {
            return {
                nowIndicator: false,
                header: false,
                allDaySlot: false,
                columnHeader: false
            }
        },
        methods: {
            next: function() {
                this.$store.state.calendars['fullCalendar'].next();
                this.$store.commit('calendarWeekOffset', 1);
            },
            prev: function() {
                this.$store.state.calendars['fullCalendar'].prev();
                this.$store.commit('calendarWeekOffset', -1);
            },
            nextMonth: function() {
                // This somehow doesn't work, so I've made an ugly fix
                // this.$store.state.calendars['fullCalendar'].incrementDate(this.$moment.duration(4, 'week'));
                this.$store.state.calendars['fullCalendar'].next();
                this.$store.state.calendars['fullCalendar'].next();
                this.$store.state.calendars['fullCalendar'].next();
                this.$store.state.calendars['fullCalendar'].next();
                this.$store.commit('calendarWeekOffset', 4);
            },
            prevMonth: function() {
                // This somehow doesn't work, so I've made an ugly fix
                // this.$store.state.calendars['fullCalendar'].incrementDate(this.$moment.duration(-4, 'week'));
                this.$store.state.calendars['fullCalendar'].prev();
                this.$store.state.calendars['fullCalendar'].prev();
                this.$store.state.calendars['fullCalendar'].prev();
                this.$store.state.calendars['fullCalendar'].prev();
                this.$store.commit('calendarWeekOffset', -4);
            },
            today: function() {
                this.$store.state.calendars['fullCalendar'].today();
            },
            editable() {
                return this.$store.getters.currentClinicRole > 1
            },
            eventDropOrResize(info) {
                let slotId = info.event.extendedProps.slot.id;
                let data = {
                    start: info.event.start,
                    end: info.event.end
                };

                this.$store.state.api.post_edit_slot(slotId, data)
                    .then(resp => {
                        if (resp.error) {
                            this.toastError(resp.error);
                        } else if (resp) {
                            // TODO update slot data in the event
                            this.$store.dispatch('updateEvent', [info.event, resp]);
                            this.toastSuccess("Termin je bil uspešno posodobljen.");
                        }
                    })

                // TODO after successful drop alert nurse if she wants to notify that patient's slot has been changed
            }
        },
        computed: {
            isTodayVisible() {
                return this.$moment().isBetween(this.calStart, this.calEnd, null, '[]')
            },
            isPreviousVisible() {
                // Check if anonymous user and if date is in the past
                if (this.$store.getters.isUserAuthenticated) {
                    return true;
                }

                return this.isTodayVisible()
            },
            calStart() { return this.$store.getters.currentWeekRange.start },
            calEnd() { return this.$store.getters.currentWeekRange.end },
            calTitle() { return this.$store.state.calendars['fullCalendar'].view ? this.$store.state.calendars['fullCalendar'].view.title : '' },
            calDays() {
                let calDays = [];

                let day = this.calStart.clone();
                while (day && day.isSameOrBefore(this.calEnd)) {
                    calDays.push(day.clone());
                    day.add(1, 'days');
                }
                return calDays;
            },
            calendarInit() {
                return this.$store.state.calendars['fullCalendar']
            }
        }
    }
</script>

<style scoped lang="scss">
    .calendar-head-wrapper .calendar-wrapper {
        max-height: 90vh;
        min-height: 35vh;
        height: 80vh;
        overflow: visible;
    }
    @media screen and (max-width: 768px) {
        .calendar-head-wrapper {
            overflow-x: scroll;

            >table, >div {
                width: 300%;
            }
        }
    }

    .fc-prev-button,
    .fc-next-button {
        padding-left: 6px;
        padding-right: 6px;
    }

    .fc-prev-month-button,
    .fc-next-month-button {
        padding-left: 8px;
        padding-right: 8px;
    }
</style>

<style>
    /* Ugly fix for overflowing today yellow marker on calendar */
    .fc-time-grid {
        min-height: initial !important;
    }

    /*.fc-time-grid-container {*/
    /*    overflow: visible !important;*/
    /*    height: 100% !important;*/
    /*}*/
</style>

<template>
    <div class="admin-view">
        <section class="main">
            <Nav></Nav>
            <br/>
            <div class="container">
                <b-tabs type="is-boxed">
                    <b-tab-item label="Ambulante" icon="clinic-medical" >
                        <Clinics></Clinics>
                    </b-tab-item>

                    <b-tab-item label="Uporabniki" icon="users">
                        <Users></Users>
                    </b-tab-item>

                    <b-tab-item label="Oddelki" icon="hospital">
                        <Departments></Departments>
                    </b-tab-item>

                    <b-tab-item label="Enote" icon="flag">
                        <Units></Units>
                    </b-tab-item>
                </b-tabs>
            </div>

        </section>
    </div>
</template>

<script>
    import Nav from '../components/Nav'
    import Units from "../components/admin/Units";
    import Departments from "../components/admin/Departments";
    import Clinics from "../components/admin/Clinics";
    import Users from "../components/admin/Users";

    export default {
        name: "Clinic",
        components: {Units, Departments, Clinics, Users, Nav}
    }
</script>

<style lang="scss">
    .admin-view {
        .table .button {
            float: right;
        }
    }

</style>

<template>
    <b-navbar class="is-primary navbar-main">
        <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <b>ZD Ljubljana</b>
                <!--<img src="/logo.png">-->
            </b-navbar-item>
        </template>
        <template slot="end">

            <b-navbar-item tag="router-link" to="admin" v-if="amAdmin">
                <b-tooltip label="Administracija" position="is-left">
                  <b-icon icon="tools"></b-icon>
                </b-tooltip>
                <span class="navbar-item-label only-mobile">Administracija</span>
            </b-navbar-item>

            <div class="navbar-item clickable" @click="openUserSettings" v-if="$store.state.user.id">
                <b-tooltip label="Uporabniške nastavitve" position="is-left">
                  <b-icon icon="user-circle"></b-icon>
                </b-tooltip>
                <span class="navbar-item-label only-mobile">Uporabniške nastavitve</span>
            </div>

            <div class="navbar-item clickable" @click="logout" v-if="$store.state.user.id">
                <b-tooltip :label="logoutLabel()" position="is-left">
                  <span class="icon">
                      <i class="fas fa-sign-out-alt"></i>
                  </span>
                </b-tooltip>
                <span class="navbar-item-label only-mobile">Odjava</span>
            </div>

            <b-navbar-item tag="a" href="https://narocanja.zd-lj.si/navodila.pdf" v-if="$store.state.user.id">
                <b-tooltip label="Navodila" position="is-left">
                  <b-icon icon="question"></b-icon>
                </b-tooltip>
                <span class="navbar-item-label only-mobile">Navodila</span>
            </b-navbar-item>

            <b-navbar-item tag="div" v-if="!$store.state.user.id">
                <div class="buttons">
                    <a class="button is-primary is-small" @click="login">Prijava za zaposlene</a>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
    export default {
        name: "Nav",
        methods: {
            login() {
                this.$router.push('login')
            },
            logout(){
              this.$store.dispatch('logout');
            },
            openUserSettings() {
                this.$store.commit('administratingUser', this.$store.state.user);
                this.$store.commit('modal', 'userSettings');
            },
            logoutLabel(){
              return 'Odjava uporabnika '+this.$store.state.user.username;
            }
        },
        computed: {
            amAdmin() {
                return this.$store.state.user.roles && (Object.keys(this.$store.state.user.roles.units).length || this.$store.state.user.roles.system)
            }
        }
    }
</script>

<style scoped lang="scss">
    .navbar-main {
        background-image: linear-gradient(141deg, #218153 0%, #369f7e 71%, #37b8a5 100%);
    }

    .navbar-main.is-primary .navbar-brand > a.navbar-item:hover,
    .navbar-main.is-primary .navbar-brand > a.navbar-item:focus{
        background-color: inherit;
    }

    .navbar-item .navbar-item-label {
        vertical-align: text-bottom;
        margin-left: 0.4em;
    }
    @media screen and (min-width: 1024px) {
        .navbar-start {
            margin-left: 2em;
        }
        .navbar-item .navbar-item-label.only-mobile {
            display: none;
            margin-left: 0.2em;
        }
    }
</style>

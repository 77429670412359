<template>
    <div id="app">
        <router-view/>
        <b-modal :active.sync="$store.state.modals.userSettings" has-modal-card>
            <UserSettingsModal></UserSettingsModal>
        </b-modal>
    </div>
</template>

<script>
    import UserSettingsModal from "./components/modals/UserSettingsModal";

    export default {
        components: {
            UserSettingsModal
        },
        created() {
            this.$store.dispatch('init');
        }
    }
</script>

<style lang="scss">
    @import "./variables.scss";

    #app, body, html {
        height: 100%;
    }

    .clickable {
        cursor: pointer;
    }

    .empty-content h2 {
        text-align: center;
        margin: 5em 0;
        color: #a2a2a2;
    }

    .info-button {
        cursor: pointer;
        vertical-align: text-bottom;
        margin-left: 0.3em;
    }

    .title.no-space-after {
        margin-bottom: 0;
    }

    .only-print {
        display: none;
    }

    .fc-content {
        font-weight: 500;
    }

    @media print {
        .icon-users .fa-users,
        .icon-users {
            color: black !important;
            display: inline-block !important;
        }
        .no-print {
            display: none;
        }


        .only-print {
            display: initial;
        }

        .el-note {
            font-size: 0.85em;
        }

        .fc-title {
            white-space: break-spaces;
            display: inline !important;
        }

        .fc-time {
            font-weight: 500;
        }

        .fc-content {
            padding: 3px;
            overflow: visible;
            white-space: break-spaces;
            font-weight: 400;
        }

        .animation-content {
            max-width: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            margin: 0;
        }


        .fc-content {
            white-space: break-spaces !important;
            overflow: visible;

        }

        * {
            position: relative !important;

        }

        .clinic-view {
            display: block !important;
        }
    }
</style>

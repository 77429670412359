<template>
    <div class="nurse">
        <div class="close"><a @click="close"><b-icon icon="times"></b-icon></a></div>
        <div class="header"><h3 class="title is-5">{{dayTitle}}</h3></div>
        <div>
        <FullCalendar :ref="refName"
                      defaultView="timeGridDay"
                      height="parent"
                      locale="sl"
                      :slotLabelInterval="'01:00'"
                      nowIndicator="true"
                      header="false"
                      columnHeader="false"
                      :allDaySlot="false"
                      :minTime="minTime"
                      :maxTime="maxTime"
                      :slotLabelFormat="labelFormat"
                      :slotDuration="interval"
                      :plugins="calendarPlugins"
                      :eventSources="eventSources"
                      :eventRender="eventRender"
        />
        </div>
    </div>

</template>

<script>
    import FullCalendar from '@fullcalendar/vue'
    import interactionPlugin from '@fullcalendar/interaction';
    import timeGridPlugin from '@fullcalendar/timegrid'
    import momentPlugin from '@fullcalendar/moment'
    import { authUserCalendarDataTransform } from "@/helpers";

    export default {
        name: "SideCal",
        components: {FullCalendar},
        data() {
            return {
                refName: 'asideCal',
                calendarPlugins: [ timeGridPlugin, momentPlugin, interactionPlugin ],
                columnHeaderFormat: 'dddd, D. MMM',
                labelFormat: {
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: false,
                    omitZeroMinute: false,
                    meridiem: false
                },
                header: false,

                eventSources: [
                    {   // calendar
                        id: 1,
                        events: this.calendarES
                    }
                ]

            }
        },
        methods: {
            close() {
                this.$store.commit('aside', false)
            },
            calendarES(info, successCallback, failureCallback) {
                this.$store.commit('loading', true);
                let pub = true;
                if (this.$store.state.user.id && this.$store.getters.currentClinicRole > 1) pub = false;

                this.$store.state.api.fetch_calendar(pub, this.$route.params.clinicId, info.startStr, info.endStr)
                    .then(data => {
                        if (data.error) {
                            failureCallback();
                            this.$store.commit('loading', false);
                        } else {
                            successCallback(data.map(evt => authUserCalendarDataTransform(evt, this.$moment, this.$store.state.clinic.eventBusy)));
                            this.$store.commit('loading', false);
                            this.$store.state.calendars[this.refName].render();
                        }
                    })
            },
            eventRender(info) {
                let event = info.event;

                if (event.extendedProps) {
                    let title = info.el.getElementsByClassName("fc-content");
                    if (title.length && event.extendedProps.reservation && event.extendedProps.reservation.patientName) {
                        title[0].classList.add("reservation");

                        let patientName = event.extendedProps.reservation.patientName;
                        let patientNote = event.extendedProps.reservation.patientNote;
                        let patientFileNumber = event.extendedProps.reservation.patientFileNumber;
                        let patientPhone = event.extendedProps.reservation.patientPhone;
                        let optionalText = '';

                        if (patientFileNumber) optionalText += `<br/><strong>Št. kartoteke:</strong> ${patientFileNumber}`;
                        if (patientPhone) optionalText += `<br/><strong>Telefon:</strong> ${patientPhone}`;
                        if (patientNote) optionalText += `<br/><strong>Opomba:</strong> ${patientNote}`;

                        title[0].innerHTML += ` <div class="hover-info"><strong>Ime:</strong> ${patientName}${optionalText} </div>`;

                        if (event.extendedProps.reservation.attended) {
                            title[0].innerHTML += ' <span class="fa-stack has-text-primary"><i class="fas fa-circle fa-stack-2x"></i><i class="fas fa-check fa-stack-1x has-text-white"></i></span>'
                        }
                    }
                }
            }
        },
        computed: {
            interval() { return this.$store.state.clinic.timeIntervalFormatted },
            minTime() { return this.$store.state.clinic.timeMinFormatted },
            maxTime() { return this.$store.state.clinic.timeMaxFormatted },
            dayTitle() { return this.$store.state.asideDay ? this.$store.state.asideDay.format('dddd, D. MMM') : '' }
        },
        mounted() {
            this.$store.commit('calendar', {'name': this.refName, 'value': this.$refs[this.refName].getApi()});
            this.$store.commit('asideCalendarRef', this.refName);
        }
    }
</script>

<style scoped lang="scss">
    .close {
        position: absolute;
        top: 0;
        right: 0.5em;

    }
    .header {
        text-align: center;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
    }
    .toolbar {
      display: flex; justify-content: space-evenly
    }
</style>
<style lang="scss">
    .aside .fc-head {
    display: none;
    }
</style>

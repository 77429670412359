import axios from 'axios'
import Router from './router';
const moment = require('moment');

let API_BASE = 'https://narocanja.zd-lj.si';
// let API_BASE = 'https://narocanja-test.zd-lj.si';

// API_BASE = 'http://lukavpn.blackblox.si:8084';
// let API_BASE = 'https://apistage.blackblox.si';
// let API_BASE = 'http://localhost:8084';
// let API_BASE = 'https://dev.blackblox.si';
// let API_BASE = 'http://joze.blackblox.si:8084';
// let API_BASE = 'http://jozevpn.blackblox.si:8084';
// let API_BASE = 'https://apitest.blackblox.si';
// let API_BASE = 'https://api.blackblox.si';
// let API_BASE = 'http://192.168.2.24:8084';
// API_BASE = 'http://luka.blackblox.si:8084';
// API_BASE = 'http://192.168.1.6:8084';

let API_PATH = '/v1';
let API_URL = API_BASE + API_PATH;

function storeData (data) {
    let expiry = new Date();
    expiry.setSeconds(expiry.getSeconds() + data.expires_in);

    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    localStorage.setItem('expires', expiry.toString());
    localStorage.removeItem('login-logout-event');
    localStorage.setItem('login-logout-event', 'empty'+ Math.random());
}
function clearStorage () {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires');
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('login-logout-event');
    localStorage.setItem('login-logout-event', 'empty'+ Math.random());
}

export default class {

    constructor () {
        this.calendarPublicEP = API_URL + '/public/calendar/';
        this.calendarInternalEP = API_URL + '/calendar/';

        axios.defaults.withCredentials = true;
        axios.interceptors.request.use(
            (config) => {
                let ep = config.url.split('/').pop();
                if (ep === 'login' || ep === 'password' || config.url.indexOf('/public/') > 0) return config;

                let token = localStorage.getItem('access_token');
                if (token) {
                    let expiry = new Date(localStorage.getItem('expires'));
                    if (expiry > new Date()) {
                        config.headers['Authorization'] = `Bearer ${token}`;
                        return config
                    } else {
                        return this.refresh(localStorage.getItem('refresh_token'))
                            .then((data) => {
                                config.headers['Authorization'] = `Bearer ${data.access_token}`;
                                return config
                            })
                            .catch(error => {
                                console.error(error.stack);
                                clearStorage();
                                return Promise.reject(new Error('reauth'))
                            })
                    }
                } else {
                    return Promise.reject(new Error('reauth'))
                }
            },
            (error) => {
                return Promise.reject(error)
            }
        )
    }

    login (user) {
        let data = new FormData();
        data.append('username', user.username);
        data.append('password', user.password);
        data.append('grant_type', 'password');
        return this.auth(data);
    }
    logout () {
        clearStorage();
        Router.push({ name: 'home'});
    }
    refresh (refresh_token) {
        let data = new FormData();
        data.append('grant_type', 'refresh_token');
        data.append('refresh_token', refresh_token);
        return this.auth(data);
    }
    auth (data) {
        data.append('client_id', 'narocanja');
        data.append('client_secret', 'narocanja');
        return axios.post(API_URL + '/login', data, {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
        })
            .then(response => {
                if (response.data.error) {
                    console.error(response.data)
                } else {
                    storeData(response.data);
                }
                return response.data
            })
            .catch(err => {
                if (err.response && err.response.data.error_description) {
                    if (err.response.data.error_description.indexOf('Invalid refresh token') >= 0) {
                        clearStorage();
                    } else if (err.response.data.error_description.indexOf('Bad credentials') >= 0) {
                        return {error: 'credentials'};
                    }
                }
                return this.handle_error(err);
            })
    }
    forgotPassword(email) {
        return this.generic_get('/public/password/' + encodeURI(email))
            .then(response => { return response })
    }
    resetPassword(data) {
        return this.generic_post('/public/password', data)
            .then(response => { return response })
    }

    handle_error (error) {
        let res = '';
        if (error.response) {
            // The request was made and the server responded with a status code
            console.error(error.response.data);
            if (error.response.status === 404 ) {
                res = {error: '404'};
            } else if (error.response.status >= 500) {
                res = {error: 'server'};
            } else if (error.response.status === 401) {
                Router.push({ name: 'login'});
                res = {error: 'credentials'};
            } else {
                res = {error: error.response.data ? error.response.data : 'other'};
            }
        } else if (error.request) {
            console.error('The request was made but no response was received.');
            res = {error: 'connection'};
        } else if (error.message === 'reauth') {
            // log in is required due to missing token or unsuccessful refresh token attempt.
            Router.push({ name: 'login'});
            res = {error: 'reauth'};
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message);
            res = {error: 'unknown'};
        }
        console.error(error.message);
        return res
    }

    generic_get (path, opt) {
        return axios.get(API_URL + path, opt)
            .then(response => {
                return response.data
            })
            .catch((error) => this.handle_error(error));
    }
    generic_post (path, data) {
        return axios.post(API_URL + path, data)
            .then(response => {
                return response.data
            })
            .catch((error) => this.handle_error(error));
    }

    link_ical (uuid) {
        return API_URL+'/public/reservation/download/'+uuid
    }

    fetch_users () {
        return this.generic_get('/users')
            .then(response => { return response })
    }
    fetch_departments () {
        return this.generic_get('/departments')
            .then(response => { return response })
    }
    fetch_me () {
        return this.generic_get('/user')
            .then(response => { return response })
    }
    fetch_my_clinics () {
        return this.generic_get('/user/clinics')
    }
    fetch_user_clinics(userId) {
        return this.generic_get('/user/' + userId + '/clinics')
            .then(response => { return response })
    }
    fetch_public_units () {
        return this.generic_get('/public/units/')
            .then(response => { return response })
    }
    fetch_public_clinic (clinicId) {
        return this.generic_get('/public/clinic/'+clinicId)
            .then(response => { return response })
    }
    fetch_by_uuid (uuid) {
        return this.generic_get('/public/reservation/'+uuid)
            .then(response => { return response })
    }
    fetch_calendar (pub, clinicId, start, end) {
        return this.generic_get((pub ? '/public' : '') +'/calendar/'+clinicId, {params: {start: start, end: end}})
            .then(response => { return response })
    }
    fetch_clinic_users (clinicId) {
        return this.generic_get('/clinic/'+clinicId+'/users')
            .then(response => {
                let users = [];
                if (response['admins']) for (let u of response['admins']) {
                    u.role = 2;
                    users.push(u);
                }
                if (response['users']) for (let u of response['users']) {
                    u.role = 1;
                    users.push(u)
                }
                return users
            })

    }
    fetch_clinic_schedules (clinicId) {
        return this.generic_get('/clinic/'+clinicId+'/schedules')
            .then(response => {
                // Transform events to be on this week
                response.forEach(
                    schedule => {
                        schedule.events = JSON.parse(schedule.events);

                        schedule.events.map( e => {
                            let start = moment(e.start);
                            let end = moment(e.end);

                            if (e.type === 1) e.color = 'hsl(45,79%,37%)';
                            else e.color = 'hsl(142,81%,41%)';

                            e.start = moment().isoWeekday(start.isoWeekday()).set('hour', start.hour()).set('minute', start.minute()).set('second', start.second()).toISOString();
                            e.end = moment().isoWeekday(end.isoWeekday()).set('hour', end.hour()).set('minute', end.minute()).set('second', end.second()).toISOString();
                        })
                    }
                );
                return response
            })
    }

    fetch_referral_orders (clinicId) {
        return this.generic_get('/clinic/' + clinicId + '/referral-order/')
            .then(response => { return response })
    }

    fetch_referral_order_by_uuid (uuid) {
        return this.generic_get('/public/referral-order/'+uuid)
            .then(response => { return response })
    }

    approve_referral_order (id, data) {
        return this.generic_post('/referral-order/' + id + '/approve', data)
    }

    decline_referral_order (id, data) {
        return this.generic_post('/referral-order/' + id + '/decline', data)
    }

    post_cancel_reservation (uuid, notify) {
        return this.generic_post('/public/cancel/'+uuid, {notify: notify})
    }
    post_booking (isAuthenticated, booking) {
        // If user is authenticated, he can skip recaptcha
        return this.generic_post((isAuthenticated ? '' : '/public') + '/book', booking)
    }
    post_referral_order (isAuthenticated, clinicId, data) {
        return this.generic_post((isAuthenticated ? '' : '/public') + '/clinic/'+ clinicId + '/referral-order', data)
    }
    post_slots (clinicId, slots) {
        return this.generic_post('/clinic/'+clinicId+'/slots', slots)
    }
    post_clinicSettings (clinicId, settings) {
        return this.generic_post('/clinic/'+clinicId, settings)
    }
    post_clinicAdmin (clinicId, settings) {
        if (clinicId) {
            return this.generic_post('/clinic/'+clinicId+'/admin', settings)
        } else {
            return this.generic_post('/clinic', settings)
        }

    }
    post_department(department) {
        return this.generic_post('/department', department)
    }
    post_unit(unit) {
        return this.generic_post('/unit', unit)
    }
    post_announcement (clinicId, announcement) {
        return this.generic_post('/clinic/'+clinicId+'/announcement', announcement)
    }
    post_free_up_slot (slotId) {
        return this.generic_post('/slot/'+slotId+'/free')
    }
    post_notify (pack) {
        return this.generic_post('/reservation/notify', pack)
    }
    change_reservation_status(uuid, data) {
        return this.generic_post('/reservation/' + uuid + '/status', data)
    }
    post_reservation_update(uuid, data) {
        return this.generic_post('/reservation/' + uuid, data)
    }
    post_edit_slot(slotId, data) {
        return this.generic_post('/slot/' + slotId + '/edit', data)
    }
    post_cancel (pack) {
        return this.generic_post('/slot/delete', pack)
    }
    post_user (user) {
        return this.generic_post('/user', user)
    }
    post_schedule (schedule) {
        return this.generic_post('/schedule', schedule)
    }
    post_applySchedule (clinicId, data) {
        return this.generic_post('/clinic/'+clinicId+'/apply', data)
    }



    delete_announcement (clinicId, announcementId) {
        return this.generic_post('/clinic/'+clinicId+'/announcement/'+announcementId+'/delete')
    }
    delete_slot (slotId) {
        return this.generic_post('/slot/'+slotId+'/delete')
    }
    delete_clinic (clinicId) {
        return this.generic_post('/clinic/'+clinicId+'/delete')
    }
    delete_department (departmentId) {
        return this.generic_post('/department/'+departmentId+'/delete')
    }
    delete_unit (unitId) {
        return this.generic_post('/unit/'+unitId+'/delete')
    }
    delete_user (userId) {
        return this.generic_post('/user/'+userId+'/delete')
    }
    delete_schedule (scheduleId) {
        return this.generic_post('/schedule/'+scheduleId+'/delete')
    }
    watch_login_logout(){
        window.addEventListener('storage', function(event){
            if (event.key === 'login-logout-event' ) {
                location.reload() //we get warning for two redirects at logout, but that's just notice
            }
        });
    }
}

<template>
    <Modal>
        <template #header>
            <div class="columns">
                <div class="column is-1 pl-0">
                    <b-button
                        class="no-print"
                        icon-left="angle-left"
                        @click="backToAllReservations">
                    </b-button>
                </div>
                <div class="column" style="margin: auto;">
                    <div>
                        <b>Termin pregleda: </b>
                        <span>{{ event.startMoment.format('DD. MM. YYYY') }}</span>
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;Ura: </b>
                        <span>{{ event.startMoment.format('HH:mm') }} - {{ event.endMoment.format('HH:mm') }}</span>
                    </div>
                    <div>
                        <b>Ambulanta: </b>
                        <span>{{ $store.state.clinic.title }}</span>
                    </div>
                </div>
            </div>
        </template>
        <div v-if="$store.getters.bookingDisabled">
            Naročanje na ta termin ni mogoče.
        </div>
        <form v-else @submit="send">
            <b-tabs position="is-centered" type="is-toggle">
                <b-tab-item label="Naročanje">
                    <div style="margin-bottom: 20px;">
                        Polja označena z zvezdico <span class="star-field has-text-primary">*</span> so obvezna.
                    </div>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            Ime in priimek<span class="star-field has-text-primary">*</span>
                        </template>
                        <b-input type="text" v-model="name" :required="fieldRequired('name')"></b-input>
                    </b-field>
                    <b-field label="Datum rojstva" label-position="on-border">
                        <b-datepicker v-model="birth" :date-formatter="dateFormat" :date-parser="dateParse"
                                      :required="fieldRequired('birth')" editable></b-datepicker>
                    </b-field>
                    <b-field grouped group-multiline>
                        <b-field label="E-naslov" label-position="on-border" expanded>
                            <b-input type="email" v-model="email" :required="fieldRequired('email')"></b-input>
                        </b-field>
                        <b-field label="Telefon" label-position="on-border" expanded>
                            <b-input type="text" v-model="phone" :required="fieldRequired('phone')"></b-input>
                        </b-field>
                    </b-field>
                    <b-field label="Naslov" label-position="on-border">
                        <b-input type="text" v-model="address"></b-input>
                    </b-field>
                    <b-field v-if="isSchoolClinic" label-position="on-border" label="Šola">
                        <b-input v-model="schoolName" type="text"></b-input>
                    </b-field>
                    <b-field label="Opomba/razlog obravnave" label-position="on-border">
                        <b-input type="textarea" v-model="reason" rows="2"></b-input>
                    </b-field>

                    <hr/>

                    <b-field label="Št. kartoteke" label-position="on-border">
                        <b-input type="text" v-model="file"></b-input>
                    </b-field>
                </b-tab-item>
            </b-tabs>
        </form>

        <template #footer>
            <b-button class="button" type="button" @click="backToAllReservations">Prekliči</b-button>
                <b-button
                    v-show="!$store.getters.bookingDisabled"
                    class="button is-primary"
                    @click="send"
                    :disabled="loading">
                    Naroči
                </b-button>

        </template>
    </Modal>
</template>

<script>
import Modal from "./Modal";
import Toaster from "../mixins/Toaster";

export default {
    extends: Modal,
    name: 'BookMultiModal',
    components: {Modal},
    mixins: [Toaster],
    props: [],
    data() {
        let slot = this.$store.state.event.extendedProps.slot;

        return {
            name: '',
            email: '',
            phone: '',
            schoolName: '',
            birth: null,
            address: '',
            reason: '',
            file: '',
            freeSlotCount: slot.freeSlotCount || 1,
            requiredFields: [
                "name"
            ],
            loading: false
        }
    },
    methods: {
        backToAllReservations() {
            this.$parent.close();
            this.$store.commit('reservation', {});
            this.$store.commit('modal', 'reservation');
        },
        send(e) {
            e.preventDefault();
            if (!this.formValid) {
                this.formIncompleteError();
                return;
            }

            if (this.$store.getters.currentClinicRole > 1) {
                this.sendBooking();
            } else {
                this.$buefy.dialog.confirm({
                    confirmText: 'Potrdi',
                    cancelText: 'Prekliči',
                    message: 'Naročili se boste za termin ' + this.event.startMoment.format('DD. MM. YYYY') + ' ob ' + this.event.startMoment.format('HH:mm'),
                    onConfirm: () => this.sendBooking()
                });
            }
        },
        sendBooking() {
            this.loading = true;

            let data = {
                slotId: this.slot.id,
                patientName: this.name,
                patientEmail: this.email,
                patientPhone: this.phone,
                patientBirthday: this.birth,
                patientAddress: this.address,
                patientSchoolName: this.schoolName,
                patientNote: this.reason,
                patientFileNumber: this.file,
                book_patient: true
            };

            this.$store.state.api.post_booking(this.$store.getters.isUserAuthenticated, data)
                .then(resp => {
                    if (!resp.error) {
                        this.loading = false;
                        this.$store.dispatch('updateEvent', [undefined, resp.slot]);
                        this.toastBookingSuccess();
                        this.backToAllReservations();
                    } else {
                        this.toastError("Nekaj je šlo narobe.");
                    }
                })
        },
        dateFormat(d) {
            return this.$moment(d).format('D. M. YYYY');
        },
        dateParse(s) {
            return new this.$moment(s, 'DD. MM. YYYY').toDate();
        },
        fieldRequired(field) {
            return this.requiredFields.includes(field)
        }
    },
    computed: {
        event() {
            return this.$store.state.event.extendedProps;
        },
        slot() {
            return this.event.slot;
        },
        isSchoolClinic() {
            return this.$store.getters.currentClinic.school;
        },
        formValid() {
            return this.name.length > 0;
        }
    }
}
</script>

<style lang="scss">
.star-field {
    font-size: 20px;
    line-height: 16px;
    font-weight: bold;
}

/* This is ugly hack, to fix hidden calendar popup.*/
.modal .has-overflow.modal-card {
    overflow: visible !important;
}

.modal .has-overflow .modal-card-body {
    overflow: visible !important;
}
</style>

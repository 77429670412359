<template>
    <div>
        <div class="nurse">
            <div class="columns clinic-head">
                <div class="column">
                    <h1 class="title no-margin">{{$store.state.clinic.title}}</h1>
                    <ul class="icon-list">
                        <li v-if="$store.getters.currentClinicRole > 1">
                            <b-icon pack="fas" icon="cog" size="is-small" class="has-text-primary"></b-icon>
                            <a href="#" @click="$store.commit('modal', 'clinicSettings')"> Nastavitve ambulante</a>
                        </li>
                        <li>
                            <b-icon pack="fas" icon="bullhorn" size="is-small" class="has-text-primary"></b-icon>
                            <a href="#" @click="newAnnouncement"> Novo obvestilo</a>
                        </li>
                        <li v-if="$store.getters.currentClinicRole > 1">
                            <b-icon pack="fas" icon="clipboard-list" size="is-small" class="has-text-primary"></b-icon>
                            <a href="#" @click="$store.commit('largeModal', 'schedules')"> Urniki</a>
                        </li>
                    </ul>

                    <div class="mt-5" v-if="$store.state.clinic.referralPrescriptionEnabled">
                        <b-button
                            icon-left="notes-medical"
                            class="is-info"
                            @click="$store.dispatch('openReferralOrders')">
                            Napotnice in recepti
                        </b-button>
                    </div>
                </div>
                <div class="column">
                    <Announcements></Announcements>
                </div>
            </div>

            <Toolbar></Toolbar>
            <ClinicCalendar></ClinicCalendar>
        </div>
        <b-modal :active.sync="$store.state.modals.newSlot" has-modal-card class="priority-modal">
            <NewSlotModal></NewSlotModal>
        </b-modal>
        <b-modal :active.sync="$store.state.modals.clinicSettings" has-modal-card>
            <ClinicSettingsModal></ClinicSettingsModal>
        </b-modal>
        <b-modal :active.sync="$store.state.modals.timespanPicker" has-modal-card>
            <TimespanPickerModal></TimespanPickerModal>
        </b-modal>
        <b-modal :active.sync="$store.state.modals.scheduleSelect" has-modal-card>
            <ScheduleSelectModal></ScheduleSelectModal>
        </b-modal>
        <b-modal :active.sync="$store.state.modals.scheduleSlot" has-modal-card class="priority-modal schedule-modal">
            <ScheduleSlotModal></ScheduleSlotModal>
        </b-modal>
        <b-modal :active.sync="$store.state.modals.announcement" has-modal-card>
            <AnnouncementModal></AnnouncementModal>
        </b-modal>
        <b-modal :active.sync="$store.state.modals.cancel" has-modal-card>
            <NotifyModal :cancel="true"></NotifyModal>
        </b-modal>
        <b-modal :active.sync="$store.state.modals.notify" has-modal-card>
            <NotifyModal :cancel="false"></NotifyModal>
        </b-modal>
        <b-modal :active.sync="$store.state.modals.large" has-modal-card class="large-modal">
            <SchedulesModal></SchedulesModal>
        </b-modal>
        <b-modal :active.sync="$store.state.modals.referralList" has-modal-card>
            <ReferralListModal></ReferralListModal>
        </b-modal>
        <b-modal :active.sync="$store.state.modals.referralConfirm" has-modal-card>
            <ReferralConfirmModal :cancel="true"></ReferralConfirmModal>
        </b-modal>
    </div>
</template>

<script>
    import NewSlotModal from '../modals/NewSlotModal'
    import ClinicSettingsModal from "../modals/ClinicSettingsModal";
    import TimespanPickerModal from "../modals/TimespanPickerModal";
    import AnnouncementModal from "../modals/AnnouncementModal";
    import NotifyModal from "../modals/NotifyModal";
    import SchedulesModal from "../modals/large/SchedulesModal";
    import ScheduleSelectModal from "../modals/ScheduleSelectModal";
    import ScheduleSlotModal from "../modals/scheduleSlotModal";
    import Toolbar from "../calendar/Toolbar";
    import ClinicCalendar from "../calendar/ClinicCalendar"
    import Announcements from "./Announcements";
    import ReferralListModal from "@/components/modals/ReferralListModal";
    import ReferralConfirmModal from "@/components/modals/ReferralConfirmModal";
    export default {
        name: "Nurse",
        components: {
            Announcements,
            Toolbar,
            ClinicCalendar,
            ScheduleSelectModal,
            ScheduleSlotModal,
            SchedulesModal,
            TimespanPickerModal,
            ClinicSettingsModal,
            ReferralListModal,
            ReferralConfirmModal,
            NewSlotModal,
            AnnouncementModal,
            NotifyModal
        },
        data () {
            return {
                refreshInterval: null
            }
        },
        methods: {
            newAnnouncement() {
                this.$store.commit('modal', 'announcement');
                this.$store.commit('announcement', {})
            },
            refreshCalendarData() {
                // Refresh calendar every 30 seconds
                console.log("refreshCalendarData")
                this.refreshInterval = setInterval(() => {
                    this.$store.dispatch('refetch');
                }, 120000)
            }
        },
        created() {
             this.refreshCalendarData();
        },
        beforeDestroy() {
            clearInterval(this.refreshInterval)
        }
    }
</script>

<style scoped lang="scss">
    .priority-modal {
        z-index: 50;
    }

    .box {
        .content {
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }
        .level .title,
        .level .button {
            display: inline-block;
            margin: 0;
        }
    }
    .icon-list {
        margin-top: 0.5em;
        margin-left: 0.5em;

        li * {
            margin-right: 0.5em;
        }
    }
</style>

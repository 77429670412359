<template>
    <div class="toolbar">
        <b-button size="is-small" icon-pack="fas" icon-left="thumbtack" class="is-primary" @click="pin" title="Pripni"></b-button>
        <b-dropdown position="is-bottom-left">
            <b-button slot="trigger" size="is-small" icon-pack="fas" icon-left="ellipsis-h" class="is-dark" title="Več..."></b-button>
            <b-dropdown-item @click="print"><b-icon icon="print"></b-icon> Natisni</b-dropdown-item>
            <b-dropdown-item @click="notify"><b-icon icon="envelope"></b-icon> Obvesti paciente</b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item @click="cancel"><b-icon icon="trash-alt"></b-icon> Izbriši termine</b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
    export default {
        name: "Toolbar",
        props: ['day'],
        methods: {
            add() {
                this.$store.commit('modal', 'timespanPicker');
            },
            notify() {
                this.$store.commit('notify', {start: this.day, end: this.day.clone().add(24, 'hours')});
                this.$store.commit('modal', 'notify');
            },
            cancel() {
                this.$store.commit('notify', {start: this.day, end: this.day.clone().add(1, 'days')});
                this.$store.commit('modal', 'cancel');
            },
            print() {
                this.$store.dispatch('print', this.day).then(() => window.print());
            },
            pin() {
                this.$store.commit('aside', this.day)
            }
        }
    }
</script>

<style scoped lang="scss">
.toolbar {
    display: flex;
    left: 0;
    right: 0;
    width: fit-content;
    margin: auto;

    .button {
        margin: 0 0.25em;
    }
}
</style>

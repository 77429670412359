export function cloneTime(moment, time) {
    let result = moment.clone();
    result.set('hours', time.split(':')[0]);
    result.set('minutes', time.split(':')[1]);
    return result;
}

export function normalizeString(input) {
    return input.toLowerCase().replace(/[.,\-:;]/g, '').replace(/[čć]/g, 'c').replace(/ž/g, 'z').replace(/š/g, 's');
}

export function eventColor (name) {
    let colors = {
        blue: 'hsl(204, 77%, 49%)',
        green: 'hsl(142,81%,41%)',
        yellow: 'hsl(45,79%,37%)',
        red: 'hsl(0,82%,61%)'
    };
    return colors[name] ? colors[name] : '';
}


export function authUserCalendarDataTransform(data, $moment, eventBusyTitle) {
    let event = {
        id: data.id,
        start: data.start,
        end: data.end,
        startMoment: $moment(data.start),
        endMoment: $moment(data.end),
        allDay: false,
        title: data.title,
        slot: data
    };

    if (data.type === 1) {
        event.color = eventColor('yellow');
    } else if (data.reservations.length > 0) {
        if (data.freeSlotCount === 1) {
            event.title = data.reservations[0].patientName;
        } else{
            if(eventBusyTitle && data.freeSlotCount <= data.reservations.length){
                event.title = eventBusyTitle;
            }
        }

        if (data.freeSlotCount - data.reservations.length <= 0) {
            event.color = eventColor('red');
        } else {
            event.color = eventColor('green');
            // event.title = `${data.reservations.length}/${data.freeSlotCount}`
        }
    } else {
        event.color = eventColor('green');
    }

    return event;
}
<template>
<section class="section is-medium">
    <div class="container">
        <form @submit="submit">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <img class="logo" src="../assets/logot.png" title="Zdravstveni dom Ljubljana">
                </header>
                <section class="modal-card-body">
                    <b-field :label="usernameLabel">
                        <b-input type="text" v-model="user.username" placeholder="" required></b-input>
                    </b-field>

                    <b-field label="Geslo" v-show="!forgotPassword">
                        <b-input type="password" v-model="user.password" placeholder="" :required="!forgotPassword"></b-input>
                    </b-field>
                </section>
                <footer class="modal-card-foot">
                    <div  v-if="forgotPassword" class="rows">
                        <div class="row is-full">
                            <button class="button" type="button" @click="forgotPassword = false">Nazaj</button>
                            <button class="button is-primary is-pulled-right">Pridobi novo geslo</button>
                        </div>
                    </div>
                    <div v-else class="rows">
                        <div class="row is-full">
                            <button class="button" type="button" @click="$router.back()">Nazaj</button>
                            <button class="button is-primary is-pulled-right">Vstop</button>
                        </div>
                        <div class="row is-full forgot-password">
                            <a @click="forgotPassword = true">Ste pozabili geslo?</a>
                        </div>
                    </div>
                </footer>
            </div>
        </form>
        <b-loading :is-full-page="true" :active.sync="$store.getters.isLoading" :can-cancel="true"></b-loading>
    </div>
</section>
</template>

<script>
    import Toaster from "../components/mixins/Toaster";

    export default {
        name: "Login",
        mixins: [Toaster],
        data() {
            return {
                user: {
                    username: '',
                    password: ''
                },
                forgotPassword: false
            }
        },
        methods: {
            submit(e) {
                this.$store.commit('loading', true);
                e.preventDefault();

                // If forgot password parameter link was clicked, request forgot password email
                if (this.forgotPassword) {
                    this.$store.state.api.forgotPassword(this.user.username)
                        .then(resp => {
                            // If error has occured, show error toast
                            if (!resp || resp.error) {
                                this.toastError("E-naslov ne obstaja.");
                            } else {
                                // No error, show success toast
                                this.toastSuccess("Na vaš E-naslov smo vam poslali nadaljna navodila za ponastavitev gesla.");
                            }
                            this.$store.commit('loading', false);
                        })
                } else {
                    // Try to login user
                    this.$store.state.api.login(this.user)
                        .then(resp => {
                            if (resp.access_token) {
                                this.$store.state.api.fetch_me()
                                    .then(resp => this.userData(resp))
                            } else {
                                this.toastLoginFail(resp.error);
                                this.$store.commit('loading', false);
                            }
                        })
                }
            },
            userData(data) {
                if (data.id) {
                    this.$store.dispatch('loggedin', data);
                    // TODO this is not ok, if user starts app on /login url, there is no history and app can't go back
                    this.$router.back();
                }
                else {
                    this.toastLoginFail(data)
                }
                this.$store.commit('loading', false);
            }
        },
        computed: {
            usernameLabel() {
                if (this.forgotPassword)
                    return "E-naslov";
                else
                    return "Uporabniško ime";
            }
        }
    }
</script>

<style scoped lang="scss">
    .section {
        background-image: linear-gradient(141deg, #218153 0%, #369f7e 71%, #37b8a5 100%);
        height: 100%;
    }
     .container {
         max-width: 20em;
     }

    .modal-card-foot {
        justify-content: space-between;
    }
    .logo {
        text-align: center;
        margin: 0.5em auto;
    }

    .rows {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .forgot-password {
        margin: 5px auto 0 auto;
    }
</style>

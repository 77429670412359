<template>
    <Modal>
        <template #header>
            <div><b>Termin pregleda: </b><span>{{ event.startMoment.format('dddd')}}</span> <b>&nbsp;&nbsp;&nbsp;&nbsp;Ura: </b><span>{{ event.startMoment.format('HH:mm') }} - {{ event.endMoment.format('HH:mm') }}</span></div>
            <div><b>Ambulanta: </b><span>{{$store.state.clinic.title}}</span></div>
        </template>

        <form @submit="send">
            <b-tabs v-model="activeTab" position="is-centered" type="is-toggle">
                <b-tab-item label="Prost termin">
                    <b-field label="Napis za paciente" label-position="on-border">
                        <b-input type="text" v-model="title_free"></b-input>
                    </b-field>
                    <br />

                    <b-field grouped>
                        <b-field label="Št. razpisanih mest" label-position="on-border">
                            <b-input
                                class="is-inline-block"
                                type="number"
                                v-model="freeSlotCount"
                                required
                                min="1"
                                max="60"
                                style="width: 130px;">
                            </b-input>
                        </b-field>
                        <b-checkbox v-model="multiple" style="margin-left: 2.5rem;">Ustvari več terminov</b-checkbox>
                    </b-field>
                    <br /><br />
                    <b-field label="Trajanje v minutah" label-position="on-border" v-if="multiple">
                        <b-numberinput step="1" v-model="duration"></b-numberinput>
                    </b-field>
                </b-tab-item>

                <b-tab-item>
                    <template slot="header">
                        <span>Drugo</span>
                    </template>
                    <b-field label="Napis za paciente" label-position="on-border">
                        <b-input type="text" v-model="title_other"></b-input>
                    </b-field>
                    <br />
                    <b-checkbox v-model="multiple">Ustvari več terminov</b-checkbox>
                    <br /><br />
                    <b-field label="Trajanje termina v minutah" label-position="on-border" v-if="multiple">
                        <b-numberinput step="1" v-model="duration"></b-numberinput>
                    </b-field>
                </b-tab-item>
            </b-tabs>
        </form>

        <template #footer>
            <button class="button " type="button" @click="$parent.close()">Prekliči</button>
            <button class="button is-primary" @click="send">Ustvari</button>
        </template>
    </Modal>
</template>

<script>
    import Modal from "./Modal";
    import Toaster from "../mixins/Toaster";
    import { cloneTime } from "../../helpers";
    import BTabItem from "buefy/src/components/tabs/TabItem";
    import { eventColor } from "@/helpers";

    export default {
        name: 'ScheduleSlotModal',
        components: {
            BTabItem,
            Modal
        },
        mixins: [Toaster],
        data() {
            return {
                freeSlotCount: 1,
                activeTab: 0,
                title_free: this.$store.state.clinic.eventFree,
                title_other: this.$store.state.clinic.eventOther,
                duration: parseInt(this.$store.state.clinic.eventLength),
                multiple: false
            }
        },
        methods: {
            makeSlots(from, to, duration, type, title, freeSlotCount) {
                let slots = [];
                let slotColor = type === 0 ? eventColor('green') : eventColor('yellow');

                if (duration < 1) {
                    slots.push({
                        start: from.format(),
                        end: to.format(),
                        title: title,
                        type: type,
                        freeSlotCount: freeSlotCount,
                        isScheduleSlot: true,
                        color: slotColor,
                        comment: ''
                    })
                } else {
                    let current = from.clone();
                    let timeMin = cloneTime(current, this.$store.state.clinic.timeMin);
                    let timeMax = cloneTime(current, this.$store.state.clinic.timeMax);

                    while (current.isBefore(to)) {
                        let next = current.clone().add(duration, 'minutes');
                        if (next.isBetween(timeMin, timeMax, null, '[]')) {
                            slots.push({
                                start: current.format(),
                                end: next.format(),
                                title: title,
                                type: type,
                                freeSlotCount: freeSlotCount,
                                isScheduleSlot: true,
                                color: slotColor,
                                comment: ''
                            });
                        }
                        if (next.get('date') !== current.get('date')) {
                            timeMin = cloneTime(next, this.$store.state.clinic.timeMin);
                            timeMax = cloneTime(next, this.$store.state.clinic.timeMax);
                        }
                        current = next;
                    }
                }
                return slots;
            },
            send(e) {
                e.preventDefault();
                let slots = this.makeSlots(
                    this.event.startMoment,
                    this.event.endMoment,
                    this.multiple ? this.duration : 0,
                    this.getActiveType,
                    this.getActiveTitle,
                    Number(this.freeSlotCount || 1)
                );
                this.$store.dispatch('addSlots', slots);
                this.$parent.close()
            }
        },
        computed: {
            event() {
                return this.$store.state.event;
            },
            getActiveTitle() {
                if (this.activeTab === 0) {
                    return this.title_free || this.$store.state.clinic.eventFree;
                } else {
                    return this.title_other || this.$store.state.clinic.eventOther;
                }
            },
            getActiveType() {
                if (this.activeTab === 0) {
                    return 0;
                } else {
                    return 1;
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "../../colors.scss";

    .schedule-modal .tabs.is-toggle li:nth-child(1).is-active a {
        background-color: $cal-green;
        border-color: $cal-green;
    }
    .schedule-modal .tabs.is-toggle li:nth-child(2).is-active a {
        background-color: $cal-yellow;
        border-color: $cal-yellow;
    }
</style>

<template>
    <div>
        <div class="level">
            <div class="level-left"></div>
            <div class="level-right">
                <b-button class="button is-success" icon-left="plus" @click="newUser">Nov uporabnik</b-button>
            </div>
        </div>

        <b-field>
            <b-input placeholder="Išči po imenu, priimku ali uporabniškem imenu" type="search" icon="search" v-model="search_person"></b-input>
        </b-field>
        <div>
            <b-table
                    :data="people"
                    :paginated="false"
                    :sort-icon="'arrow-up'"
                    :sort-icon-size="'is-small'"
                    :loading="$store.state.loadingClinicUsers"
                    default-sort="username">
                <b-table-column field="username" label="Uporabnik" v-slot="props" sortable>
                    {{ props.row.username }}
                </b-table-column>
                <b-table-column field="name" label="Ime" v-slot="props" sortable>
                    {{ props.row.name }} {{ props.row.surname }}
                </b-table-column>
                <b-table-column field="email" label="E-naslov" v-slot="props" sortable>
                    <a :href="'mailto:props.row.email'">{{ props.row.email }}</a>
                </b-table-column>
                <b-table-column v-slot="props">
                    <b-button class="button is-info" icon-pack="fas" icon-left="pencil-alt" @click="openUserSettings(props.row)">
                        Uredi
                    </b-button>
                </b-table-column>
            </b-table>
        </div>
    </div>
</template>

<script>
    import {normalizeString} from "../../helpers";
    import BTableColumn from "buefy/src/components/table/TableColumn";

    export default {
        name: "Clinics",
        components: {BTableColumn},
        data () {
            return {
                search_person: ''
            }
        },
        methods: {
            user(user) {
               if (user) {
                   return {
                       id: user.id,
                       username: user.username,
                       name: user.name,
                       surname: user.surname,
                       email: user.email,
                       password: user.password
                   }
               } else {
                   return {
                       id: null,
                       username: null,
                       name: null,
                       surname: null,
                       email: null,
                       password: null
                   }
               }
            },
            openUserSettings(user) {
                this.$store.commit('administratingUser', this.user(user));
                this.$store.commit('modal', 'userSettings');
            },
            newUser() {
                this.openUserSettings()
            }
        },
        computed: {
            people() {
                return this.$store.state.users_search_list.filter((option) => {
                    return (
                        (option.searchName && option.searchName.indexOf(normalizeString(this.search_person)) >= 0) ||
                        (option.searchUsername && option.searchUsername.indexOf(normalizeString(this.search_person)) >= 0) ||
                        (option.email && option.email.indexOf(normalizeString(this.search_person)) >= 0 ))
                })
            }
        },
        mounted() {
            this.$store.dispatch('reloadUsers');
        }
    }
</script>

<style scoped>

</style>

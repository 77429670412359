<template>
    <div>
        <div class="message is-danger" v-show="!hasEvents">
            <div class="message-body">
                V tem tednu ni razpisanih terminov.
            </div>
        </div>
        <CalendarComponent/>
    </div>
</template>

<script>
    import Toaster from '../mixins/Toaster'
    import Calendar from '../mixins/Calendar'
    import CalendarComponent from "./CalendarComponent";
    import {eventColor} from "@/helpers";
    export default {
        name: "PublicCalendar",
        components: {CalendarComponent },
        mixins: [ Toaster, Calendar ],
        data() {
            return {
                nowIndicator: false,
                selectable: false,
                allDaySlot: false,
                columnHeader: true,
                // columnHeaderFormat: 'ddd, D. MMM',
                header: {
                    left:   'prev today next',
                    center: '',
                    right:  'title'
                },

                selectOverlap: function(event) {
                    return event.rendering === 'background';
                },

                newEventSources: [
                    {   // my reservation
                        id: 2,
                        events: this.myReservation
                    }
                ],
                hasEvents: false
            }
        },
        methods: {
            calendarES(info, successCallback, failureCallback) {
                this.$store.commit('loading', true);
                let pub = true;
                if (this.$store.getters.isUserAuthenticated && this.$store.getters.currentClinicRole > 1) pub = false;

                this.$store.state.api.fetch_calendar(pub, this.$route.params.clinicId, info.startStr, info.endStr)
                    .then(data => {
                        if (data.error) {
                            failureCallback();
                            this.$store.commit('loading', false);
                        } else {
                            this.hasEvents = data.length > 0;

                            successCallback(data.map(evt => this.calendarDataTransform(evt)));
                            this.$store.commit('loading', false);
                        }
                    })
            },
            calendarDataTransform(data) {
                let event = {
                    id: data.id,
                    start: data.start,
                    end: data.end,
                    startMoment: this.$moment(data.start),
                    endMoment: this.$moment(data.end),
                    allDay: false,
                    title: data.title,
                    slot: data
                };

                if (data.type === 1) {
                    event.color = eventColor('yellow');
                    event.classNames = ['busy'];
                } else if (data.reservations.length > 0) {
                    if (data.freeSlotCount - data.reservations.length > 0) {
                        // There are still free slots.
                        event.color = eventColor('green');
                        // event.title = `${data.reservations.length}/${data.freeSlotCount}`
                    } else {
                        // No open slots anymore, slot is filled up.
                        event.classNames = ['busy'];
                        event.color = eventColor('red');
                        event.title = data.reservations[0].patientName || 'Zaseden termin';
                    }
                } else {
                    event.color = eventColor('green');
                }

                return event;
            },
            myReservation (info, successCallback) {
                if (this.$store.state.uuid.length > 0) {
                    this.$store.state.api.fetch_by_uuid(this.$store.state.uuid)
                        .then(data => {
                            if (data.error) {
                                this.toastReservationNotFound();
                                successCallback([]);
                            } else if (!data.error && data.reservation && data.reservation.id) {
                                // Create event in the calendar
                                let slot = data.slot;
                                slot.reservation = data.reservation;

                                let event = {
                                    id: slot.id,
                                    start: slot.start,
                                    end: slot.end,
                                    startMoment: this.$moment(slot.start),
                                    endMoment: this.$moment(slot.end),
                                    color: eventColor('blue'),
                                    classNames: ['reservation'],
                                    allDay: false,
                                    title: "Vaš termin",
                                    slot: slot
                                };

                                if (!this.$store.state.uuidFetched) {
                                    this.$store.commit('event', event);
                                    this.$store.commit('reservation', data.reservation); // TODO unnecessary maybe
                                    this.$store.commit('modal', 'reservation');
                                    this.$store.commit('uuidFetched', true);
                                }
                                successCallback([event]);
                            }
                        })
                } else {
                    successCallback([])
                }
            },
            eventClick: function(info) {
                let slot = info.event.extendedProps.slot;
                if (info.event.rendering !== 'background' && slot.type === 0) {
                    let reservation = slot.reservation;

                    // Check if this is the anonymous user's reservation, fetched by uuid or he just booked.
                    if (reservation && reservation.id) {
                        this.$store.commit('modal', 'reservation');
                    } else if (this.$store.getters.isUserAuthenticated && (slot.reservations.length > 0 || info.event.source.id === '2')) {
                        this.$store.commit('modal', 'reservation');
                    } else if (slot.freeSlotCount - slot.reservations.length > 0) {
                        // Anonymous user can only open a slot, if it is free (green), has free slots.
                        this.$store.commit('event', info.event);
                        this.$store.commit('modal', 'booking');
                    }
                }
            }
        },
        mounted () {
            this.newEventSources.forEach(src => this.$store.state.calendars['fullCalendar'].addEventSource(src));
        }
    }
</script>
<style scoped>
    .message.is-danger{
        margin-top: 1rem;
    }
</style>
<style lang="scss">
    @media screen and (max-width: 768px) {
        .patient .fc-view-container {
            overflow-x: scroll;
        }
        .patient .fc-view.fc-timeGridWeek-view.fc-timeGrid-view {
            width: 300%;
        }
    }
</style>

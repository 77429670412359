<template>
    <Modal>
        <template #header>
            <div>
                <b>Termin pregleda: </b>
                <span>{{ event.startMoment.format('DD. MM. YYYY') }}</span>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;Ura: </b>
                <span>{{ event.startMoment.format('HH:mm')}} - {{ event.endMoment.format('HH:mm') }}</span>
            </div>
            <div>
                <b>Ambulanta: </b>
                <span>{{ $store.state.clinic.title }}</span>
            </div>
        </template>
        <div v-if="$store.getters.bookingDisabled">
            Naročanje na ta termin ni mogoče.
        </div>
        <form v-else @submit="send">
            <b-tabs v-model="activeTab" position="is-centered" type="is-toggle" v-if="$store.getters.isUserAuthenticated">
                <b-tab-item label="Naročanje">
                    <div style="margin-bottom: 20px;">
                        Polja označena z zvezdico <span class="star-field has-text-primary">*</span> so obvezna.
                    </div>

                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            Ime in priimek<span class="star-field has-text-primary">*</span>
                        </template>
                        <b-input type="text" v-model="name" :required="fieldRequired('name')"></b-input>
                    </b-field>

                    <b-field grouped>
                        <b-field label="E-naslov" label-position="on-border" expanded>
                            <b-input type="email" v-model="email" :required="fieldRequired('email')"></b-input>
                        </b-field>
                        <b-field label="Telefon" label-position="on-border" expanded>
                            <b-input type="text" v-model="phone" :required="fieldRequired('phone')"></b-input>
                        </b-field>
                    </b-field>

                    <b-field label="Datum rojstva" label-position="on-border">
                        <b-datepicker v-model="birth" :date-formatter="dateFormat" :date-parser="dateParse" :required="fieldRequired('birth')" editable></b-datepicker>
                    </b-field>

                    <b-field label="Naslov" label-position="on-border">
                        <b-input type="text" v-model="address"></b-input>
                    </b-field>

                    <b-field v-if="isSchoolClinic" label-position="on-border" label="Šola">
                        <b-input v-model="schoolName" type="text"></b-input>
                    </b-field>

                    <b-field label="Opomba/razlog obravnave" label-position="on-border">
                        <b-input type="textarea" v-model="reason" rows="2"></b-input>
                    </b-field>

                    <hr/>

                    <b-field label="Št. kartoteke" label-position="on-border">
                        <b-input type="text" v-model="file"></b-input>
                    </b-field>
                </b-tab-item>

                <b-tab-item label="Prost termin">
                    <b-field label="Napis za paciente" label-position="on-border">
                        <b-input type="text" v-model="title" disabled></b-input>
                    </b-field>

                    <b-field label="Št. razpisanih mest" label-position="on-border">
                        <b-input
                            class="is-inline-block"
                            type="number"
                            v-model="freeSlotCount"
                            required
                            min="1"
                            max="60"
                            style="width: 130px;">
                        </b-input>
                    </b-field>
                </b-tab-item>

                <b-tab-item>
                    <template slot="header">
                        <span>Drugo</span>
                    </template>
                    <b-field label="Napis za paciente" label-position="on-border">
                        <b-input type="text" v-model="title_other" minlength="1"></b-input>
                    </b-field>
                </b-tab-item>
            </b-tabs>

            <div v-else>
                <div style="margin-bottom: 20px;">
                    Polja označena z zvezdico <span class="star-field has-text-primary">*</span> so obvezna.
                </div>
                <b-field grouped>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            Ime in priimek<span class="star-field has-text-primary">*</span>
                        </template>
                        <b-input type="text" v-model="name" required></b-input>
                    </b-field>
                </b-field>

                <b-field grouped>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            E-naslov <span v-if="fieldRequired('email')" class="star-field has-text-primary">*</span>
                        </template>
                        <b-input type="email" v-model="email" :required="fieldRequired('email')"></b-input>
                    </b-field>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            Telefon <span v-if="fieldRequired('phone')" class="star-field has-text-primary">*</span>
                        </template>
                        <b-input type="text" v-model="phone" :required="fieldRequired('phone')"></b-input>
                    </b-field>
                </b-field>

                <b-field label-position="on-border">
                    <template slot="label">
                        Datum rojstva <span v-if="fieldRequired('birth')" class="star-field has-text-primary">*</span>
                    </template>
                    <b-datepicker v-model="birth" :required="fieldRequired('birth')" :date-formatter="dateFormat"></b-datepicker>
                </b-field>

                <b-field label="Naslov" label-position="on-border">
                    <b-input type="text" v-model="address"></b-input>
                </b-field>

                <b-field v-if="isSchoolClinic" label-position="on-border">
                    <template slot="label">
                        Šola <span class="star-field has-text-primary">*</span>
                    </template>
                    <b-input v-model="schoolName" type="text"></b-input>
                </b-field>

                <b-field label="Opomba/razlog obravnave" label-position="on-border">
                    <b-input type="textarea" v-model="reason" rows="2"></b-input>
                </b-field>

                <hr/>

                <div>
                    <span class="has-text-primary font-weight-bold star-field" style="margin-right: 8px">*</span>
                    <b-checkbox v-model="accept" required>Privolitev za obdelavo osebnih podatkov</b-checkbox>
                    <span class="info-button" @click="$store.commit('modal', 'gdpr')"><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></span>
                    <br/><br/>
                </div>
                <Recaptcha ref="recaptcha" @verify="captchaVerify"></Recaptcha>
            </div>
        </form>

        <template #footer>
            <b-button class="button" type="button" @click="$parent.close()">Prekliči</b-button>
            <b-button class="button is-danger" icon-pack="fas" icon-left="trash" type="button" @click="remove" v-if="$store.state.user.id">Izbriši</b-button>
            <span v-if="$store.getters.isUserAuthenticated">
                <span v-if="activeTab === 0">
                    <b-button v-show="!$store.getters.bookingDisabled" class="button is-primary" @click="send" :disabled="loading">Naroči</b-button>
                </span>
                <span v-else-if="activeTab === 1">
                    <b-button v-show="!$store.getters.bookingDisabled" class="button is-primary" @click="send" :disabled="loading">Shrani prost termin</b-button>
                </span>
                <span v-else-if="activeTab === 2">
                    <b-button v-show="!$store.getters.bookingDisabled" class="button is-primary" @click="send" :disabled="loading">Shrani drugo</b-button>
                </span>
            </span>
            <span v-else>
                <b-button v-show="!$store.getters.bookingDisabled" class="button is-primary" @click="send" :disabled="loading">Naroči se</b-button>
            </span>
        </template>

        <b-modal :active.sync="$store.state.modals.gdpr" has-modal-card>
            <GdprModal></GdprModal>
        </b-modal>
    </Modal>
</template>

<script>
    import Recaptcha from '../Recaptcha'
    import Modal from "./Modal";
    import Toaster from "../mixins/Toaster";
    import GdprModal from "./GdprModal";

    export default {
        extends: Modal,
        name: 'BookModal',
        components: { Modal, Recaptcha, GdprModal },
        mixins: [Toaster],
        props: [],
        data() {
            let event = this.$store.state.event;
            let slot = event.extendedProps.slot;

            return {
                activeTab: slot.type > 0 ? 2 : 0,  // type 0 is free, type 1 is other
                name: '',
                email: '',
                phone: '',
                schoolName: '',
                birth: null,
                address: '',
                reason: '',
                accept: false,
                recaptcha: '',
                file: '',
                freeSlotCount: slot.freeSlotCount || 1,
                nurseRequiredFields: [
                    "name"
                ],
                publicRequiredFields: [
                    "name",
                    "email",
                    "birth",
                    "phone",
                    "accept"
                ],
                loading: false,
                title_busy: this.$store.state.clinic.eventBusy,
                title: slot.type === 0 && event.title ? event.title : this.$store.state.clinic.eventFree, // TODO do i need event.title or slot.title?
                title_other: slot.type > 0 && event.title ? event.title : this.$store.state.clinic.eventOther
            }
        },
        methods: {
            captchaVerify(token) {
                this.recaptcha = token;
            },
            send(e) {
                e.preventDefault();
                if (!this.formValid) {
                    this.formIncompleteError();
                    return;
                }
                if (!this.$store.getters.isUserAuthenticated && this.recaptcha.length < 1) {
                    this.recaptchaError();
                    return;
                }

                if (this.$store.getters.currentClinicRole > 1) {
                    this.sendBooking();
                } else {
                    this.$buefy.dialog.confirm({
                        confirmText: 'Potrdi',
                        cancelText: 'Prekliči',
                        message: 'Naročili se boste za termin '+this.event.startMoment.format('DD. MM. YYYY') + ' ob '+this.event.startMoment.format('HH:mm'),
                        onConfirm: () => this.sendBooking()
                    });
                }
            },
            sendBooking() {
                this.loading = true;

                let data = {
                    slotId: this.slot.id,
                    patientName: this.name,
                    patientEmail: this.email,
                    patientPhone: this.phone,
                    patientBirthday: this.birth,
                    patientAddress: this.address,
                    patientSchoolName: this.schoolName,
                    patientNote: this.reason,
                    freeSlotCount: Number(this.freeSlotCount),
                    patientFileNumber: this.file,
                    captchaResponse: this.recaptcha
                };

                if (this.activeTab === 0) {
                    data['book_patient'] = true;
                } else if (this.activeTab === 1) {
                    data['title'] = this.title;
                    // data['title_busy'] = this.title_busy;
                } else if (this.activeTab === 2) {
                    data['title_other'] = this.title_other;
                }

                this.$store.state.api.post_booking(this.$store.getters.isUserAuthenticated, data)
                    .then(resp => {
                        if (!resp.error) {
                            this.$parent.close();

                            if (!this.$store.getters.isUserAuthenticated && resp.uuid) {
                                resp.slot.reservation = resp.reservation;
                            }

                            this.$store.dispatch('updateEvent', [undefined, resp.slot]);

                            if (this.activeTab === 2 || this.activeTab === 3) {
                                this.toastSuccess("Termin je bil uspešno posodobljen.");
                            } else {
                                this.toastBookingSuccess();

                                // If user is anonymous, open modal with his reservation data.
                                if (this.$store.getters.isUserAuthenticated) {
                                    // Else if slot can have multiple slots, open table view, or just close modal.
                                    if (this.slot.freeSlotCount > 1) {
                                        this.$store.commit('reservation', {});
                                        this.$store.commit('modal', 'reservation');
                                    }
                                } else {
                                    this.$store.commit('uuid', resp.uuid);
                                    this.$store.commit('reservation', resp.reservation);
                                    this.$store.commit('modal', 'reservation');
                                }
                            }
                            this.loading = false;
                        } else if (resp.error === "slot_filled") {
                            // Slot was booked in the time this user tried to book it.
                            this.$store.dispatch('refetch');
                            this.$parent.close();
                            this.toastError("Nekdo je pravkar zasedel ta termin.");
                        } else {
                            this.toastError("Nekaj je šlo narobe.");
                        }
                    })
            },
            remove() {
                this.$buefy.dialog.confirm({
                    confirmText: 'Izbriši',
                    cancelText: 'Prekliči',
                    type: 'is-danger',
                    message: 'Ali ste prepričani, da želite izbrisati termin '+this.event.startMoment.format('DD. MM. YYYY')+' ob '+this.event.startMoment.format('HH:mm') + '? Tega dejanja ni mogoče razveljaviti!',
                    onConfirm: () => this.$store.state.api.delete_slot(this.slot.id)
                        .then(resp => {
                            if (!resp.error) {
                                this.$parent.close();
                                this.toastCancellationSuccess();
                                this.$store.dispatch('refetch');
                            } else {
                                this.toastGenericFail(resp);
                            }
                        })
                });
            },
            dateFormat(d) {
                return this.$moment(d).format('D. M. YYYY');
            },
            dateParse(s) {
                return new this.$moment(s, 'DD. MM. YYYY').toDate();
            },
            fieldRequired(field) {
                let requiredFields = this.$store.state.user.id  ? this.nurseRequiredFields : this.publicRequiredFields;
                return requiredFields.includes(field)
            }
        },
        computed: {
            event() {
                return this.$store.state.event.extendedProps;
            },
            slot() {
                return this.event.slot;
            },
            isSchoolClinic() {
                return this.$store.getters.currentClinic.school;
            },
            formValid() {
                // If user is authenticated, only name is a required field.
                if (this.$store.state.user.id) {
                    if (this.activeTab === 0) {
                        return this.name.length > 0;
                    } else if (this.activeTab === 1) {
                        return this.title.length > 0;
                    } else if (this.activeTab === 2) {
                        return this.title_other.length > 0;
                    }
                }

                if (this.isSchoolClinic) {
                    if (!(this.schoolName.length > 0)) return false;
                }
                // Other users have other required fields also.
                return (this.name.length > 0 && this.email.length > 0 && this.phone.length > 0 && this.birth && this.accept)
            }
        }
    }
</script>

<style>
    .star-field {
        font-size: 20px;
        line-height: 16px;
        font-weight: bold;
    }

    /* This is ugly hack, to fix hidden calendar popup.*/
    .modal .has-overflow.modal-card {
        overflow: visible !important;
    }

    .modal .has-overflow .modal-card-body {
        overflow: visible !important;
    }

    .modal .modal-card-body form > div > .field {
        margin-bottom: 1rem;
    }

    .modal .tab-item > .field {
        margin-bottom: 18px;
    }
</style>

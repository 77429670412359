<template>
    <Modal>
        <template #header>
            <div>
                <b>Naročanje napotnic in receptov</b>
            </div>
            <div>
                <b>Ambulanta: </b>
                <span>{{ $store.state.clinic.title }}</span>
            </div>
        </template>
        <div v-if="referralOrder.id">
            <div>
                <strong>
                    Datum:
                </strong>
                {{ getReferralOrderPropOrSlash(referralOrder, 'created', true) }}
            </div>
            <div>
                <strong>
                    Ime in priimek:
                </strong>
                {{ getReferralOrderPropOrSlash(referralOrder, 'patientName') }}
            </div>
            <div v-if="referralOrder.patientEmail">
                <strong>
                    E-naslov:
                </strong>
                {{ getReferralOrderPropOrSlash(referralOrder, 'patientEmail') }}
            </div>
            <div v-if="referralOrder.patientPhone">
                <strong>
                    Telefon:
                </strong>
                {{ getReferralOrderPropOrSlash(referralOrder, 'patientPhone') }}
            </div>

            <div v-if="referralOrder.patientBirthday">
                <strong>
                    Datum rojstva:
                </strong>
                {{ getReferralOrderPropOrSlash(referralOrder, 'patientBirthday', true) }}
            </div>
            <div v-if="referralOrder.patientAddress">
                <strong>
                    Naslov:
                </strong>
                {{ getReferralOrderPropOrSlash(referralOrder, 'patientAddress') }}
            </div>
            <div v-if="referralOrder.patientZzzs">
                <strong>
                    ZZZS številka:
                </strong>
                {{ getReferralOrderPropOrSlash(referralOrder, 'patientZzzs') }}
            </div>
            <div v-if="referralOrder.patientNote">
                <strong>
                    Opomba/razlog obravnave:
                </strong>
                {{ getReferralOrderPropOrSlash(referralOrder, 'patientNote') }}
            </div>
            <div v-if="referralOrder.patientFileNumber">
                <strong>
                    Št. kartoteke:
                </strong>
                {{ getReferralOrderPropOrSlash(referralOrder, 'patientFileNumber') }}
            </div>

            <div class="mt-4" v-if="referralOrder.referrals.length > 0">
                <div class="mb-2">
                    <strong>Napotnice</strong>
                </div>
                <b-table
                    :data="referralOrder.referrals"
                    striped
                    narrowed>
                    <b-table-column field="index" label="#" v-slot="props">
                        {{ props.index + 1 }}
                    </b-table-column>

                    <b-table-column field="activity" label="Specialistična aktivnost" v-slot="props">
                        {{ props.row.activity }}
                    </b-table-column>

                    <b-table-column field="description" label="Opis" v-slot="props">
                        {{ props.row.description }}
                    </b-table-column>
                </b-table>
            </div>

            <div class="mt-4" v-if="referralOrder.prescriptions.length > 0">
                <div class="mb-2">
                    <strong>Recepti</strong>
                </div>
                <b-table
                    :data="referralOrder.prescriptions"
                    striped
                    narrowed>
                    <b-table-column field="index" label="#" v-slot="props">
                        {{ props.index + 1 }}
                    </b-table-column>

                    <b-table-column field="medicine" label="Zdravilo" v-slot="props">
                        {{ props.row.medicine }}
                    </b-table-column>
                </b-table>
            </div>

            <div class="mt-4" v-if="referralOrder.approved">
                <strong>Opomba:</strong>
                {{ referralOrder.note }}
            </div>
        </div>

        <form @submit="send" v-else>
            <div style="margin-bottom: 20px;" v-if="!$store.getters.isUserAuthenticated">
                Napotnico je možno naročiti samo za kontrolne preglede. Za ostale napotnice se povežite z vašim izbranim zdravnikom.<br><br>
                Polja označena z zvezdico <span class="star-field has-text-primary">*</span> so obvezna.
            </div>
            <b-field grouped>
                <b-field label-position="on-border" expanded>
                    <template slot="label">
                        Ime in priimek<span class="star-field has-text-primary">*</span>
                    </template>
                    <b-input type="text" v-model="name" required></b-input>
                </b-field>
            </b-field>

            <b-field grouped>
                <b-field label-position="on-border" expanded>
                    <template slot="label">
                        E-naslov <span v-if="fieldRequired('email')" class="star-field has-text-primary">*</span>
                    </template>
                    <b-input type="email" v-model="email" :required="fieldRequired('email')"></b-input>
                </b-field>
                <b-field label-position="on-border" expanded>
                    <template slot="label">
                        Telefon <span v-if="fieldRequired('phone')" class="star-field has-text-primary">*</span>
                    </template>
                    <b-input type="text" v-model="phone" :required="fieldRequired('phone')"></b-input>
                </b-field>
            </b-field>

            <b-field label-position="on-border">
                <template slot="label">
                    Datum rojstva <span v-if="fieldRequired('birth')" class="star-field has-text-primary">*</span>
                </template>
                <b-datepicker v-model="birth" :required="fieldRequired('birth')" :date-formatter="dateFormat"></b-datepicker>
            </b-field>

            <b-field label="Naslov" label-position="on-border">
                <b-input type="text" v-model="address"></b-input>
            </b-field>

            <b-field label-position="on-border" message="9 mestna ZZZS številka je zapisana na vaši kartici zdravstvenega zavarovanja nad imenom in priimkom ali na vaši evropski kartici zdravstvenega zavarovanja">
              <template slot="label">
                ZZZS številka <span v-if="fieldRequired('zzzs')" class="star-field has-text-primary">*</span>
              </template>
              <b-input type="text" pattern="^(?:[0-9]{8}|[0-9]{9})$" v-model="zzzs" :required="fieldRequired('zzzs')"></b-input>
            </b-field>

            <div>
                <div class="mb-2">
                    <strong>Napotnice</strong> (največ 3)
                </div>

                <div v-for="(extra, index) in referrals" :key="extra.id">
                    <div class="mt-2 mb-3">
                        Napotnica #{{ index + 1 }}
                    </div>
                    <div>
                        <b-field grouped group-multiline>
                            <b-field label-position="on-border" expanded>
                                <template slot="label">
                                    Specialistična dejavnost <span class="star-field has-text-primary">*</span>
                                </template>
                                <b-input type="text" v-model="extra.activity" required></b-input>
                            </b-field>
                            <p class="control">
                                <b-button class="button is-danger" icon-pack="fas" icon-left="times" type="button" @click="removeReferral(index)"></b-button>
                            </p>
                        </b-field>

                        <b-field label-position="on-border" expanded>
                            <template slot="label">
                                Opis bolezni <span class="star-field has-text-primary">*</span>
                            </template>
                            <b-input type="text" v-model="extra.description" required></b-input>
                        </b-field>
                    </div>
                </div>
                <b-button
                    class="button is-primary mt-4"
                    icon-pack="fas"
                    icon-left="plus"
                    type="button"
                    :disabled="referrals.length >= 3"
                    @click="addReferral">
                    Dodaj napotnico
                </b-button>
            </div>
            <hr/>

            <div>
                <div class="mb-3">
                    <strong>Recepti</strong> (največ 12)
                </div>

                <div v-for="(extra, index) in prescriptions" :key="extra.id">
                    <div class="mt-2 mb-3">
                        Recept #{{ index + 1 }}
                    </div>
                    <div>
                        <b-field grouped group-multiline>
                            <b-field label-position="on-border" expanded>
                                <template slot="label">
                                    Zdravilo <span class="star-field has-text-primary">*</span>
                                </template>
                                <b-input type="text" v-model="extra.medicine" required></b-input>
                            </b-field>
                            <p class="control">
                                <b-button class="button is-danger" icon-pack="fas" icon-left="times" type="button" @click="removePrescription(index)"></b-button>
                            </p>
                        </b-field>
                    </div>
                </div>
                <b-button
                    class="button is-primary mt-4"
                    icon-pack="fas"
                    icon-left="plus"
                    type="button"
                    :disabled="prescriptions.length >= 12"
                    @click="addPrescription">
                    Dodaj recept
                </b-button>
            </div>

            <div class="mt-5" v-if="!$store.getters.isUserAuthenticated">
                <span class="has-text-primary font-weight-bold star-field" style="margin-right: 8px">*</span>
                <b-checkbox v-model="accept" required>Privolitev za obdelavo osebnih podatkov</b-checkbox>
                <span class="info-button" @click="$store.commit('modal', 'gdpr')"><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></span>
                <br/><br/>
                <Recaptcha ref="recaptcha" @verify="captchaVerify"></Recaptcha>
            </div>
        </form>

        <template #footer>
            <b-button  class="button" type="button" @click="$parent.close()"><span v-if="referralOrder.id">Zapri</span><span v-else>Prekliči</span></b-button>
            <b-button
                class="button is-primary"
                @click="send"
                v-if="!referralOrder.id"
                :disabled="loading || (referrals.length === 0 && prescriptions.length === 0)">
                Naroči
            </b-button>
        </template>
        <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        <b-modal :active.sync="$store.state.modals.gdpr" has-modal-card>
            <GdprModal></GdprModal>
        </b-modal>
    </Modal>
</template>

<script>
    import Recaptcha from '../Recaptcha'
    import Modal from "./Modal";
    import Toaster from "../mixins/Toaster";
    import GdprModal from "./GdprModal";

    export default {
        extends: Modal,
        name: 'ReferralOrderModal',
        components: { Modal, Recaptcha, GdprModal },
        mixins: [Toaster],
        data() {
            return {
                name: '',
                email: '',
                phone: '',
                birth: null,
                address: '',
                zzzs: '',
                reason: '',
                accept: false,
                recaptcha: '',
                referrals: [],
                prescriptions: [],
                file: '',
                nurseRequiredFields: [
                    "name"
                ],
                publicRequiredFields: [
                    "name",
                    "email",
                    "birth",
                    "phone",
                    "accept",
                    "zzzs"
                ],
                loading: false
            }
        },
        methods: {
            getReferralOrderPropOrSlash(referralOrder, property, isDate) {
                let text = '/';
                if (referralOrder.id && referralOrder[property]) {
                    text = referralOrder[property];
                    if (isDate) {
                        text = this.$moment(text).format('D. M. YYYY');
                    }
                }
                return text;
            },
            addReferral() {
                this.referrals.push({
                    activity: '',
                    description: ''
                });
            },
            removeReferral(index) {
                this.referrals.splice(index, 1);
            },
            addPrescription() {
                this.prescriptions.push({
                    medicine: ''
                });
            },
            removePrescription(index) {
                this.prescriptions.splice(index, 1);
            },
            captchaVerify(token) {
                this.recaptcha = token;
            },
            send(e) {
                e.preventDefault();
                if (!this.formValid()) {
                    this.formIncompleteError();
                    return;
                }
                if (!this.referralValid()) {
                    return;
                }
                if (!this.$store.getters.isUserAuthenticated && this.recaptcha.length < 1) {
                    this.recaptchaError();
                    return;
                }

                if (this.$store.getters.currentClinicRole > 1) {
                    this.sendRefferalOrder();
                } else {
                    let referralCount = this.referrals.length;
                    let prescriptionCount = this.prescriptions.length;
                    let message = 'Naročili boste naslednje ';
                    if (referralCount > 0 && prescriptionCount > 0) {
                        message += '<strong>napotnice:</strong> <br />';
                        for (let r of this.referrals) {
                            message += `- ${r.activity} (${r.description})<br />`;
                        }

                        message += '<br /><strong>recepte:</strong><br />';
                        for (let p of this.prescriptions) {
                            message += '- ' + p.medicine + '<br />';
                        }
                    } else if (referralCount > 0) {
                        message += '<strong>napotnice:</strong> <br />';
                        for (let r of this.referrals) {
                            message += `- ${r.activity} (${r.description})<br />`;
                        }
                    } else if (prescriptionCount > 0) {
                        message += '<strong>recepte:</strong> <br />';
                        for (let p of this.prescriptions) {
                            message += '- ' + p.medicine + '<br />';
                        }
                    } else {
                        message += 'napotnice in recepte'
                    }
                    this.$buefy.dialog.confirm({
                        confirmText: 'Potrdi',
                        cancelText: 'Prekliči',
                        message: message,
                        onConfirm: () => this.sendRefferalOrder()
                    });
                }
            },
            sendRefferalOrder() {
                this.loading = true;

                let data = {
                    patientName: this.name,
                    patientEmail: this.email,
                    patientPhone: this.phone,
                    patientBirthday: this.birth,
                    patientAddress: this.address,
                    patientZzzs: this.zzzs,
                    patientNote: this.reason,
                    patientFileNumber: this.file,
                    captchaResponse: this.recaptcha,
                    referrals: this.referrals,
                    prescriptions: this.prescriptions
                };

                this.$store.state.api.post_referral_order(
                    this.$store.getters.isUserAuthenticated,
                    this.$store.state.clinic.id,
                    data
                )
                    .then(resp => {
                        if (!resp.error) {
                            this.$store.dispatch('referralOrder', resp);
                            if (this.$store.getters.isUserAuthenticated) {
                                this.$store.dispatch('fetchReferralOrders');
                            }
                            // this.$store.state.api.fetch_referral_order_by_uuid(resp.uuid)
                            //     .then(order => {
                            //        console.log(order);
                            //     });
                            this.toastSuccess("Naročilnice/recepti so naročeni.");
                            this.loading = false;
                        } else {
                            this.toastError("Nekaj je šlo narobe.");
                            this.loading = false;
                            this.$refs.recaptcha.reset()

                        }
                    })
            },
            dateFormat(d) {
                return this.$moment(d).format('D. M. YYYY');
            },
            fieldRequired(field) {
                let requiredFields = this.$store.state.user.id  ? this.nurseRequiredFields : this.publicRequiredFields;
                return requiredFields.includes(field)
            },
            referralValid() {
                // At least 1 referral or prescription must be included
                if (this.referrals.length === 0 && this.prescriptions.length === 0) {
                    this.toastError("Potrebno je dodati vsaj eno naročilnico ali recept.");
                    return false;
                }

                for (let i = 0; i < this.referrals.length; i++) {
                    let referral = this.referrals[i];
                    let activity = referral.activity;
                    let description = referral.description;

                    if (!activity || !description) {
                        this.toastError("Naročilnica #" + (i+1) + " mora imeti izpolnjena vsa vnosna polja.");
                        return false;
                    }
                }

                for (let i = 0; i < this.prescriptions.length; i++) {
                    let medicine = this.prescriptions[i].medicine;

                    if (!medicine) {
                        this.toastError("Recept #" + (i+1) + " mora imeti izpolnjena vsa vnosna polja.");
                        return false;
                    }
                }
                return true
            },
            formValid() {
                // If user is authenticated, only name is a required field.
                if (this.$store.state.user.id) {
                    return this.name.length > 0;
                }

                // Other users have other required fields also.
                return (this.name.length > 0 && this.email.length > 0 && this.phone.length > 0 && this.birth && this.accept)
            }
        },
        computed: {
            referralOrder() {
                return this.$store.state.referralOrder;
            }
        }
    }
</script>

<template>
    <div class="patient">
        <div class="columns clinic-head">
            <div class="column ">
                <h1 class="title no-margin">{{$store.state.clinic.title}}</h1>
                <ul class="icon-list">
                    <li v-if="workHours">
                        <b-icon pack="far" icon="clock" size="is-small" class="has-text-primary"></b-icon>
                        {{workHours}}
                    </li>
                    <li v-if="$store.state.clinic.address">
                        <b-icon pack="fas" icon="home" size="is-small" class="has-text-primary"></b-icon>
                        <a :href="gmaps($store.state.clinic.address)" target="_blank"> {{$store.state.clinic.address}}</a>
                    </li>
                    <li v-if="$store.state.clinic.email">
                        <b-icon pack="fas" icon="at" size="is-small" class="has-text-primary"></b-icon>
                        <a :href="'mailto:'+$store.state.clinic.email"> {{$store.state.clinic.email}}</a>
                    </li>
                    <li v-if="$store.state.clinic.phone">
                        <b-icon pack="fas" icon="phone" size="is-small" class="has-text-primary"></b-icon>
                        <span> {{$store.state.clinic.phone}}</span>
                    </li>
                </ul>

                <div class="mt-5" v-if="$store.state.clinic.referralPrescriptionEnabled">
                    <b-button icon-left="notes-medical" class="is-info" @click="openModal">Naroči napotnico ali recept</b-button>
                </div>
            </div>
            <div class="column">
                <Announcements></Announcements>
            </div>
        </div>
        <div class="calendar-wrapper" v-if="$store.getters.isReady">
            <PublicCalendar></PublicCalendar>
        </div>
    </div>
</template>

<script>
    import PublicCalendar from "../calendar/PublicCalendar";
    import Announcements from "./Announcements";
    export default {
        name: "Patient",
        components: {Announcements, PublicCalendar},
        methods: {
            gmaps (address) {
                return 'https://www.google.com/maps/place/'+address
            },
            openModal() {
                this.$store.dispatch('openReferralOrderModal');
            }
        },
        computed: {
            workHours() {
                if (this.$store.state.clinic.timeMinFormatted && this.$store.state.clinic.timeMaxFormatted) {
                    return this.$store.state.clinic.timeMin + " - " + this.$store.state.clinic.timeMax
                }
                return ''
            }
        }
    }
</script>

<style scoped lang="scss">
    .calendar-wrapper {
        height: 100vh;
    }
</style>

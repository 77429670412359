<template>
    <div>
        <b-modal v-if="isUserAuthenticated && edit" :active.sync="edit" has-modal-card>
            <ReservationEditModal :reservationProp="reservation"></ReservationEditModal>
        </b-modal>
        <div v-else>
            <div class="container reservations-list" v-if="isUserAuthenticated && showReservationsList">
                <!-- All slot's reservations, if there are many. -->
                <Modal>
                    <template #header>
                        <div class="columns pt-2 pb-2">
                            <div class="column mb-0">
                                <h2 class="title is-5">
                                    Naročeni pacienti ({{ reservations.length }}/{{ slot.freeSlotCount }})
                                </h2>
                            </div>
                            <div class="column is-2 pb-1 pt-1 ">
                                <b-button class="no-print mb-0" type="is-primary" icon-left="edit" @click="editBooking">
                                    Uredi
                                </b-button>
                            </div>
                        </div>
                    </template>
                    <div class="mb-4">
                        <b>Napis za paciente:</b>
                        <span v-if="freeSlotCount>0">
                            {{ slot.title }}
                        </span>
                        <span v-else>
                            {{ $store.state.clinic.eventBusy }}
                        </span>
                        <br/>

                        <b>Termin pregleda: </b>
                        <span>
                            {{ event.startMoment.format('DD. MM. YYYY') }}
                            ({{ event.startMoment.format('H:mm') }} - {{ event.endMoment.format('H:mm') }})
                        </span>
                        <br/>

                        <span>
                            <b>Št. prostih mest:</b>
                            <b-tag
                                class="ml-2"
                                style="font-size: 0.9rem;"
                                :type="freeSlotCount === 0 ? 'is-danger' : 'is-success'"
                                attached>
                                {{ reservations.length }}/{{ slot.freeSlotCount }}
                            </b-tag>
                        </span>
                    </div>
                    <b-table
                        :data="reservations"
                        striped
                        narrowed
                        style="margin-bottom: 40px;"
                        hoverable>
                        <b-table-column field="patientName" label="Ime in priimek" v-slot="props">
                            {{ props.row.patientName }}
                        </b-table-column>

                        <b-table-column field="patientEmail" label="E-naslov" v-slot="props">
                            {{ props.row.patientEmail }}
                        </b-table-column>

                        <b-table-column field="attended" width="50" label="Prišel" centered v-slot="props">
                            <b-tooltip
                                :label="dateTimeParse(props.row.attended)"
                                position="is-bottom"
                                v-if="props.row.attended">
                                <b-icon
                                    pack="fas"
                                    icon="check"
                                    size="is-small"
                                    type="is-success">
                                </b-icon>
                            </b-tooltip>
                            <b-icon
                                v-else
                                pack="fas"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                        </b-table-column>

                        <b-table-column field="prescription" width="50" label="Recept izdan" centered v-slot="props">
                            <b-tooltip
                                :label="dateTimeParse(props.row.prescription)"
                                position="is-bottom"
                                v-if="props.row.prescription">
                                <b-icon
                                    pack="fas"
                                    icon="check"
                                    size="is-small"
                                    type="is-success">
                                </b-icon>
                            </b-tooltip>
                            <b-icon
                                v-else
                                pack="fas"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                        </b-table-column>

                        <b-table-column field="referral" width="50" label="Napotnica izdana" centered v-slot="props">
                            <b-tooltip
                                :label="dateTimeParse(props.row.referral)"
                                position="is-bottom"
                                v-if="props.row.referral">
                                <b-icon
                                    pack="fas"
                                    icon="check"
                                    size="is-small"
                                    type="is-success">
                                </b-icon>
                            </b-tooltip>
                            <b-icon
                                v-else
                                pack="fas"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                        </b-table-column>

                        <b-table-column centered v-slot="props">
                            <b-button class="is-small is-primary" @click="expandReservation(props)">
                                Odpri
                            </b-button>
                        </b-table-column>
                    </b-table>

                    <template #footer>
                        <div class="container no-print">
                            <span style="float: left">
                                <b-button class="button mt-0 mb-0" @click="$parent.close()">Prekliči</b-button>
                            </span>
                            <span style="float: right">
                                <b-button
                                    class="button is-primary mt-0 mb-0"
                                    @click="addBooking"
                                    :disabled="freeSlotCount <= 0">Naroči pacienta</b-button>
                            </span>
                        </div>
                    </template>
                </Modal>
            </div>

            <!-- Currently selected reservation -->
            <Modal customClass="has-overflow" v-else-if="isUserAuthenticated || (event && event.slot)">
                <template #header>
                    <div class="columns" v-if="isUserAuthenticated">
                        <div class="column is-1 pl-0" v-if="slot && slot.freeSlotCount > 1">
                            <b-button
                                class="no-print"
                                icon-left="angle-left"
                                @click="backToAllReservations">
                            </b-button>
                        </div>
                        <div class="column" style="margin: auto;">
                            <h2 class="title is-5">
                                Rezerviran termin
                            </h2>
                        </div>
                        <div class="column is-2" v-if="isUserAuthenticated && !edit">
                            <b-button class="no-print" type="is-primary" icon-left="edit" @click="edit = true">
                                Uredi
                            </b-button>
                        </div>
                    </div>

                    <div class="columns" v-else>
                        <h2 class="title is-5">
                            Vaš termin
                        </h2>
                    </div>
                </template>

                <div>
                    <div class="container">
                        <div v-if="event">
                            <b>Termin pregleda: </b><span>{{ event.startMoment.format('DD. MM. YYYY') }}</span><br/>
                            <b>Ura: </b><span>{{ event.startMoment.format('H:mm') }} - {{
                                event.endMoment.format('H:mm')
                            }}</span>
                        </div>
                        <div>
                            <b>Ambulanta: </b><span>{{ $store.state.clinic.title }}</span>
                        </div>
                        <div v-if="$store.state.clinic.address">
                            <b>Naslov: </b><span>{{ $store.state.clinic.address }}</span>
                        </div>
                        <br/>

                        <div class="no-print">
                            <b>Ime: </b><span>{{ reservation.patientName }}</span>
                        </div>
                        <div class="no-print" v-if="reservation.patientFileNumber">
                            <b>Št. kartoteke: </b><span>{{ reservation.patientFileNumber }}</span>
                        </div>
                        <div class="no-print" v-if="reservation.patientEmail">
                            <b>E-naslov: </b><span>{{ reservation.patientEmail }}</span>
                        </div>
                        <div class="no-print" v-if="reservation.patientPhone">
                            <b>Telefon: </b><span>{{ reservation.patientPhone }}</span>
                        </div>
                        <div class="no-print" v-if="reservation.patientBirthday">
                            <b>Datum rojstva:</b>
                            <span>
                                {{ $moment(reservation.patientBirthday).format('D. M. YYYY') }}
                            </span>
                        </div>
                        <div class="no-print" v-if="reservation.patientAddress">
                            <b>Naslov: </b><span>{{ reservation.patientAddress }}</span>
                        </div>
                        <div class="no-print" v-if="reservation.patientSchoolName">
                            <b>Šola: </b><span>{{ reservation.patientSchoolName }}</span>
                        </div>
                        <div v-if="reservation.patientNote">
                            <b>Opomba/razlog: </b><span>{{ reservation.patientNote }}</span>
                        </div>
                    </div>

                    <div v-if="isUserAuthenticated" class="container no-print" style="margin-top: 20px;">
                        <b>Status:</b><br/>
                        <b-tooltip
                            :active="!!reservation.notified"
                            :label="dateTimeParse(reservation.notified)"
                            position="is-bottom">
                            <b-button class="btn-status"
                                      style="margin-left: 0;"
                                      v-bind:class="{'is-primary': reservation.notified}"
                                      :loading="loading['notified']"
                                      :disabled="loading['notified']"
                                      @click="toggleStatus('notified')">
                                Obveščen
                            </b-button>
                        </b-tooltip>
                        <b-tooltip
                            :active="!!reservation.attended"
                            :label="dateTimeParse(reservation.attended)"
                            position="is-bottom">
                            <b-button class="btn-status"
                                      v-bind:class="{'is-primary': reservation.attended}"
                                      :loading="loading['attended']"
                                      :disabled="loading['attended']"
                                      @click="toggleStatus('attended')">
                                Prišel
                            </b-button>
                        </b-tooltip>
                        <b-tooltip
                            :active="!!reservation.prescription"
                            :label="dateTimeParse(reservation.prescription)"
                            position="is-bottom">
                            <b-button class="btn-status"
                                      v-bind:class="{'is-primary': reservation.prescription}"
                                      :loading="loading['prescription']"
                                      :disabled="loading['prescription']"
                                      @click="toggleStatus('prescription')">
                                Recept izdan
                            </b-button>
                        </b-tooltip>
                        <b-tooltip
                            :active="!!reservation.referral"
                            :label="dateTimeParse(reservation.referral)"
                            position="is-bottom">
                            <b-button class="btn-status"
                                      v-bind:class="{'is-primary': reservation.referral}"
                                      :loading="loading['referral']"
                                      :disabled="loading['referral']"
                                      @click="toggleStatus('referral')">
                                Napotnica izdana
                            </b-button>
                        </b-tooltip>
                    </div>
                </div>

                <template #footer>
                    <div class="container no-print">
                        <div>
                            <b-button class="is-fullwidth" icon-pack="fas" icon-left="email" @click="notify"
                                      v-if="$store.state.user.id > 0">Obvesti po e-pošti
                            </b-button>
                            <b-button class="is-fullwidth" icon-pack="fas" icon-left="print" @click="print">Natisni
                            </b-button>
                            <b-button class="is-fullwidth" icon-pack="fas" icon-left="file-download" @click="ics">
                                Izvozi
                            </b-button>
                            <span v-if="isUserAuthenticated">
                                <b-button class="btn-cancel is-danger" icon-pack="fas" icon-left="ban"
                                          @click="cancel(false)">Odpovej brez obveščanja</b-button>
                                <b-button class="btn-cancel is-right is-danger" icon-pack="fas" icon-left="info"
                                          @click="cancel(true)">Odpovej in obvesti</b-button>
                            </span>
                            <span v-else>
                                <b-button class="is-fullwidth is-danger" icon-pack="fas" icon-left="ban"
                                          @click="cancel(true)">Odpovej</b-button>
                            </span>
                        </div>
                    </div>
                </template>
            </Modal>
        </div>
    </div>

</template>

<script>
import Modal from "./Modal";
import Toaster from "../mixins/Toaster";
import ReservationEditModal from "./ReservationEditModal";
import {mapGetters} from "vuex";

export default {
    name: 'ReservationModal',
    components: {Modal, ReservationEditModal},
    mixins: [Toaster],
    data() {
        return {
            edit: false,
            loading: {
                notified: false,
                attended: false,
                prescription: false,
                referral: false
            }
        }
    },
    methods: {
        expandReservation(r) {
            this.$store.commit('reservation', r.row);
        },
        backToAllReservations() {
            this.$store.commit('reservation', {});
        },
        editBooking() {
            this.$parent.close();
            this.$store.commit('modal', 'bookingMultiEdit');
        },
        addBooking() {
            this.$parent.close();
            this.$store.commit('modal', 'bookingMulti');
        },
        cancel(notify) {
            let confirmText = 'Odpovej';
            let message = 'Ali ste prepričani, da želite odpovedati termin <strong>' + this.event.startMoment.format('DD. MM. YYYY') + '</strong> ob <strong>' + this.event.startMoment.format('H:mm') + '</strong>? <br />Tega dejanja ne morete razveljaviti!';
            let hasIcon = false;

            if (this.$store.getters.isUserAuthenticated) {
                if (notify) {
                    confirmText += ' in obvesti';
                    message += "<br /><br /> Pacient bo prejel obvestilo o odpovedanem terminu.";
                } else {
                    confirmText += ' brez obveščanja';
                    message += "<br /><br /> Termin bo odpovedan, pacient pa ne bo prejel nobenega obvestila.";
                    hasIcon = true;
                }
            }

            this.$buefy.dialog.confirm({
                confirmText: confirmText,
                cancelText: 'Prekliči',
                type: 'is-danger',
                hasIcon: hasIcon,
                message: message,
                onConfirm: () => this.$store.state.api.post_cancel_reservation(this.reservation.uuid, notify)
                    .then(resp => {
                        if (!resp.error) {
                            this.$store.commit('reservation', {});
                            this.$store.commit('uuid', '');

                            if (this.slot.freeSlotCount > 1 && this.isUserAuthenticated) {
                                this.$store.dispatch('updateEvent', [undefined, resp]);
                                this.backToAllReservations();
                            } else {
                                this.$parent.close();
                                this.$store.dispatch('removeEvent');
                            }

                            this.toastCancellationSuccess();
                        } else {
                            this.toastError("Nekaj je šlo narobe.");
                        }
                    })
            });
        },
        email() {
            window.location = "mailto:" + this.reservation.patientEmail;
        },
        print() {
            window.print();
        },
        notify() {
            this.$parent.close();
            this.$store.commit('notify', {
                start: this.event.startMoment,
                end: this.event.endMoment,
                reservation: this.reservation
            });
            this.$store.commit('modal', 'notify');
        },
        toggleStatus(status) {
            this.loading[status] = true;

            // If reservation has no status (notified, attended, prescription, referral),
            // toggle it to true, else if status is set, set it to false to clear it.
            let data = {
                status: status,
                value: !this.reservation[status]
            };

            this.$store.state.api.change_reservation_status(this.reservation.uuid, data)
                .then(data => {
                    this.$store.dispatch('updateReservation', data);
                    this.loading[status] = false;
                    this.toastSuccess("Uspešno ste posodobili status rezerviranega termina.");
                });
        },
        ics() {
            window.open(this.$store.state.api.link_ical(this.reservation.uuid));
        },
        dateTimeParse(s) {
            if (!s) {
                return '';
            }
            return new this.$moment(s).format("D. M. YYYY H:mm");
        }
    },
    computed: {
        ...mapGetters([
            'isUserAuthenticated'
        ]),
        event() {
            if (this.$store.state.event.extendedProps) {
                // Event has extended props if it is FullCalendar event.
                return this.$store.state.event.extendedProps;
            } else if (this.$store.state.event.id) {
                // Event has no extended props, if event/reservation was fetched by uuid,
                // and event was not set by clicking on the FullCalendar.
                return this.$store.state.event;
            }
            return null
        },
        slot() {
            return this.event ? this.event.slot : null;
        },
        reservation() {
            return this.$store.state.reservation;
        },
        reservations() {
            return this.slot.reservations;
        },
        hasMultipleReservations() {
            return this.reservations && this.reservations.length > 1
        },
        showReservationsList() {
            // Show reservations list, if there is more than one reservation
            // on this slot and no reservation is currently selected.
            return this.slot && this.slot.freeSlotCount > 1 && !this.reservation.id
        },
        freeSlotCount() {
            if (!this.slot) {
                return null;
            }

            let count = this.slot.freeSlotCount - this.reservations.length;
            if (count < 0) {
                count = 0;
            }

            return count
        }
    }
}
</script>

<style lang="scss">
.reservations-list .table th {
    vertical-align: middle;
}
</style>

<style scoped lang="scss">
.container .button {
    margin-bottom: 1em;
}

.button.btn-status {
    margin-left: 8px;
    margin-bottom: 0;
}

.columns {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.btn-cancel {
    width: 45%;

    &.is-right {
        float: right;
    }
}

.hide-tooltip .tooltip-content {
    display: none !important;
}
</style>

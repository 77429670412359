<template>
    <Modal>
        <template #header>
            <div><b>Termin pregleda: </b><span>{{ event.startMoment.format('DD. MM. YYYY') }}</span> <b>&nbsp;&nbsp;&nbsp;&nbsp;Ura: </b><span>{{ event.startMoment.format('HH:mm') }} - {{ event.endMoment.format('HH:mm') }}</span></div>
            <div><b>Ambulanta: </b><span>{{ $store.state.clinic.title }}</span></div>
        </template>

        <form @submit="send">
            <b-tabs v-model="activeTab" position="is-centered" type="is-toggle">
                <b-tab-item label="Naročanje">
                    <div style="margin-bottom: 20px;">
                        Polja označena z zvezdico <span class="star-field has-text-primary">*</span> so obvezna.
                    </div>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            Ime in priimek<span class="star-field has-text-primary">*</span>
                        </template>
                        <b-input type="text" v-model="name" :required="fieldRequired('name')"></b-input>
                    </b-field>

                    <b-field grouped>
                        <b-field label="E-naslov" label-position="on-border" expanded>
                            <b-input type="email" v-model="email" :required="fieldRequired('email')"></b-input>
                        </b-field>
                        <b-field label="Telefon" label-position="on-border" expanded>
                            <b-input type="text" v-model="phone" :required="fieldRequired('phone')"></b-input>
                        </b-field>
                    </b-field>

                    <b-field label="Datum rojstva" label-position="on-border">
                        <b-datepicker v-model="birth" :date-formatter="dateFormat" :date-parser="dateParse" :required="fieldRequired('birth')" editable></b-datepicker>
                    </b-field>

                    <b-field label="Naslov" label-position="on-border">
                        <b-input type="text" v-model="address"></b-input>
                    </b-field>

                    <b-field v-if="isSchoolClinic" label-position="on-border" label="Šola">
                        <b-input v-model="schoolName" type="text"></b-input>
                    </b-field>

                    <b-field label="Opomba/razlog obravnave" label-position="on-border">
                        <b-input type="textarea"  v-model="reason" rows="2"></b-input>
                    </b-field>

                    <hr/>

                    <b-field label="Št. kartoteke" label-position="on-border">
                        <b-input type="text" v-model="file"></b-input>
                    </b-field>

                    <b-field label="Napis za paciente" label-position="on-border">
                        <b-input type="text" v-model="title_busy"></b-input>
                    </b-field>
                </b-tab-item>

                <b-tab-item label="Prost termin">
                    <b-field label="Napis za paciente" label-position="on-border">
                        <b-input type="text" v-model="title_free" disabled></b-input>
                    </b-field>
                    <br />

                    <b-field grouped>
                        <b-field label="Št. razpisanih mest" label-position="on-border">
                            <b-input
                                class="is-inline-block"
                                type="number"
                                v-model="freeSlotCount"
                                required
                                min="1"
                                max="60"
                                style="width: 130px;">
                            </b-input>
                        </b-field>
                        <b-checkbox v-model="multiple" style="margin-left: 2.5rem;">Ustvari več terminov</b-checkbox>
                    </b-field>
                    <br /><br />

                    <b-field label="Trajanje termina v minutah" label-position="on-border" v-if="multiple">
                        <b-numberinput step="1" v-model="duration"></b-numberinput>
                    </b-field>
                </b-tab-item>

                <b-tab-item>
                    <template slot="header">
                        <span>Drugo</span>
                    </template>
                    <b-field label="Napis za paciente" label-position="on-border">
                        <b-input type="text" v-model="title_other" minlength="1"></b-input>
                    </b-field>
                    <br />

                    <b-checkbox v-model="multiple">Ustvari več terminov</b-checkbox>
                    <br /><br />
                    <b-field label="Trajanje termina v minutah" label-position="on-border" v-if="multiple">
                        <b-numberinput step="1" v-model="duration"></b-numberinput>
                    </b-field>
                </b-tab-item>
            </b-tabs>
        </form>

        <template #footer>
            <button class="button " type="button" @click="$parent.close()">Prekliči</button>
            <span v-if="$store.getters.isUserAuthenticated">
                <button v-if="activeTab === 0" class="button is-primary" @click="send">Naroči</button>
                <button v-else-if="activeTab === 1" class="button is-primary" @click="send">Ustvari prost termin</button>
                <button v-else-if="activeTab === 2" class="button is-primary" @click="send">Ustvari drugo</button>
            </span>
        </template>
        <b-loading :active.sync="saveInProgress" :is-full-page="false"></b-loading>
    </Modal>
</template>

<script>
    import Modal from "./Modal";
    import Toaster from "../mixins/Toaster";
    import { cloneTime } from "../../helpers";

    export default {
        name: 'NewSlotModal',
        components: {
            Modal
        },
        mixins: [Toaster],
        data() {
            return {
                activeTab: 0,
                saveInProgress: false,
                name: '',
                email: '',
                phone: '',
                birth: null,
                address: '',
                schoolName: '',
                reason: '',
                file: '',
                freeSlotCount: 1,
                nurseRequiredFields: [
                    "name"
                ],
                publicRequiredFields: [
                    "name",
                    "email",
                    "birth",
                    "phone",
                    "accept"
                ],
                title_busy: this.$store.state.clinic.eventBusy,
                title_free: this.$store.state.clinic.eventFree,
                title_other: this.$store.state.clinic.eventOther,
                duration: parseInt(this.$store.state.clinic.eventLength),

                multiple: false
            }
        },
        methods: {
            makeSlots(from, to, duration, type, title, freeSlotCount) {
                let slots = [];

                if (duration < 1) {
                    slots.push({
                        start: from.format(),
                        end: to.format(),
                        title: title,
                        type: type,
                        freeSlotCount: freeSlotCount,
                        comment: ''
                    })
                } else {
                    let current = from.clone();
                    let timeMin = cloneTime(current, this.$store.state.clinic.timeMin);
                    let timeMax = cloneTime(current, this.$store.state.clinic.timeMax);

                    while (current.clone().add(duration, 'minutes').isSameOrBefore(to)) {
                        let next = current.clone().add(duration, 'minutes');
                        if (next.isBetween(timeMin, timeMax, null, '[]')) {
                            slots.push({
                                start: current.format(),
                                end: next.format(),
                                title: title,
                                type: type,
                                freeSlotCount: freeSlotCount,
                                comment: ''
                            });
                        }
                        if (next.get('date') !== current.get('date')) {
                            timeMin = cloneTime(next, this.$store.state.clinic.timeMin);
                            timeMax = cloneTime(next, this.$store.state.clinic.timeMax);
                        }
                        current = next;
                    }
                }
                return slots;
            },
            send(e) {
                e.preventDefault();
                if (!this.formValid) {
                    this.formIncompleteError();
                    return;
                }
                this.saveInProgress = true;

                let slots = this.makeSlots(
                    this.event.startMoment,
                    this.event.endMoment,
                    this.multiple ? this.duration : 0,
                    this.getActiveType,
                    this.getActiveTitle,
                    Number(this.freeSlotCount || 1)
                );

                this.$store.state.api.post_slots(this.$store.state.clinic.id, slots)
                    .then(resp => {
                        if (resp.length && !resp.error) {
                            // Make reservation also
                            if (this.activeTab === 0) {
                                this.$store.state.api.post_booking(
                                    true,
                                    {
                                        slotId: resp[0]['id'],
                                        book_patient: true,
                                        patientName: this.name,
                                        patientEmail: this.email,
                                        patientPhone: this.phone,
                                        patientBirthday: this.birth,
                                        patientFileNumber: this.file,
                                        patientAddress: this.address,
                                        patientSchoolName: this.schoolName,
                                        patientNote: this.reason,
                                        title_busy: this.title_busy
                                    }
                                )
                                .then(() => {
                                    this.$parent.close();
                                    this.$store.dispatch('refetch')
                                });
                            } else {
                                this.$parent.close();
                                this.$store.dispatch('refetch')
                            }
                        }
                        this.saveInProgress = false;
                    })
            },
            dateFormat(d) {
                return this.$moment(d).format('D. M. YYYY');
            },
            dateParse(s) {
                return new this.$moment(s, 'DD. MM. YYYY').toDate();
            },
            fieldRequired(field) {
                let requiredFields = this.$store.state.user.id  ? this.nurseRequiredFields : this.publicRequiredFields;
                return requiredFields.includes(field)
            }
        },
        computed: {
            event() {
                return this.$store.state.event;
            },
            formValid() {
                if (this.activeTab === 0) {
                    if (this.$store.state.user.id) return (this.name.length);
                    return (this.name.length &&this.email.length && this.phone.length && this.birth)
                } else {
                    // return this.title_free && this.title_free.length > 0
                    return true
                }
            },
            isSchoolClinic() {
                return this.$store.getters.currentClinic.school;
            },
            getActiveTitle() {
                if (this.activeTab === 0) {
                    return this.title_busy || this.$store.state.clinic.eventBusy;
                } else if (this.activeTab === 1) {
                    return this.title_free || this.$store.state.clinic.eventFree;
                } else {
                    return this.title_other || this.$store.state.clinic.eventOther;
                }
            },
            getActiveType() {
                if (this.activeTab === 0) {
                    return 0;
                } else if (this.activeTab === 1) {
                    return 0;
                } else {
                    return 1;
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "../../colors.scss";

    .tabs.is-toggle li:nth-child(1).is-active a {
        background-color: $cal-red;
        border-color: $cal-red;
    }

    .tabs.is-toggle li:nth-child(2).is-active a {
        background-color: $cal-green;
        border-color: $cal-green;
    }

    .tabs.is-toggle li:nth-child(3).is-active a {
        background-color: $cal-yellow;
        border-color: $cal-yellow;
    }

    .free-slot .tabs.is-toggle li.is-active a {
        background-color: $cal-green;
        border-color: $cal-green;
    }
</style>

<style scoped lang="scss">
    .modal-card-body {
        width: 30em;
    }

    .field {
        margin-bottom: 0;
    }
</style>

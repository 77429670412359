import Vue from 'vue'

export default {
    state: {
        modal: null,
        calendarRef: '',

        list: [],
        element: {},

        slots: []
    },
    mutations: {
        element(state, el) {
            state.element = el;
        },
        scheduleCalendarRef(state, cal) {
            state.calendarRef = cal;
        },
        list(state, list) {
            state.list = list.sort((a, b) => { return a.title.localeCompare(b.title) });
        }
    },
    actions: {
        unselect(context) {
            context.state.element = {};
            context.dispatch('scheduleSelect');
        },
        select(context, id) {
            // Needs promise, because find() is async and can finish before SchedulesModalPart mounts,
            // and initializes action 'scheduleCalendarRef' with parameter refName = 'scheduleCalendar'
            new Promise(function(resolve) {
                context.state.element = context.state.list.find(el => {
                    return el.id === id
                });

                // Add isScheduleSlot to all events in the schedule calendar
                context.state.element.events.map(e => {
                    e.isScheduleSlot = true;
                    return e;
                })

                resolve('found')
            }).then(() => {
                context.dispatch('scheduleSelect');
            });
        },
        create(context) {
            context.state.element = {
                id: null,
                title: '',
                clinicId: context.rootState.clinic.id,
                events: []
            };
            context.dispatch('scheduleSelect');
        },
        scheduleSelect(context) {
            let source = {events: context.state.element.events};

            if (context.rootState.calendars[context.state.calendarRef]) {
                context.rootState.calendars[context.state.calendarRef].getEventSources().forEach(es => es.remove());
                if (source.events && source.events.length > 0) {
                    context.rootState.calendars[context.state.calendarRef].addEventSource(source);
                }
            }
        },
        addSlots(context, slots) {
            slots.forEach(slot => { slot.id = slot.start });
            Vue.set(context.state.element, 'events', context.state.element.events.concat(slots));
            this.dispatch('refreshEventSource');
        },
        removeSlot(context, slot) {
            Vue.set(context.state.element, 'events', context.state.element.events.filter(evt => evt.id !== slot.id && evt.id !== slot.id));
            this.dispatch('refreshEventSource');
        },
        refreshEventSource(context) {
            context.rootState.calendars[context.state.calendarRef].removeAllEvents();
            context.rootState.calendars[context.state.calendarRef].addEventSource(context.state.element.events);
        }

    },
    getters: {
        events: state => {
            return state.element;
        }
    }
};

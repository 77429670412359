<template>
    <Modal>
        <template #header>
            <h1 class="title is-5">Nastavitve ambulante</h1>
        </template>

        <b-field label="Ime" label-position="on-border">
            <b-input type="text" v-model="clinic.title" required></b-input>
        </b-field>
        <b-field label="Naslov" label-position="on-border">
            <b-input type="text" v-model="clinic.address"></b-input>
        </b-field>

        <div class="columns">
            <div class="column is-half">
                <b-field label="E-naslov" label-position="on-border">
                    <b-input type="email" v-model="clinic.email"></b-input>
                </b-field>
            </div>
            <div class="column is-half">
                <b-field label="Telefon" label-position="on-border">
                    <b-input type="text" v-model="clinic.phone" ></b-input>
                </b-field>
            </div>
        </div>

        <div class="columns">
            <div class="column is-half">
                <b-field>
                    <b-checkbox v-model="clinic.school">Šolska ambulanta</b-checkbox>
                </b-field>
            </div>
            <div class="column is-half">
                <b-field>
                    <b-checkbox v-model="clinic.referralPrescriptionEnabled">Napotnice in recepti omogočeni</b-checkbox>
                </b-field>
            </div>
        </div>

        <!-- TODO uncomment when ready
        <b-field>
            <b-checkbox v-model="clinic.autoBookEmailEnabled">Avtomatsko pošiljanje elektronskega sporočila ob rezervaciji termina.</b-checkbox>
        </b-field>
        -->
        <hr/>
        <h1 class="title is-6">Delovni čas (prikaz koledarja)</h1>
        <b-field grouped group-multiline>
            <b-field label="Od" label-position="on-border" expanded>
                <b-timepicker v-model="clinic.timeMin" size="is-small" inline required editable></b-timepicker>
            </b-field>
            <b-field label="Do" label-position="on-border" expanded>
                <b-timepicker v-model="clinic.timeMax" size="is-small" inline required editable></b-timepicker>
            </b-field>
            <b-field label="Interval" label-position="on-border">
                <div>
                    <b-input class="is-inline-block" type="number" v-model="clinic.timeInterval" required min="1" max="60"></b-input>
                    <p class="control is-inline-block">
                        <span class="button is-static">minut</span>
                    </p>
                </div>
            </b-field>
        </b-field>

        <b-checkbox v-model="clinic.weekends">Vikendi</b-checkbox>

        <hr/>
        <h1 class="title is-6">Privzete vrednosti terminov</h1>

        <b-field label="Dolžina termina" label-position="on-border">
            <b-input type="number" v-model="clinic.eventLength" required min="5" max="60" expanded></b-input>
            <p class="control">
                <span class="button is-static">minut</span>
            </p>
        </b-field>
        <b-field label="Naslov - prosti" label-position="on-border">
            <b-input type="text" v-model="clinic.eventFree"></b-input>
        </b-field>
        <b-field label="Naslov - zaseden" label-position="on-border">
            <b-input type="text" v-model="clinic.eventBusy"></b-input>
        </b-field>
        <b-field label="Naslov - drugo" label-position="on-border">
            <b-input type="text" v-model="clinic.eventOther"></b-input>
        </b-field>

        <template #footer>
            <button class="button " type="button" @click="$parent.close()">Prekliči</button>
            <button class="button is-primary" @click="send()">Shrani</button>
        </template>
    </Modal>
</template>

<script>
    import Modal from "../modals/Modal";
    import Toaster from "../mixins/Toaster";

    export default {
        extends: Modal,
        name: 'ClinicSettingsModal',
        components: {Modal},
        mixins: [Toaster],
        data() {
            return {
                clinic: {
                    address: this.$store.state.clinic.address,
                    email: this.$store.state.clinic.email,
                    eventBusy: this.$store.state.clinic.eventBusy,
                    eventFree: this.$store.state.clinic.eventFree,
                    eventLength: this.$store.state.clinic.eventLength,
                    eventOther: this.$store.state.clinic.eventOther,
                    phone: this.$store.state.clinic.phone,
                    school: this.$store.state.clinic.school,
                    referralPrescriptionEnabled: this.$store.state.clinic.referralPrescriptionEnabled,
                    timeInterval: this.$store.state.clinic.timeInterval,
                    timeMin: new Date("2020/01/01 " + this.$store.state.clinic.timeMin),
                    timeMax: new Date("2020/01/01 " + this.$store.state.clinic.timeMax),
                    title: this.$store.state.clinic.title,
                    weekends: this.$store.state.clinic.weekends,
                    autoBookEmailEnabled: this.$store.state.clinic.autoBookEmailEnabled
                }
            }
        },
        methods: {
            send() {
                if (!this.formValid) {
                    this.formIncompleteError();
                    return;
                }

                let data = Object.assign({}, this.clinic);
                data.timeMin = this.$moment(data.timeMin).format("HH:mm");
                data.timeMax = this.$moment(data.timeMax).format("HH:mm");
console.log(data.referralPrescriptionEnabled)
                this.$store.state.api.post_clinicSettings(this.$route.params.clinicId, data)
                    .then(resp => {
                        if (!resp.error) {
                            this.$store.commit('clinic', resp);
                            this.$parent.close();
                            this.$router.go();
                            this.toastSavingSuccess();
                        }
                    })
            }
        },
        computed: {
            formValid() {
                return this.clinic.timeInterval > 0 && this.clinic.title && this.clinic.title.length > 0  && this.clinic.eventLength > 0
            }
        }
    }
</script>

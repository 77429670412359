<template>
    <div>
        <Nav></Nav>
        <section class="hero is-primary is-bold is-medium">
            <div class="hero-body ">
                <div class="container">
                    <h1 class="title">Dobrodošli!</h1>
                    <h2 class="subtitle">
                        Izberite enoto in ambulanto s seznama ali pa poiščite ambulanto po imenu.
                    </h2>
                    <div class="message is-warning" v-for="role in expiredRoles" :key="role.id">
                        <div class="message-body">
                            Ena izmed pravic vam je potekla {{ $moment(role.validity).format('LLL') }}.
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="columns">
                    <div class="column has-text-centered">
                        <h1 class="title is-3">Izberi na seznamu</h1>
                        <b-field label="Enota">
                            <b-select class="has-text-centered" placeholder="Izberite enoto ZD" v-model="selectedUnit">
                                <option v-for="(unit, index) in $store.state.units" :value="index" :key="unit.id">
                                    {{ unit.title }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field label="Ambulanta">
                            <b-select class="has-text-centered" placeholder="Izberite ambulanto" @input="option => selectClinic(option)" :disabled="!selectedUnit && selectedUnit !== 0">
                                <optgroup v-for="department in selectedDepartments" :key="department.id" :label="department.title">
                                    <option v-for="clinic in department.clinics" :value="clinic.id" :key="clinic.id" >{{clinic.title}}</option>
                                </optgroup>
                            </b-select>
                        </b-field>
                    </div>
                    <div class="column has-text-centered">
                        <h1 class="title is-3">Išči po imenu</h1>
                        <b-field label="Poišči ambulanto">
                            <b-autocomplete
                                    v-model="searchText"
                                    :data="filteredClinics"
                                    field="title"
                                    placeholder="npr. Jaka Novak, dr. med."
                                    icon-pack="fas" icon="user"
                                    @select="option => selectClinic(option.id)">
                                <template slot-scope="props">
                                    <div class="media">
                                        <div class="media-content">
                                            <b>{{ props.option.title }}</b>
                                            <br>
                                            <small class="has-text-grey">{{ props.option.department }}</small>
                                            <small class="has-text-grey-light"> {{ props.option.unit }}</small>
                                        </div>
                                    </div>
                                </template>
                                <template slot="empty">Ni rezultatov</template>
                            </b-autocomplete>
                        </b-field>
                    </div>
                    <div class="column is-one-third has-text-centered" v-if="myClinics.length">
                        <h1 class="title is-3">Moje ambulante</h1>
                        <br/>
                        <b-select class="has-text-centered" placeholder="Izberite ambulanto" @input="option => selectClinic(option)" :disabled="myClinics.length < 1">
                            <option v-for="clinic in myClinics" :value="clinic.id" :key="clinic.id" >{{clinic.title}}</option>
                        </b-select>
                    </div>
                </div>
            </div>
        </section>
        <b-loading :is-full-page="true" :active.sync="$store.getters.isLoading"></b-loading>
    </div>
</template>

<script>
    import BSelect from "buefy/src/components/select/Select";
    import Nav from "../components/Nav";
    import Toaster from "../components/mixins/Toaster";
    import {normalizeString} from "../helpers";

    export default {
        name: "Home",
        components: {Nav, BSelect},
        mixins: [Toaster],
        data () {
            return {
                my_clinics: [],
                selectedUnit: null,
                searchText: '',
                selected: null
            }
        },
        methods: {
            selectClinic(id) {
                this.$router.push({ name: 'clinic', params: {clinicId: id}});
            }
        },
        computed: {
            expiredRoles() {
                // TODO GET request to get clinic names
                return this.$store.state.userExpiredRoles;
            },
            filteredClinics() {
                return this.$store.state.clinics_search_list.filter((option) => {
                    return option.searchTitle.indexOf(normalizeString(this.searchText)) >= 0
                })
            },
            selectedDepartments() {
                return (this.selectedUnit || this.selectedUnit === 0) ? this.$store.state.units[this.selectedUnit].departments : [];
            },
            myClinics() {
                let clinics = [];
                if (this.$store.state.user.roles) {
                    if (this.$store.state.user.roles.system) {
                        clinics = this.$store.state.clinics_search_list;
                    } else {
                        clinics = this.$store.state.userClinics;
                    }
                }
                return clinics;
            }
        },
        mounted() {
            this.$store.commit('loading', true);
            this.$store.state.api.fetch_public_units()
                .then(resp => {
                    if (!resp.error && resp.length > 0) {
                        this.$store.dispatch('units', resp)
                            .then((units) => {
                                if (units.length === 1) {
                                    this.selectedUnit = 0;
                                }
                            });
                    } else {
                        this.toastGenericFail(resp);
                    }
                    this.$store.commit('loading', false);
                })
                .catch(() => {
                    this.$store.commit('loading', false);
                })
          this.$store.state.api.watch_login_logout()
        }
    }
</script>

<style scoped lang="scss">
    .container {
        position: relative;
    }
    .column:not(:nth-child(1)) {
        border-left: 2px solid lighten(#369f7e, 10);
    }
    .column .title.is-3 {
        color: lighten(#369f7e, 10);
        font-weight: lighter;
    }
    .column .autocomplete {
        max-width: 80%;
        margin: auto;
    }
    .column .control {
        max-width: 80%;
        margin: auto;
    }

    .navbar-main.is-primary {
        background-image: none;
        background-color: inherit;
        position: absolute;
        width: 100%;
    }

    @media screen and (max-width: 768px) {

        .column:not(:nth-child(1)) {
            border-left: none;
            border-top: 2px solid lighten(#369f7e, 10);
            margin-top: 2em;
            padding-top: 2em;
        }
    }
</style>

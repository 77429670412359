<template>
    <Modal customClass="wide-modal">
        <template #header>
            <div>
                <b>Napotnice in recepti</b>
            </div>
            <div>
                <b>Ambulanta: </b>
                <span>{{ $store.state.clinic.title }}</span>
            </div>
        </template>

        <b-table
            :data="referralOrders"
            striped
            narrowed
            paginated
            :per-page="15"
            sort-icon-size="is-small"
            detailed
            detail-key="uuid"
            pagination-simple
            style="margin-bottom: 40px;"
            :loading="loadingReferralOrders"
            hoverable>
            <b-table-column field="created" label="Datum" sortable v-slot="props">
                {{ dateParse(props.row.created) }}
            </b-table-column>

            <b-table-column field="patientName" label="Ime in priimek" sortable v-slot="props">
                {{ props.row.patientName }}
            </b-table-column>

            <b-table-column field="patientEmail" label="E-naslov" sortable v-slot="props">
                {{ props.row.patientEmail }}
            </b-table-column>

            <b-table-column field="referrals.length" width="50" label="Napotnice" sortable centered v-slot="props">
                <span
                    class="tag is-success"
                    v-if="props.row.referrals.length > 0">
                    {{ props.row.referrals.length }}
                </span>
                <span
                    v-else
                    class="tag">
                    {{ props.row.referrals.length }}
                </span>
            </b-table-column>

            <b-table-column field="prescriptions.length" width="50" label="Recepti" sortable centered v-slot="props">
                <span
                    class="tag is-success"
                    v-if="props.row.prescriptions.length > 0">
                    {{ props.row.prescriptions.length }}
                </span>
                <span
                    v-else
                    class="tag">
                    {{ props.row.prescriptions.length }}
                </span>
            </b-table-column>

            <b-table-column field="approved" width="50" label="Potrjeno" sortable centered v-slot="props">
                <b-icon
                    pack="fas"
                    icon="check"
                    size="is-small"
                    type="is-success"
                    v-if="props.row.approved">
                </b-icon>
                <b-icon
                    v-else
                    pack="fas"
                    icon="question"
                    size="is-small"
                    type="is-dark">
                </b-icon>
            </b-table-column>

            <b-table-column centered v-slot="props">
                <b-button
                    class="is-small is-primary"
                    :loading="!!referralOrdersLoading[props.row.id]"
                    @click="confirmReferralOrder(props.row)"
                    style="margin-inline: 0.2em"
                    v-if="userCanApprove && !props.row.approved">
                    Potrdi
                </b-button>
                <b-button
                    class="is-small is-danger"
                    :loading="!!referralOrdersLoading[props.row.id]"
                    @click="rejectReferralOrder(props.row)"
                    style="margin-inline: 0.2em"
                    v-if="userCanApprove && !props.row.approved">
                    Zavrni
                </b-button>
            </b-table-column>

            <template slot="detail" slot-scope="props">
                <article class="media">
                    <div class="media-content pt-3 pb-3 pl-2 pr-2">
                        <div class="content">
                            <div>
                                <strong>
                                    Datum:
                                </strong>
                                {{ getReferralOrderPropOrSlash(props.row, 'created', true) }}
                            </div>
                            <div>
                                <strong>
                                    Ime in priimek:
                                </strong>
                                {{ getReferralOrderPropOrSlash(props.row, 'patientName') }}
                            </div>
                            <div v-if="props.row.patientEmail">
                                <strong>
                                    E-naslov:
                                </strong>
                                {{ getReferralOrderPropOrSlash(props.row, 'patientEmail') }}
                            </div>
                            <div v-if="props.row.patientPhone">
                                <strong>
                                    Telefon:
                                </strong>
                                {{ getReferralOrderPropOrSlash(props.row, 'patientPhone') }}
                            </div>

                            <div v-if="props.row.patientBirthday">
                                <strong>
                                    Datum rojstva:
                                </strong>
                                {{ getReferralOrderPropOrSlash(props.row, 'patientBirthday', true) }}
                            </div>
                            <div v-if="props.row.patientAddress">
                                <strong>
                                    Naslov:
                                </strong>
                                {{ getReferralOrderPropOrSlash(props.row, 'patientAddress') }}
                            </div>
                            <div v-if="props.row.patientZzzs">
                              <strong>
                                ZZZS številka:
                              </strong>
                              {{ getReferralOrderPropOrSlash(props.row, 'patientZzzs') }}
                            </div>

                        <!--            <span v-if="isSchoolClinic">-->
                        <!--                <div>-->
                        <!--                    <strong>-->
                        <!--                        Šola:-->
                        <!--                    </strong>-->
                        <!--                    {{ getReferralOrderPropOrSlash('patientSchoolName') }}-->
                        <!--                </div>-->
                        <!--                <div>-->
                        <!--                    <strong>-->
                        <!--                        Razred:-->
                        <!--                    </strong>-->
                        <!--                    {{ getReferralOrderPropOrSlash('patientSchoolGrade') }}-->
                        <!--                </div>-->
                        <!--            </span>-->

                            <div v-if="props.row.patientNote">
                                <strong>
                                    Opomba/razlog obravnave:
                                </strong>
                                {{ getReferralOrderPropOrSlash(props.row, 'patientNote') }}
                            </div>
                            <div v-if="props.row.patientFileNumber">
                                <strong>
                                    Št. kartoteke:
                                </strong>
                                {{ getReferralOrderPropOrSlash(props.row, 'patientFileNumber') }}
                            </div>

                            <div class="mt-4" v-if="props.row.referrals.length > 0">
                                <div class="mb-2">
                                    <strong>Napotnice</strong>
                                </div>
                                <b-table
                                    :data="props.row.referrals"
                                    striped
                                    narrowed>
                                    <b-table-column field="index" label="#" v-slot="props">
                                        {{ props.index + 1 }}
                                    </b-table-column>

                                    <b-table-column field="activity" label="Specialistična aktivnost" v-slot="props">
                                        {{ props.row.activity }}
                                    </b-table-column>

                                    <b-table-column field="description" label="Opis" v-slot="props">
                                        {{ props.row.description }}
                                    </b-table-column>
                                </b-table>
                            </div>

                            <div class="mt-4" v-if="props.row.prescriptions.length > 0">
                                <div class="mb-2">
                                    <strong>Recepti</strong>
                                </div>
                                <b-table
                                    :data="props.row.prescriptions"
                                    striped
                                    narrowed>
                                    <b-table-column field="index" label="#" v-slot="props">
                                        {{ props.index + 1 }}
                                    </b-table-column>

                                    <b-table-column field="medicine" label="Zdravilo" v-slot="props">
                                        {{ props.row.medicine }}
                                    </b-table-column>
                                </b-table>
                            </div>

                            <div class="mt-4" v-if="props.row.approved && props.row.note">
                                <strong>Opomba:</strong>
                                {{ props.row.note }}
                            </div>

                            <span v-if="userCanApprove && !props.row.approved">
                                <b-field class="mt-5" label="Opomba" label-position="on-border">
                                    <b-input
                                        type="text"
                                        :disabled="!!referralOrdersLoading[props.row.id]"
                                        v-model="props.row.note">
                                    </b-input>
                                </b-field>
                                <div class="field is-inline-block">
                                    <b-checkbox
                                        :disabled="!!referralOrdersLoading[props.row.id]"
                                        v-model="props.row.notify">
                                        Pošlji obvestilo
                                    </b-checkbox>
                                </div>

                                <div class="column" style="float: right;">
                                    <b-button
                                        class=" is-primary"
                                        icon-pack="fas"
                                        icon-left="check"
                                        type="button"
                                        @click="confirmReferralOrder(props.row)"
                                        style="margin-inline:2px"
                                        :loading="!!referralOrdersLoading[props.row.id]">
                                        Potrdi
                                    </b-button>
                                    <b-button
                                        class="is-danger"
                                        icon-pack="fas"
                                        icon-left="times"
                                        type="button"
                                        @click="rejectReferralOrder(props.row)"
                                        style="margin-inline:2px"
                                        :loading="!!referralOrdersLoading[props.row.id]">
                                        Zavrni
                                    </b-button>
                                </div>
                            </span>
                        </div>
                    </div>
                </article>
            </template>
        </b-table>

        <template #footer>
            <b-button class="button" type="button" @click="$parent.close()">Prekliči</b-button>
            <b-button
                class="button is-primary"
                @click="$store.dispatch('openReferralOrderModal')">
                Naroči napotnico ali recept
            </b-button>
        </template>
    </Modal>
</template>

<script>
    import Modal from "./Modal";
    import Toaster from "../mixins/Toaster";

    export default {
        extends: Modal,
        name: 'ReferralListModal',
        components: { Modal },
        mixins: [Toaster],
        data() {
            return {
                referralOrdersLoading: {},
                referrals: [],
                loading: false,
                note: ''
            }
        },
        methods: {
            getReferralOrderPropOrSlash(referralOrder, property, isDate) {
                let text = '/';
                if (referralOrder.id && referralOrder[property]) {
                    text = referralOrder[property];
                    if (isDate) {
                        text = this.$moment(text).format('D. M. YYYY');
                    }
                }
                return text;
            },
            addReferral() {
                this.referrals.push({
                    activity: '',
                    description: ''
                });
            },
            removeReferral(index) {
                this.referrals.splice(index, 1);
            },
            confirmReferralOrder(order) {
                let referralCount = order.referrals.length;
                let prescriptionCount = order.prescriptions.length;
                let message = 'Ste prepričani, da želite pacientu <strong>' + order.patientName + '</strong> potrditi naslednje ';
                if (referralCount > 0 && prescriptionCount > 0) {
                    message += '<strong>napotnice:</strong> <br />';
                    for (let r of order.referrals) {
                        message += `- ${r.activity} (${r.description})<br />`;
                    }

                    message += '<br /><strong>recepte:</strong><br />';
                    for (let p of order.prescriptions) {
                        message += '- ' + p.medicine + '<br />';
                    }
                } else if (referralCount > 0) {
                    message += '<strong>napotnice:</strong> <br />';
                    for (let r of order.referrals) {
                        message += `- ${r.activity} (${r.description})<br />`;
                    }
                } else if (prescriptionCount > 0) {
                    message += '<strong>recepte:</strong> <br />';
                    for (let p of order.prescriptions) {
                        message += '- ' + p.medicine + '<br />';
                    }
                } else {
                    message += 'napotnice in recepte?'
                }

                this.$buefy.dialog.confirm({
                    confirmText: 'Potrdi',
                    cancelText: 'Prekliči',
                    message: message,
                    onConfirm: () => this.approveReferralOrder(order)
                });

            },
            rejectReferralOrder(order) {
                let referralCount = order.referrals.length;
                let prescriptionCount = order.prescriptions.length;
                let message = 'Ste prepričani, da želite pacientu <strong>' + order.patientName + '</strong> zavrniti naslednje ';
                if (referralCount > 0 && prescriptionCount > 0) {
                    message += '<strong>napotnice:</strong> <br />';
                    for (let r of order.referrals) {
                        message += `- ${r.activity} (${r.description})<br />`;
                    }

                    message += '<br /><strong>recepte:</strong><br />';
                    for (let p of order.prescriptions) {
                        message += '- ' + p.medicine + '<br />';
                    }
                } else if (referralCount > 0) {
                    message += '<strong>napotnice:</strong> <br />';
                    for (let r of order.referrals) {
                        message += `- ${r.activity} (${r.description})<br />`;
                    }
                } else if (prescriptionCount > 0) {
                    message += '<strong>recepte:</strong> <br />';
                    for (let p of order.prescriptions) {
                        message += '- ' + p.medicine + '<br />';
                    }
                } else {
                    message += 'napotnice in recepte?'
                }

                this.$buefy.dialog.confirm({
                    confirmText: 'Zavrni',
                    cancelText: 'Prekliči',
                    message: message,
                    onConfirm: () => this.declineReferralOrder(order)
                });

            },
            approveReferralOrder(order) {
                this.loading = true;
                this.$set(this.referralOrdersLoading, order.id, true);

                this.$store.state.api.approve_referral_order(order.id, order)
                    .then(resp => {
                        if (!resp.error) {
                            this.$store.commit('updateReferralOrder', resp);
                            this.toastSuccess("Naročilnice/recepti so potrjeni.");
                        } else {
                            this.toastError("Nekaj je šlo narobe.");
                        }
                    })
                .finally(() => {
                    this.$set(this.referralOrdersLoading, order.id, false);
                });
            },
            declineReferralOrder(order) {
                this.loading = true;
                this.$set(this.referralOrdersLoading, order.id, true);

                this.$store.state.api.decline_referral_order(order.id, order)
                    .then(resp => {
                        if (!resp.error) {
                            if (this.$store.getters.isUserAuthenticated) {
                                this.$store.dispatch('fetchReferralOrders');
                            }
                            this.toastSuccess("Naročilnice/recepti so bili zavrnjeni.");
                        } else {
                            this.toastError("Nekaj je šlo narobe.");
                        }
                    })
                .finally(() => {
                    this.$set(this.referralOrdersLoading, order.id, false);
                });
            },
            dateParse(s) {
                if (!s) {
                    return '';
                }
                return new this.$moment(s).format("D. M. YYYY");
            }
        },
        computed: {
            loadingReferralOrders() {
                return this.$store.state.loadingReferralOrders;
            },
            referralOrders() {
                return this.$store.state.referralOrders;
            },
            userCanApprove() {
                return this.$store.getters.currentClinicRole > 1;
            }
        }
    }
</script>

import { ToastProgrammatic as Toast } from 'buefy'
export default {
    methods: {
        showToast(lvl, msg) {
            Toast.open({
                duration: 5000,
                message: msg,
                position: 'is-top',
                type: 'is-'+lvl
            })
        },
        toastErrorResponse (type) {
            if (type === 'server') {
                this.showToast('danger', 'Napaka na strežniku.');
            } else if (type === 'connection') {
                this.toastConnectionError();
            } else {
                this.showToast('danger', 'Prišlo je do napake.');
            }
        },
        toastConnectionError () {
            this.showToast('danger', 'Povezave ni bilo mogoče vzpostaviti.');
        },
        toastChangePasswordFailLength () {
            this.showToast('danger', 'Novo geslo mora biti dolgo vsaj 6 znakov.');
        },
        toastChangePasswordFailRepeat () {
            this.showToast('danger', 'Novo geslo se ne ujema s ponovitvijo.');
        },
        toastResetPasswordSuccess () {
            this.showToast('success', 'Na e-poštni naslov ste prejeli nadaljna navodila.');
        },
        toastResetPasswordFail (type) {
            if (type === 'credentials') {
                this.showToast('danger', 'Uporabnik s tem e-poštnim naslovom ne obstaja.');
            } else {
                this.toastErrorResponse(type);
            }
        },
        toastLoginFail (type) {
            if (type === 'credentials') {
                this.showToast('danger', 'Napačno uporabniško ime ali geslo.');
            } else {
                this.toastErrorResponse(type);
            }
        },
        toastGenericFail (type) {
            if (type === 'credentials') {
                this.showToast('danger', 'Nimate dovoljenja za ogled strani.');
            } else {
                this.toastErrorResponse(type);
            }
        },
        recaptchaError () {
            this.showToast('danger', 'Prosimo, izpolnite polje "Nisem robot".');
        },
        formIncompleteError () {
            this.showToast('danger', 'Prosimo, izpolnite vsa polja.');
        },
        toastError(message) {
            this.showToast('danger', message);
        },
        toastSuccess(message) {
            this.showToast('success', message);
        },
        toastBookingSuccess () {
            this.showToast('success', 'Uspešno ste se naročili.');
        },
        toastCancellationSuccess () {
            this.showToast('success', 'Uspešno ste odpovedali termin.');
        },
        toastCancellationsSuccess () {
            this.showToast('success', 'Uspešno ste izbrisali termine.');
        },
        toastNotifySuccess () {
            this.showToast('success', 'Obvestila so predana v odpošiljanje.');
        },
        toastReservationNotFound () {
            this.showToast('warning', 'Rezervacija ne obstaja.');
        },
        toastSavingSuccess () {
            this.showToast('success', 'Podatki so bili uspešno shranjeni.');
        }
    }
}

<template>
    <Modal class="schedule-modal">
        <template #header>
            <h1 class="title is-5">Uporabi tedenski urnik</h1>
        </template>
        <b-field label="Od" label-position="on-border">
            <b-datepicker placeholder="Vtipkajte ali izberite datum..." icon="calendar" editable v-model="data.from" :date-formatter="formatter">
            </b-datepicker>
        </b-field>

        <b-field label="Do" label-position="on-border">
            <b-datepicker placeholder="Vtipkajte ali izberite datum..." icon="calendar" editable v-model="data.to" :date-formatter="formatter">
            </b-datepicker>
        </b-field>
        <br/>
        <b-field label="Urnik" label-position="on-border">
            <b-select :placeholder="placeholder" v-model="data.scheduleId" expanded :disabled="!hasSchedules">
                <option v-for="schedule in schedules" :value="schedule.id" :key="schedule.id">
                    {{ schedule.title }}
                </option>
            </b-select>
        </b-field>
        <div class="message is-danger" v-if="!loading && !hasSchedules">
            <div class="message-body">
                Najprej je potrebno ustvariti urnik.
            </div>
        </div>
        <template #footer>
            <button class="button " type="button" @click="$parent.close()">Prekliči</button>
            <button class="button is-primary" @click="send" :disabled="!hasSchedules || loading">Uporabi</button>
        </template>
        <b-loading :active.sync="loading" :is-full-page="false"></b-loading>
    </Modal>
</template>

<script>
    import Modal from "../modals/Modal";
    import Calendar from "../mixins/Calendar";

    export default {
        extends: Modal,
        name: 'ScheduleSelectModal',
        components: { Modal },
        mixins: [Calendar],
        data() {
            return {
                schedules: [],
                data: {
                    scheduleId: null,
                    from: this.$store.getters.currentWeekRange.start.toDate(),
                    to: this.$store.getters.currentWeekRange.end.toDate()
                },
                loading: false
            }
        },
        methods: {
            send() {
                this.loading = true;

                let temp = {
                    scheduleId: this.data.scheduleId,
                    from: this.$moment(this.data.from).format(),
                    to: this.$moment(this.data.to).format()
                };
                this.$store.state.api.post_applySchedule(this.$store.state.clinic.id, temp)
                    .then(resp => {
                        if (resp.error) {
                            this.toastError(resp.error);
                        } else if (resp.length >= 1) {
                            this.$store.dispatch('refetch');
                            this.toastSavingSuccess();
                            this.$parent.close();
                        }
                        this.loading = false;
                    })
            },
            formatter(date) {
                return this.$moment(date).format("D/M/YYYY")
            }
        },
        computed: {
            placeholder() {
                if (this.schedules.length)
                    return 'Izberite urnik';
                return 'Ni urnikov'
            },
            hasSchedules() {
                return this.schedules.length > 0
            }
        },
        mounted() {
            this.loading = true;

            this.$store.state.api.fetch_clinic_schedules(this.$store.state.clinic.id)
                .then(resp => {
                    this.schedules = resp;
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    }
</script>

<style>
    .modal .animation-content .modal-card.schedule-modal {
        overflow: visible ;
    }

    .schedule-modal .modal-card-body {
        overflow: visible;
    }
</style>

<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <slot name="header"></slot>
        </header>
        <div class="large-modal columns no-pad">
            <div class="column is-two-fifths list-column">
                <div class="list is-hoverable">
                    <a class="list-item list-item-special"><b-button icon-left="plus" class="is-success is-fullwidth" @click="create">Ustvari</b-button></a>
                    <div class="list-item" v-for="el in list" :key="el.id" @click="select(el.id)" :class="{ active: el.id === element.id }">
                        {{ el.title }}
                    </div>
                </div>
            </div>
            <div class="column is-three-fifths">
                <SchedulesModalPart v-if="element.title || element.events" v-on:refresh="refresh"></SchedulesModalPart>
                <div class="empty-content" v-else><h2 class="title is-6">Na levi izberite ali ustvarite nov urnik</h2></div>
            </div>
        </div>

        <footer class="modal-card-foot">
            <slot name="footer">
                <button class="button " type="button" @click="$parent.close()">Zapri</button>
            </slot>
        </footer>
    </div>

</template>

<script>
    import SchedulesModalPart from "./SchedulesModalPart";
    export default {
        name: "SchedulesModal",
        components: {SchedulesModalPart},
        data() {
            return {
            }
        },
        methods: {
            refresh() {
                this.$store.state.api.fetch_clinic_schedules(this.$store.state.clinic.id).then(resp => {
                    this.$store.commit('list', resp);
                })
            },
            select(id) {
                this.$store.dispatch('select', id);
            },
            create() {
                this.$store.dispatch('create')
            }
        },
        computed: {
            list() {
                return this.$store.state.schedules.list
            },
            element() {
                return this.$store.state.schedules.element
            }
        },
        mounted() {
            this.refresh();
        }
    }
</script>

<style scoped lang="scss">
    .modal-card {
        width: 960px;
    }
    .modal-card-foot {
        z-index: 1;
    }
    .columns.no-pad {
        margin: 0;

        .column {
            padding: 0;
        }
    }
    .columns.large-modal {
        background-color: white;
        max-height: 80vh;
        height: 80vh;
        margin-bottom: 0;

        .list {
            height: 100%;
            overflow-y: scroll;

            .list-item {
                cursor: pointer;
            }
            .list-item:hover {
                background-color: #f9f9f9;
            }
            .list-item.active {
                background-color: #f0f0f0;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .modal-card {
            width: 640px;
        }
    }
    @media screen and (max-width: 960px) {
        .modal-card {
            width: auto;
        }
    }
</style>

<template>
    <Modal>
        <template #header>
            <h1 class="title is-5" v-if="user && user.id">Uporabnik: {{user.username}}</h1>
            <h1 class="title is-5" v-else>Nov uporabnik</h1>
        </template>

        <b-field label="Uporabniško ime" label-position="on-border" v-if="hasSystemOrUnitRole">
            <b-input type="text" v-model="user.username"></b-input>
        </b-field>
        <b-field label="Ime" label-position="on-border">
            <b-input type="text" v-model="user.name"></b-input>
        </b-field>
        <b-field label="Priimek" label-position="on-border">
            <b-input type="text" v-model="user.surname"></b-input>
        </b-field>
        <b-field label="E-naslov" label-position="on-border">
            <b-input type="email" v-model="user.email" value="/" autocomplete="off"></b-input>
        </b-field>
        <hr/>
        <h1 class="title is-6">Geslo</h1>
        <b-field label="Novo geslo" label-position="on-border">
            <b-input type="password" v-model="newPassword"></b-input>
        </b-field>
        <b-field label="Ponovitev" label-position="on-border">
            <b-input type="password" v-model="newPasswordRepeat"></b-input>
        </b-field>

        <hr/>

        <h1 class="title is-6" v-if="user && user.id">Ambulante</h1>

        <span v-if="hasSystemOrUnitRole">
            <span v-if="editingAnotherUser">
                <b-field label="Dodaj uporabnika v ambulanto" label-position="on-border">
                    <b-autocomplete
                        v-model="searchText"
                        :data="filteredClinics"
                        field="title"
                        clearable
                        open-on-focus
                        @select="clinic => clinicAdd(clinic)"
                        placeholder="išči ambulante"
                        expanded>
                        <template slot-scope="props">
                            <div class="">
                                <b>{{ props.option.title }}</b>
                                <br>
                                <small class="has-text-grey">{{ props.option.department }}</small>
                                <small class="has-text-grey-light"> {{ props.option.unit }}</small>
                            </div>
                        </template>
                    </b-autocomplete>
                </b-field>

                <div class="user-clinics">
                    <div class="user-card card" v-for="clinic in userClinics" :key="clinic.id">
                        <div>
                            <h2 class="card-title">{{ clinic.title }}</h2>
                            <h4 class="card-subtitle">
                                <span v-if="clinic.department.title">{{ clinic.department.title }}</span>
                                <span v-else>{{ clinic.department }}</span>

                                <span v-if="clinic.department.unit"> ({{ clinic.department.unit.title }})</span>
                                <span v-else> ({{ clinic.unit }})</span>
                            </h4>
                        </div>
                        <div class="card-icons clickable">
                            <a @click="clinicRemove(clinic.id)">
                                <b-icon pack="fas" icon="user-times" type="is-danger" class="faded on-hover-show" title="Odvzemi dostop"></b-icon>
                            </a>

                            <a @click="userAdmin(clinic.id)">
                                <b-icon pack="fas" icon="crown" type="is-info" title="Administrator ambulante" class="on-hover-show" v-if="clinic.isAdmin"></b-icon>
                                <b-icon pack="fas" icon="crown" type="is-info" title="Administrator ambulante" class="faded on-hover-show" v-else></b-icon>
                            </a>
                        </div>
                    </div>
                    <b-loading :active.sync="$store.state.administratingUserClinicsLoading" :is-full-page="false"></b-loading>
                </div>
            </span>
            <span v-else>
                Kot sistemski administrator lahko dostopate do vseh ambulant.
            </span>
        </span>
        <span v-else>
            <div class="user-clinics">
                <div class="user-card card" v-for="clinic in userClinics" :key="clinic.id">
                    <div>
                        <h2 class="card-title">{{ clinic.title }}</h2>
                        <h4 class="card-subtitle">
                            <span v-if="clinic.department.title">{{ clinic.department.title }}</span>
                            <span v-else>{{ clinic.department }}</span>

                            <span v-if="clinic.department.unit"> ({{ clinic.department.unit.title }})</span>
                            <span v-else> ({{ clinic.unit }})</span>
                        </h4>
                    </div>
                    <div class="card-icons">
                        <a>
                            <b-icon pack="fas" icon="crown" type="is-info" title="Administrator ambulante" v-if="clinic.isAdmin"></b-icon>
                            <b-icon pack="fas" icon="crown" type="is-info" title="Administrator ambulante" class="faded" v-else></b-icon>
                        </a>
                    </div>
                </div>
                <b-loading :active.sync="$store.state.administratingUserClinicsLoading" :is-full-page="false"></b-loading>
            </div>
        </span>

        <template #footer>
            <button class="button" @click="$parent.close()">Prekliči</button>
            <template v-if="user.id">
                <button class="button is-warning" @click="logout" v-if="$store.state.user.id === user.id">Odjava</button>
                <b-button class="button is-danger" icon-pack="fas" icon-left="trash" type="button" @click="remove" v-else>Izbriši</b-button>
            </template>
            <button class="button is-primary" @click="send">Shrani</button>
        </template>
        <b-loading :active.sync="saveInProgress" :is-full-page="false"></b-loading>
    </Modal>
</template>

<script>
import Modal from "../modals/Modal";
import Toaster from '../mixins/Toaster'
import {normalizeString} from "@/helpers";

export default {
        extends: Modal,
        name: 'UserSettingsModal',
        components: { Modal },
        mixins: [ Toaster ],
        props: [],
        data() {
            return {
                user: this.$store.state.user,
                newPassword: '',
                newPasswordRepeat: '',
                saveInProgress: false,
                searchText: ''
            }
        },
        methods: {
            verify() {
                if (this.newPassword) {
                    if (this.newPassword.length < 6) {
                        this.toastChangePasswordFailLength();
                        return false;
                    }
                    else if (this.newPassword !== this.newPasswordRepeat) {
                        this.toastChangePasswordFailRepeat();
                        return false;
                    } else {
                        this.user.password = this.newPassword;
                    }
                }
                return true;
            },
            send() {
                if (this.verify()) {
                    this.saveInProgress = true;

                    // Set user clinics and reduce them to only needed fields, to exclude unnecessary fields.
                    this.user.clinics = this.userClinics.map(c => {
                        return {
                            id: c.id,
                            title: c.title,
                            isAdmin: c.isAdmin
                        }
                    });

                    this.$store.state.api.post_user(this.user)
                        .then(resp => {
                            if (!resp.error) {
                                this.$parent.close();
                                this.$store.dispatch('reloadUsers');
                                this.toastSavingSuccess();
                            }
                            this.saveInProgress = false;
                        })
                        .catch(err => {
                            this.saveInProgress = false;
                            this.toastErrorResponse(err);
                        })
                }
            },
            remove() {
                this.$buefy.dialog.confirm({
                    confirmText: 'Izbriši',
                    cancelText: 'Prekliči',
                    type: 'is-danger',
                    message: 'Ali ste prepričani, da želite izbrisati tega uporabnika? Tega dejanja ni mogoče razveljaviti.',
                    onConfirm: () => this.$store.state.api.delete_user(this.user.id)
                        .then(resp => {
                            if (!resp.error) {
                                this.$parent.close();
                                this.$store.dispatch('reloadUsers');
                            }
                        })
                });
            },
            logout() {
                this.$store.dispatch('logout');
                this.$parent.close();
            },
            clinicAdd(selected) {
                if (selected && this.userClinics.findIndex(c => c.id === selected.id) < 0) {
                    this.$set(selected, 'isAdmin', false);
                    this.userClinics.push(selected);

                    // This is a hack to clear the input as buefy has a bug.
                    setTimeout(() => this.searchText = '', 5);
                }
            },
            clinicRemove(id) {
                // Filter clinic out with the selected ID
                this.userClinics = this.userClinics.filter((v) => {return v.id !== parseInt(id)});
            },
            userAdmin(id) {
                // Toggle if user isAdmin on the clinic
                let clinic = this.userClinics.find(c => c.id === parseInt(id));
                this.$set(clinic, 'isAdmin', !clinic.isAdmin);
            }
        },
        computed: {
            hasSystemOrUnitRole() {
                let currentUser = this.$store.state.currentUser;
                return currentUser.roles && (currentUser.roles.system || Object.keys(currentUser.roles.units).length>0);
            },
            filteredClinics() {
                return this.$store.state.clinics_search_list.filter((option) => {
                    return option.searchTitle.indexOf(normalizeString(this.searchText)) >= 0
                });
            },
            userClinics: {
                get() {
                    return this.$store.state.administratingUserClinics;
                },
                set(value) {
                    this.$store.commit('administratingUserClinics', value);
                }
            },
            editingAnotherUser() {
                return this.user !== this.$store.state.user;
            }
        },
        created() {
            if (this.$store.state.administratingUser) {
                this.user = this.$store.state.administratingUser;
            }
            else {
                this.user = this.$store.state.user;
            }
        }
    }
</script>

<style lang="scss">
    .user-clinics {
        position: relative;
        min-height: 50px;
    }

    .user-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 1em;
        margin: 1em 0;
        line-height: 1.2em;

        .card-subtitle {
            font-weight: 500;
            font-size: 0.9em;
        }

        .card-icons {
            span {
                margin: 0 0.4em;
                cursor: default;

                & a {
                    cursor: default;
                }
            }

            .faded {
                opacity: 0.2;
            }

            &.clickable span {
                cursor: pointer;

                & a {
                    cursor: pointer;
                }

            }
        }

        .on-hover-show {
        }

        & .on-hover-show:hover {
            opacity: 1;
        }
    }
</style>

<template>
    <div>
        <div class="level">
            <div class="level-left"></div>
            <div class="level-right">
                <b-button class="button is-success" icon-left="plus" v-if="$store.state.user.roles.system" @click="newDepartment">Nov oddelek</b-button>
            </div>
        </div>

        <b-field>
            <b-input placeholder="Išči po imenu oddelka" type="search" icon="search" v-model="search_department"></b-input>
        </b-field>

        <b-table
            :data="departments"
            :paginated="false"
            :sort-icon="'arrow-up'"
            :sort-icon-size="'is-small'"
            default-sort="title">
            <b-table-column field="title" label="Ime" v-slot="props" sortable>
                {{ props.row.title }}
            </b-table-column>
            <b-table-column field="unit.title" label="Enota" v-slot="props" sortable>
                {{ props.row.unit.title }}
            </b-table-column>
            <b-table-column v-slot="props">
                <b-button class="button is-info" icon-pack="fas" icon-left="pencil-alt" @click="openDepartmentAdmin(props.row)">
                    Uredi
                </b-button>
            </b-table-column>
        </b-table>

        <b-modal :active.sync="$store.state.modals.departmentAdministration" has-modal-card>
            <DepartmentAdministrationModal @reload="loadData()"></DepartmentAdministrationModal>
        </b-modal>
    </div>
</template>

<script>
    import {normalizeString} from "../../helpers";
    import DepartmentAdministrationModal from "../modals/DepartmentAdministrationModal";
    export default {
        name: "Departments",
        components: { DepartmentAdministrationModal },
        data () {
            return {
                search_department: ''
            }
        },
        methods: {
            openDepartmentAdmin(department) {
                this.$store.commit('administratingDepartment', department);
                this.$store.commit('modal', 'departmentAdministration');
            },
            newDepartment() {
                this.openDepartmentAdmin({})
            },
            loadData() {
                this.$store.commit('loading', true);
                this.$store.state.api.fetch_departments()
                    .then(resp => {
                        if (!resp.error && resp.length > 0) {
                            this.$store.commit('departments', resp);
                        } else {
                            this.toastGenericFail(resp);
                        }
                        this.$store.commit('loading', false);
                    })
                    .catch(() => {
                        this.$store.commit('loading', false);
                    });
            }
        },
        computed: {
            departments() {
                return this.$store.state.departments_search_list.filter((option) => {
                    return option.searchTitle.indexOf(normalizeString(this.search_department)) >= 0
                })
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>

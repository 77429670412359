<template>
    <div>
        <div v-for="announcement in orderedAnnouncements" :key="announcement.id">
            <Announcement :announcement="announcement"></Announcement>
        </div>
    </div>
</template>

<script>
    import Announcement from "../Announcement";
    export default {
        name: "Announcements",
        components: {Announcement},
        computed: {
            orderedAnnouncements: function () {
                return this.$store.state.clinic.announcements

            }
        }
    }
</script>

<style scoped lang="scss">
    article {
        margin-bottom: 1em;
    }
</style>

import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Clinic from './views/Clinic.vue'
import Login from './views/Login.vue'
import Admin from './views/Admin.vue'
import Instructions from './views/Instructions.vue'
import PasswordReset from './views/PasswordReset.vue'
import store from './store'

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/password-reset',
            name: 'passwordReset',
            component: PasswordReset
        },
        {
            path: '/admin',
            name: 'admin',
            component: Admin,
            beforeEnter: (to, from, next) => {
                if (store.state.user.roles && (Object.keys(store.state.user.roles.units).length || store.state.user.roles.system)) {
                    next()
                } else {
                    next({
                        name: "home"
                    });
                }

            }
        },
        {
            path: '/instructions',
            name: 'instructions',
            component: Instructions,
            beforeEnter: (to, from, next) => {
                if (store.state.user.id) {
                    next()
                } else {
                    next({
                        name: "login"
                    });
                }

            }
        },
        {
            path: '/:clinicId',
            name: 'clinic',
            component: Clinic
        }
    ]
})

<template>
    <div class="column content-column">
        <div class="flex-group">
            <br/>
            <b-field label="Ime urnika" label-position="on-border">
                <b-input type="text" v-model="schedule.title"></b-input>
            </b-field>
            <div class="level">
                <button class="button level-left" @click="cancel">Prekliči</button>
                <b-button class="button is-danger" icon-pack="fas" icon-left="trash" type="button" @click="remove">Izbriši</b-button>
                <button class="button is-success level-right" @click="save">Shrani</button>
            </div>
        </div>
        <div class="flex-group grows cal-wrapper schedule-calendar">
            <CalendarComponent></CalendarComponent>
        </div>
    </div>
</template>

<script>
    import CalendarComponent from "../../calendar/CalendarComponent";
    import BInput from "buefy/src/components/input/Input";
    import Calendar from "../../mixins/Calendar";

    export default {
        name: 'SchedulesModalPart',
        components: {BInput, CalendarComponent},
        mixins: [ Calendar ],
        data() {
            return {
                refName: 'scheduleCalendar',
                nowIndicator: false,
                selectable: true,
                header: false,
                allDaySlot: false,
                columnHeader: true,
                columnHeaderFormat: 'ddd',
                eventSources: this.events
            }
        },
        methods: {
            select: function(event) {
                event.startMoment = this.$moment(event.start);
                event.endMoment = this.$moment(event.end);
                this.$store.commit('event', event);
                this.$store.commit('modal', 'scheduleSlot');
            },
            cancel: function() {
                this.$store.dispatch('unselect')
            },
            remove: function() {
                this.$buefy.dialog.confirm({
                    confirmText: 'Izbriši',
                    cancelText: 'Prekliči',
                    type: 'is-danger',
                    message: 'Ali ste prepričani, da želite izbrisati ta urnik? Tega dejanja ni mogoče razveljaviti.',
                    onConfirm: () => this.$store.state.api.delete_schedule(this.schedule.id).then(() => {
                        this.$emit('refresh');
                        this.$store.dispatch('unselect');
                    })
                });
            },
            save: function() {
                this.$store.state.api.post_schedule(this.schedule).then(() => {
                    this.$emit('refresh');
                    this.$store.dispatch('unselect');
                })
            },
            eventClick: function(event) {
                this.$store.dispatch('removeSlot', event.event)
            }
        },
        mounted() {
            this.$store.commit('scheduleCalendarRef',  this.refName);
        },
        computed: {
            schedule() {
                return this.$store.state.schedules.element
            },
            events() {
                return this.$store.state.schedules.element.events
            }
        }
    }
</script>

<style scoped lang="scss">
    .content-column {
        max-height: 95%;
        height: 95%;
        position: relative;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .flex-group {
            padding: 0 1em;
        }
        .flex-group.cal-wrapper {
            padding: 0;
        }
        .flex-group.grows {
            flex-grow: 1;
        }
    }

</style>
<style>
    .schedule-calendar a.fc-time-grid-event.fc-event:hover:before {
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00d";
        font-size: 2em;
        color: #f00;
        z-index: 10;
        line-height: 0.8em;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>


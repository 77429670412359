<template>
    <Modal>
        <template #header>
            <h1 class="title is-5">Namen zbiranja in obdelave osebnih podatkov, rok hrambe in njihovo varovanje</h1>
        </template>
        <p>Pri uporabi spletne aplikacije <i>Naročanje</i> preko spletnega obrazca izpolnite in oddate naslednje obvezne podatke (polja): <b>Ime in priimek</b>, <b>E-mail</b>, <b>Telefon</b>, <b>Datum rojstva</b>, <b>Naslov</b>, ter kot neobvezni podatek: <b>Opomba/razlog obravnave</b>.</p>
        <br/>
        <p>Naštete podatke za <i>ZDL – Zdravstveni dom Ljubljana</i> pogodbeno obdeluje podjetje <i>Kron Telekom, d.o.o.</i> in to izključno za namene naročanja na izvajanje zdravstvenih storitev. Podatki v nobenem primeru niso posredovani tretjim osebam. Rok hrambe podatkov je 12 mesecev zaradi zagotavljanja storitve in evidence delovanja storitve. Po preteku roka se podatki izbrišejo. Podatke podjetje varuje v skladu z novo Splošno uredbo o varstvu podatkov (GDPR) in internim pravilnikom.</p>
        <template #footer>
            <button class="button " type="button" @click="$parent.close()">Zapri</button>
        </template>
    </Modal>
</template>

<script>
    import Modal from "../modals/Modal";

    export default {
        extends: Modal,
        name: 'GdprModal',
        components: { Modal }
    }
</script>


<template>
    <Modal>
        <template>
            <h1 class="title is-5">Obvesti paciente</h1>
        </template>
        <div v-if="cancel">Vsi termini <b>{{printRange}}</b> bodo <b>odpovedani</b>, naročeni pacienti pa bodo na e-poštni naslov prejeli spodnje obvestilo.</div>
        <div v-else-if="notifyReservation">
            <strong>{{ notifyReservation.patientName}}</strong> bo na e-poštni naslov
            <strong>
                {{ notifyReservation.patientEmail }}
            </strong>
            prejel spodnje obvestilo.
        </div>
        <div v-else>Vsi pacienti, naročeni bodo na e-poštni naslov prejeli spodnje obvestilo.</div>
        <br/>
        <form @submit="send">
            <b-field label="Vsebina" label-position="on-border">
                <b-input type="textarea" v-model="message" rows="10" cols="80"></b-input>
            </b-field>
        </form>

        <template #footer>
            <button class="button " type="button" @click="$parent.close()">Prekliči</button>
            <button class="button is-primary" @click="send(true)">Potrdi</button>
        </template>
    </Modal>
</template>

<script>
    import Modal from "./Modal";
    import Toaster from "../mixins/Toaster";

    export default {
        extends: Modal,
        name: 'ReferralConfirmModal',
        components: { Modal },
        props: ['cancel'],
        mixins: [Toaster],
        data() {
            return {
                message: '',
                range: this.$store.state.notify_days
            }
        },
        methods: {
            send(notify) {
                let pack = {
                    message: this.message,
                    from: this.range.start.format(),
                    to: this.range.end.format(),
                    clinicId: this.$store.state.clinic.id,
                    notify: notify
                };

                let confirmMsg = 'Ali ste prepričani, da želite ';
                if (this.cancel) {
                    confirmMsg += 'izbrisati vse termine ' + this.printRange
                        if (notify) {
                            confirmMsg += ' in o tem obvestiti paciente?'
                        } else {
                            confirmMsg += ' brez obveščanja?'
                        }
                } else if (this.notifyReservation) {
                    confirmMsg += 'poslati obvestilo pacientu <strong>' + this.notifyReservation.patientEmail + '</strong>?'
                } else {
                    confirmMsg += 'poslati obvestilo vsem pacientom naročenim '+this.printRange+'?'
                }

                this.$buefy.dialog.confirm({
                    confirmText: 'Potrdi',
                    cancelText: 'Prekliči',
                    message: confirmMsg,
                    onConfirm: () => {
                        if (this.cancel) {
                            this.$store.state.api.post_cancel(pack)
                                .then(this.handle, notify)

                        } else {
                            this.$store.state.api.post_notify(pack)
                                .then(this.handle, notify)
                        }
                    }
                });
            },
            handle(resp, notify) {
                if (!resp.error) {
                    if (notify) {
                        this.toastNotifySuccess();
                    } else if (this.cancel) {
                        this.toastCancellationsSuccess();
                    }
                    this.$parent.close();
                    this.$store.dispatch('refetch');
                } else {
                    this.toastGenericFail(resp);
                }
            }
        },
        computed: {
            printRange() {
                if (this.range.start !== this.range.end) {
                    return 'od ' + this.range.start.format('D. M. YYYY') + ' do ' + this.range.end.format('D. M. YYYY');
                } else {
                    return this.range.start.format('D. M. YYYY');
                }
            },
            notifyReservation() {
                return this.range.reservation;
            }
        }
    }
</script>

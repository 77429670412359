import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid'
import momentPlugin from '@fullcalendar/moment'
import { authUserCalendarDataTransform } from "@/helpers";

export default {
    data() {
        return {
            refName: 'fullCalendar',
            calendarPlugins: [ timeGridPlugin, momentPlugin, interactionPlugin ],
            labelFormat: {
                hour: 'numeric',
                minute: '2-digit',
                hour12: false,
                omitZeroMinute: false,
                meridiem: false
            },
            columnHeaderFormat: 'ddd, D. MMM',


            selectable: this.$store.state.user.id,
            selectOverlap: function(event) {
                return event.rendering === 'background';
            },

            eventSources: [
                {   // used just to tint odd columns
                    id: 0,
                    events: this.tintedDays,
                    color: '#eaeaea'
                },
                {   // calendar
                    id: 1,
                    events: this.calendarES
                }
            ]

        }
    },
    methods: {
        select: function(event) {
            if (this.$store.state.user && !event.allDay && this.$store.getters.currentClinicRole > 1 && this.$moment(event.start).isSameOrAfter(this.$moment(), 'days')) {
                event.startMoment = this.$moment(event.start);
                event.endMoment = this.$moment(event.end);
                this.$store.commit('event', event);
                this.$store.commit('modal', 'newSlot');
            }
        },
        calendarES(info, successCallback, failureCallback) {
            this.$store.commit('loading', true);
            let pub = true;
            if (this.$store.state.user.id && this.$store.getters.currentClinicRole >= 1) pub = false;

            this.$store.state.api.fetch_calendar(pub, this.$route.params.clinicId, info.startStr, info.endStr)
                .then(data => {
                    if (data.error) {
                        failureCallback();
                        this.$store.commit('loading', false);
                    } else {
                        successCallback(data.map(evt => authUserCalendarDataTransform(evt, this.$moment, this.$store.state.clinic.eventBusy)));
                        this.$store.commit('loading', false);
                    }
                })

        },
        tintedDays (info, successCallback) {
            let events = [];
            let day = info.start;
            let z = day.getTimezoneOffset() * 60 * 1000;
            while (day < info.end) {
                if (day.getDay() % 2) {
                    let dateStr = new Date(day - z).toISOString().slice(0, 10);
                    events.push({title: '', start: dateStr, rendering: 'background'});
                }
                day.setDate(day.getDate() + 1);
            }
            successCallback(events);
        },
        eventClick: function(info) {
            if (info.event.rendering !== 'background') {
                this.$store.commit('event', info.event);

                let reservations = info.event.extendedProps.slot.reservations;
                if (reservations && reservations.length > 0) {
                    if (this.$store.state.user.id > 0 || info.event.source.id === '2') {
                        if (info.event.extendedProps.slot.freeSlotCount > 1) {
                            // Open reservations list
                            this.$store.commit('reservation', {});
                        } else if (reservations && reservations.length === 1) {
                            // Open reservation
                            this.$store.commit('reservation', reservations[0]);
                        }

                        this.$store.commit('modal', 'reservation');
                    }
                } else {
                    this.$store.commit('event', info.event);
                    this.$store.commit('modal', 'booking');
                }
            }
        }
    },
    computed: {
        weekends() { return this.$store.state.clinic.weekends },
        interval() { return this.$store.state.clinic.timeIntervalFormatted },
        minTime() { return this.$store.state.clinic.timeMinFormatted },
        maxTime() { return this.$store.state.clinic.timeMaxFormatted }
    }
}

<template class="has-overflow">
    <Modal customClass="has-overflow">
        <template #header>
            <div><h2 class="title is-5">Urejanje rezervacije</h2></div>
        </template>

        <form @submit="save">
            <div style="margin-bottom: 20px;">
                Polja označena z zvezdico <span class="star-field has-text-primary">*</span> so obvezna.
            </div>

            <b-field grouped group-multiline>
                <b-field label-position="on-border" expanded>
                    <template slot="label">
                        Ime in priimek<span class="star-field has-text-primary">*</span>
                    </template>
                    <b-input type="text" v-model="reservation.patientName" required></b-input>
                </b-field>
            </b-field>

            <b-field grouped group-multiline>
                <b-field label-position="on-border" expanded>
                    <template slot="label">
                        E-naslov <span v-if="fieldRequired('email')" class="star-field has-text-primary">*</span>
                    </template>
                    <b-input type="email" v-model="reservation.patientEmail" :required="fieldRequired('email')"></b-input>
                </b-field>
                <b-field label-position="on-border" expanded>
                    <template slot="label">
                        Telefon <span v-if="fieldRequired('phone')" class="star-field has-text-primary">*</span>
                    </template>
                    <b-input type="text" v-model="reservation.patientPhone" :required="fieldRequired('phone')"></b-input>
                </b-field>
            </b-field>

            <b-field label-position="on-border">
                <template slot="label">
                    Datum rojstva <span v-if="fieldRequired('birth')" class="star-field has-text-primary">*</span>
                </template>
                <b-datepicker v-model="reservation.patientBirthday" :required="fieldRequired('birth')" :date-formatter="formatter"></b-datepicker>
            </b-field>

            <b-field label="Naslov" label-position="on-border">
                <b-input type="text" v-model="reservation.patientAddress"></b-input>
            </b-field>

            <b-field v-if="isSchoolClinic" label-position="on-border" label="Šola">
                <b-input v-model="reservation.patientSchoolName" type="text"></b-input>
            </b-field>
            <b-field label="Opomba/razlog obravnave" label-position="on-border">
                <b-input type="textarea" v-model="reservation.patientNote" rows="2"></b-input>
            </b-field>

            <hr/>

            <b-field label="Št. kartoteke" label-position="on-border">
                <b-input type="text" v-model="reservation.patientFileNumber"></b-input>
            </b-field>

            <div class="container no-print" style="margin-top: 20px;">
                <section>
                    <div class="status-buttons">
                        <div class="field">
                            <b-checkbox v-model="reservation.notified">
                                Obveščen
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox v-model="reservation.attended">
                                Prišel
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox v-model="reservation.prescription">
                                Recept izdan
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox v-model="reservation.referral">
                                Napotnica izdana
                            </b-checkbox>
                        </div>
                    </div>
                </section>
            </div>
        </form>

        <template #footer>
            <b-button class="is-secondary" @click="$parent.close()">Prekliči</b-button>
            <b-button class="is-primary is-right" @click="save" :disabled="loading">Shrani</b-button>
        </template>
        <b-loading :active.sync="loading" :is-full-page="false"></b-loading>
    </Modal>
</template>

<script>
    import Modal from "./Modal";
    import Toaster from "../mixins/Toaster";

    export default {
        extends: Modal,
        name: 'ReservationEditModal',
        components: { Modal },
        mixins: [Toaster],
        props: {
            reservationProp: {
                type: Object
            }
        },
        data() {
            let minDate = new Date();
            minDate.setHours(0);
            minDate.setMinutes(0);

            return {
                reservation: {},
                requiredFields: [
                    "name"
                ],
                minDate: minDate,
                loading: false
            }
        },
        methods: {
            save(e) {
                e.preventDefault();

                if (!this.formValid) {
                    this.formIncompleteError();
                    return;
                }

                this.loading = true;

                this.$store.state.api.post_reservation_update(this.reservation.uuid, this.reservation)
                    .then(resp => {
                        if (resp) {
                            if (resp.error) {
                                this.toastError(resp.error);
                            } else {
                                this.$parent.close();
                                this.toastSuccess("Rezerviran termin je bil uspešno posodobljen.");

                                this.$store.dispatch('updateEvent', [undefined, resp.slot]);
                                // Update whole reservations array in the event
                                this.$store.dispatch('updateReservation', resp.reservation);
                            }
                            this.loading = false;
                        }
                    })
            },
            formatter(date) {
                return this.$moment(date).format("D. M. YYYY")
            },
            datetimeFormatter(s) {
                if (!s) {
                    return '';
                }
                return new this.$moment(s).format("D. M. YYYY HH:mm");
            },
            fieldRequired(field) {
                return this.requiredFields.includes(field)
            }
        },
        computed: {
            formValid() {
                return (this.reservation.patientName.length);
            },
            isSchoolClinic() {
                return this.$store.getters.currentClinic.school;
            },
            timepickerProps() {
                let props = {};
                let d = new Date();
                d.setHours(this.$store.state.clinic.timeMin.split(":")[0]);
                d.setMinutes(this.$store.state.clinic.timeMin.split(":")[1]);
                props['min-time'] = d;
                d = new Date();
                d.setHours(this.$store.state.clinic.timeMax.split(":")[0]);
                d.setMinutes(this.$store.state.clinic.timeMax.split(":")[1]);
                props['max-time'] = d;
                return props
            }
        },
        mounted() {
            // Deep clone reservation object
            let reservation = JSON.parse(JSON.stringify(this.reservationProp));

            // Convert status fields to booleans, to display as checkboxes
            reservation.notified = !!reservation.notified;
            reservation.attended = !!reservation.attended;
            reservation.prescription = !!reservation.prescription;
            reservation.referral = !!reservation.referral;

            reservation.patientBirthday = reservation.patientBirthday ? this.$moment(reservation.patientBirthday).toDate() : null;
            this.reservation = reservation;
        }
    }
</script>

<style>
    .star-field {
        font-size: 20px;
        line-height: 16px;
        font-weight: bold;
    }

    .modal .has-overflow.modal-card {
        overflow: visible !important;
    }

    .modal .has-overflow .modal-card-body {
        overflow: visible !important;
    }

    .status-buttons .field {
        display: inline-block;
    }

    .status-buttons .field:not(:first-child) {
        margin-left: 15px;
    }
</style>

<template>
<section class="section is-medium">
    <div class="container">
        <div  v-if="success">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <img class="logo" src="../assets/logot.png" title="Zdravstveni dom Ljubljana">
                </header>
                <section class="modal-card-body">
                    <p>Uspešno ste posodobili svoje geslo!</p>
                </section>
                <footer class="modal-card-foot is-centered">
                    <b-button class="button-login is-primary" tag="router-link"
                              to="/"
                              type="is-link"
                              replace>
                        Domov
                    </b-button>
                </footer>
            </div>
        </div>
        <form v-else @submit="submit">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <img class="logo" src="../assets/logot.png" title="Zdravstveni dom Ljubljana">
                </header>
                <section class="modal-card-body">
                    <p>Ponastavi svoje geslo</p>
                    <b-field label="Geslo">
                        <b-input type="password" v-model="password" placeholder="" required></b-input>
                    </b-field>
                    <b-field label="Ponovi geslo">
                        <b-input type="password" v-model="password2" placeholder="" required></b-input>
                    </b-field>
                    <div class="rows">
                        <div class="row">
                        </div>
                        <div class="row">
                            <div class="notification is-danger" v-show="message">
                                {{ message }}
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot is-centered">
                    <button class="button is-primary password-reset">Ponastavi geslo</button>
                </footer>
            </div>
        </form>
        <b-loading :is-full-page="true" :active.sync="$store.getters.isLoading" :can-cancel="true"></b-loading>
    </div>
</section>
</template>

<script>
    import Toaster from "../components/mixins/Toaster";

    export default {
        name: "PasswordReset",
        mixins: [Toaster],
        data() {
            return {
                password: '',
                password2: '',
                message: '',
                success: null
            }
        },
        methods: {
            verifyPassword() {
                    if (this.password.length < 6)
                        this.message = "Geslo mora vsebovati vsaj 6 znakov.";
                    else if (!(this.password === this.password2))
                        this.message = "Gesli se ne ujemata.";
                    else
                        return true;

                return false;
            },
            submit(e) {
                this.$store.commit('loading', true);
                e.preventDefault();

                // If passwords match it will return true
                if (this.verifyPassword()) {
                    let data = {
                        password: this.password,
                        password2: this.password2,
                        token: this.$route.query.token
                    };
                    this.$store.state.api.resetPassword(data)
                        .then(resp => {
                        // If error has occured, show error toast
                        if (!resp || resp.error) {
                            this.toastErrorResponse();
                        } else {
                            // No error, show success toast and redirect to login
                            this.toastSuccess("Uspešno ste posodobili svoje geslo.");
                            this.success = true;
                        }
                        this.$store.commit('loading', false);
                    })
                } else {
                    this.$store.commit('loading', false);
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .section {
        background-image: linear-gradient(141deg, #218153 0%, #369f7e 71%, #37b8a5 100%);
        height: 100%;
    }
     .container {
         max-width: 20em;
     }

    .modal-card-foot {
        justify-content: space-between;
    }
    .logo {
        text-align: center;
        margin: 0.5em auto;
    }
    .rows {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .forgot-password {
        margin: 5px auto 0 auto;
    }
    .notification {
        padding: 10px;
    }
    .button-login,
    .button.password-reset {
        margin: auto;
    }
</style>

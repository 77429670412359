import Vue from 'vue'
import Vuex from 'vuex'
import APIService from "./APIService";
import {normalizeString} from "./helpers";
import SchedulesModalStore from './store/SchedulesModalStore';
import {eventColor} from "@/helpers";

Vue.use(Vuex);

const moment = require('moment');
require('moment/locale/sl');
Vue.use(require('vue-moment'), {moment});
Vue.moment.updateLocale('sl', { weekdaysShort : ["ned", "pon", "tor", "sre", "čet", "pet", "sob"] });
Vue.moment.locale('sl');

export default new Vuex.Store({
    modules: {
        schedules: SchedulesModalStore
    },
    state: {
        api: new APIService(),
        calendars: {},
        loading: 0,
        loadingClinicUsers: false,
        loadingReferralOrders: false,
        moment : moment,
        modals: {
            timespanPicker: false,
            booking: false,
            bookingMulti: false,
            bookingMultiEdit: false,
            reservation: false,
            referralOrder: false,
            referralConfirm: false,
            referralList: false,
            prescription: false,
            announcement: false,
            notify: false,
            cancel: false,
            newSlot: false,
            scheduleSlot: false,
            scheduleSelect: false,
            userSettings: false,
            clinicSettings: false,
            clinicAdministration: false,
            departmentAdministration: false,
            unitAdministration: false,
            large: false,
            gdpr: false
        },
        largeModal: '',

        asideCalRef: '',
        asideShow: false,
        asideDay: Vue.moment(),

        user: {},   // Currently selected user (default authenticated user)
        currentUser: {},  // Currently logged in user
        userClinics: [],
        userExpiredRoles: [],
        referralOrder: {},
        referralOrders: [],
        userInitialized: false,
        calendarKey: 0,

        uuid: '',
        uuidFetched: false,
        clinic: {},
        day: {},

        units: [],
        clinics_search_list: [],
        administratingClinic: {},
        administratingUnit: {},

        users: [],
        users_search_list: [],
        administratingUser: undefined,
        administratingUserClinics: [],
        administratingUserClinicsLoading: false,

        departments: [],
        departments_search_list: [],
        administratingDepartment: {},

        slot: {}, // Currently selected slot
        reservation: {}, // Currently selected reservation
        event: {},  // Currently selected FullCallendar event
        announcement: {},

        notify_days: [],
        print_class: '',

        calendarWeekOffset: 0   // this serves no purpose other than forcing getter update
    },
    mutations: {
        units (state, units) {
            function compareAlphabetically(a, b) {
                if (a.title < b.title)
                    return -1;
                if (a.title > b.title)
                    return 1;
                return 0;
            }

            // Sort departments and units alphabetically
            for (let unit of units) {
                unit.departments.sort(compareAlphabetically);
                unit.searchTitle = normalizeString(unit.title);

                for (let department of unit.departments) {
                    department.clinics.sort(compareAlphabetically);
                }
            }

            state.units = units.sort(compareAlphabetically);
            state.clinics_search_list = state.units.map(unit => {
                return unit.departments.map(d => {
                    d.unit = unit.title;
                    d.unitId = unit.id;
                    return d
                })
            }).flat();
            state.clinics_search_list = state.clinics_search_list.map(dept => {
                return dept.clinics.map(c => {
                    c.department = dept.title;
                    c.unit = dept.unit;
                    c.unitId = dept.unitId;
                    c.searchTitle = normalizeString(c.title);
                    return c
                })
            }).flat();

            let clinics = [];
            if (state.user.roles) {
                if (state.user.roles.system) {
                    clinics = state.clinics_search_list;
                } else {
                    for (let key of Object.keys(state.user.roles.units)) {
                        let unit = state.units.find(u => {
                            return u.id === parseInt(key)
                        });
                        if (unit && unit.departments) {
                            unit.departments.forEach(d => {
                                clinics = clinics.concat(d.clinics)
                            })
                        }

                    }

                    for (let key of Object.keys(state.user.roles.clinics)) {
                        clinics.push(state.clinics_search_list.find(c => {
                            return c.id === parseInt(key)
                        }))
                    }
                }
            }

            state.userClinics = clinics;
        },
        departments (state, departments) {
            state.departments = departments;
            state.departments_search_list = state.departments.map(d => {
                d.searchTitle = normalizeString(d.title);
                d.unitId = d.unit.id;
                return d;
            });
        },
        users (state, users) {
            state.users = users;
            state.users_search_list = state.users.map(usr => {
                usr.searchName = normalizeString(usr.name + ' ' + usr.surname + ' ' + usr.email + ' ' + usr.username);
                usr.searchUsername = normalizeString(usr.username);
                return usr
            });
        },
        calendar(state, payload) {
            Vue.set(state.calendars, payload.name, payload.value);
        },
        asideCalendarRef(state, ref) {
            state.asideCalRef = ref;
            state.calendars[state.asideCalRef].gotoDate(state.asideDay.toDate());
        },
        administratingUserClinics(state, administratingUserClinics) {
            state.administratingUserClinics = administratingUserClinics;
        },
        administratingClinic(state, clinic) {
            state.administratingClinic = clinic;
        },
        administratingUser(state, user) {
            state.administratingUser = user;
            state.administratingUserClinics = [];

            if (user && user.id) {
                state.administratingUserClinicsLoading = true;

                if (state.user && user.id === state.user.id) {
                    state.api.fetch_my_clinics(user.id)
                        .then(async (resp) => {
                            await setClinics(resp);
                        })
                        .catch(e => {
                            console.error(e);
                        })
                        .finally(() => {
                            state.administratingUserClinicsLoading = false;
                        });
                } else {
                    state.api.fetch_user_clinics(user.id)
                        .then(async resp => {
                            await setClinics(resp);
                        })
                        .catch(e => {
                            console.error(e);
                        })
                        .finally(() => {
                            state.administratingUserClinicsLoading = false;
                        });
                }
            }
            async function setClinics(resp) {
                if (resp && !resp.error) {
                    let clinics = {};

                    for (let clinic of resp.userClinics) {
                        clinics[clinic.id] = clinic;
                    }

                    for (let clinic of resp.adminClinics) {
                        if (!clinics[clinic.id]) {
                            clinics[clinic.id] = clinic;
                        }
                        clinics[clinic.id]['isAdmin'] = true;
                    }

                    state.administratingUserClinics = Object.values(clinics);
                }
                state.administratingUserClinicsLoading = false;
            }
        },
        administratingDepartment(state, department) {
            state.administratingDepartment = department;
        },
        administratingUnit(state, unit) {
            state.administratingUnit = unit;
        },
        clinic(state, clinic) {
            if (clinic) {
                clinic.timeMinFormatted = clinic.timeMin + ':00';
                clinic.timeMaxFormatted = clinic.timeMax + ':00';
                let interval = parseInt(clinic.timeInterval) < 10 ? '0' + clinic.timeInterval : clinic.timeInterval;
                clinic.timeIntervalFormatted = '00:' + interval + ':00';
                // clinic.currentRole = state.user.userRoles.find(el => {
                //     if (!el.validity || state.$moment(el.validity).isAfter(state.$moment())) {
                //         if (el.userClinicId === clinic.id) return 1;
                //         if (el.adminClinicId === clinic.id) return 2;
                //         // if (el.adminUnitId === clinic.unitId) return 3;
                //         if (el.sysadmin) return 4;
                //     }
                //     return 0;
                // });
                state.clinic = clinic;
            } else {
                state.clinic = {};
                state.uuid = '';
                state.reservation = {};
                Vue.set(state.modals, 'reservation', false);
            }
        },
        modal(state, modal) {
            Vue.set(state.modals, modal, !state.modals[modal])
        },
        largeModal(state, modal) {
            state.largeModal = modal;
            state.modals.large = !state.modals.large;
        },
        reservation(state, reservation) {
            state.reservation = reservation;
        },
        event(state, event) {
            state.event = event;
        },
        notify(state, days) {
            state.notify_days = days;
        },
        announcement(state, announcement) {
            state.announcement = {};
            if (!announcement.id) {
                state.announcement = {
                    title: '',
                    display: "0",
                    level: "info",
                    content: "",
                    clinicId: state.clinic.id
                };
            } else {
                for (let key of Object.keys(announcement)) {
                    state.announcement[key] = announcement[key];
                }
            }
        },
        uuid(state, uuid) {
            state.uuid = uuid;
        },
        uuidFetched(state, fetched) {
            state.uuidFetched = fetched;
        },
        loading(state, bool) {
            // WTF is this? why not boolean
            state.loading += bool ? 1 : -1;
        },
        loadingClinicUsers(state, loading) {
            state.loadingClinicUsers = loading;
        },
        day(state, day) {
            state.day = day;
        },
        referralOrder(state, referralOrder) {
            // Vue.set(state, 'referralOrder', referralOrder);
            state.referralOrder = referralOrder;
        },
        referralOrders(state, referralOrders) {
            state.referralOrders = referralOrders;
        },
        loadingReferralOrders(state, loadingReferralOrders) {
            state.loadingReferralOrders = loadingReferralOrders;
        },
        aside(state, day) {
            if (day) {
                state.asideDay = day;
                state.asideShow = true;
                if (state.calendars[state.asideCalRef]) {
                    state.calendars[state.asideCalRef].refetchEvents();
                    state.calendars[state.asideCalRef].gotoDate(day.toDate());
                }
            } else {
                state.asideShow = false;
            }
        },
        calendarWeekOffset(state, change) {
            state.calendarWeekOffset += change;
        },
        updateReferralOrder(state, order) {
            let index = state.referralOrders.findIndex(o => { return o.id === order.id });
            if (index >= 0) {
                order.notify = true;
                Object.assign(state.referralOrders[index], order);
            }
        }
    },
    actions: {
        init(context) {
            if (!context.state.userInitialized && !context.state.user.id) {
                context.state.userInitialized = true;
                if (localStorage.getItem('access_token')) {
                    context.state.api.fetch_me()
                        .then(resp => context.dispatch('loggedin', resp))
                }
            }
        },
        logout(context) {
            context.state.user = {};
            context.state.api.logout();
        },
        loggedin(context, user) {
            if (user) {
                let userExpiredRoles = [];
                user.roles = {clinics: {}, units: {}, system: false};

                for (let r of user.userRoles) {
                    // 0 is guest, 1 is user, 2 is admin
                    // Check if validity has expired and is before current time
                    if (r.validity && moment(r.validity).isSameOrBefore(moment())) {
                        userExpiredRoles.push(r);
                        continue;
                    }
                    if (r.userClinicId && !user.roles.clinics[r.userClinicId]) user.roles.clinics[r.userClinicId] = 1;
                    if (r.adminClinicId) user.roles.clinics[r.adminClinicId] = 2;
                    if (r.adminUnitId) user.roles.units[r.adminUnitId] = 3;
                    if (r.sysadmin) user.roles.system = true;
                }
                context.state.userExpiredRoles = userExpiredRoles;
                context.state.userInitialized = true;
                context.state.user = user;
                context.state.currentUser = user;
                context.state.calendarKey += 1;
            }
        },
        refetchEvents(context) {
            context.state.calendars['fullCalendar'].refetchEvents();
        },
        updateEvent(context, [event, slot]) {
            // Updates slot in the event and sets event title.
            if (!event) {
                event = context.state.event;
            }

            let title = '';
            if (slot.type === 1) {
                event.setProp( 'color', eventColor('yellow'));
                title = slot.title;
            } else {
                if (slot.freeSlotCount === 1 && slot.reservations.length > 0) {
                    title = slot.reservations[0].patientName;
                } else if (slot.freeSlotCount > 1 && slot.freeSlotCount <= slot.reservations.length){
                    if(context.state.clinic.eventBusy){
                        title = context.state.clinic.eventBusy;
                    }else {
                        title = slot.title;
                    }
                } else {
                    title = slot.title;
                }

                if (slot.reservation && slot.reservation.id) {
                    event.setProp( 'color', eventColor('blue'));
                    title = "Vaš termin";
                } else if (slot.freeSlotCount - slot.reservations.length > 0) {
                    event.setProp( 'color', eventColor('green'));
                } else {
                    event.setProp( 'color', eventColor('red'));
                }
            }

            event.setProp( 'title', title);
            event.setProp( 'startMoment', moment(slot.start));
            event.setProp( 'endMoment', moment(slot.end));
            event.setExtendedProp('slot', slot);

            // Refresh aside calendar, if it is visible.
            if (context.state.asideShow) {
                context.state.calendars[context.state.asideCalRef].refetchEvents();
            }
        },
        removeEvent(context) {
            context.commit('event', {});
            context.commit('reservation', {});
            context.dispatch('refetchEvents')
        },
        fetchReferralOrders({state, commit}) {
            commit('loadingReferralOrders', true);
            state.api.fetch_referral_orders(state.clinic.id)
                .then(resp => {
                    if (!resp.error && resp.length > 0) {
                        // Add notify flag to all orders
                        resp = resp.map(order => {
                            order.notify = true;
                            return order;
                        });
                        commit('referralOrders', resp);
                    }
                })
                .finally(() => {
                    commit('loadingReferralOrders', false);
                });
        },
        openReferralOrders({ dispatch, commit }) {
            commit('modal','referralList')
            dispatch('fetchReferralOrders')
        },
        referralOrder({commit}, order) {
            commit('referralOrder', order);
        },
        openReferralOrderModal({commit}) {
            commit('referralOrder', {});
            commit('modal', 'referralOrder');

        },
        updateReservation(context, reservation) {
            // TODO remove this and always use updateEvent, also always return whole slot from the backend and update it.
            context.state.reservation = reservation;
            let event = context.state.event;

            // Update reservation in the event reservations array.
            let reservations = event.extendedProps.slot.reservations;
            for (let i=0; i < reservations.length; i++) {
                if (reservations[i].id === reservation.id) {
                    reservations[i] = reservation;
                    break;
                }
            }
        },
        rebuildCalendar(context) {
            context.state.calendars['fullCalendar'].setOption('slotDuration', context.state.clinic.timeIntervalFormatted);
            context.state.calendars['fullCalendar'].setOption('minTime', context.state.clinic.timeMinFormatted);
            context.state.calendars['fullCalendar'].setOption('maxTime', context.state.clinic.timeMaxFormatted);
            context.state.calendars['fullCalendar'].setOption('weekends', context.state.clinic.weekends);
        },
        dayOptions(context, e) {
            context.commit('modal', 'day');
            let day = {left: e.pageX, top: e.pageY, day: e.srcElement.id.substr(6, 1)};
            context.commit('day', day);
        },
        refetch(context) {
            Object.keys(context.state.calendars).forEach(c => { context.state.calendars[c].refetchEvents() });
        },
        print(context, day) {
            if (!day) {
                context.state.print_class = '';
            } else {
                context.state.print_class = 'hide-days show-'+(day.weekday()+1);
            }
        },
        reloadUsers(context) {
            context.commit('loading', true);
            context.commit('loadingClinicUsers', true);
            context.state.api.fetch_users()
                .then(resp => {
                    if (!resp.error && resp.length > 0) {
                        context.commit('users', resp);
                    }
                    context.commit('loadingClinicUsers', false);
                    context.commit('loading', false);
                })
                .catch(() => {
                    context.commit('loadingClinicUsers', false);
                    context.commit('loading', false);
                });
        },
        units({ commit, state }, units) {
            return new Promise(function(resolve) {
                commit('units', units);
                resolve(state.units);
            });
        },
        reloadUnits(context) {
            context.commit('loading', true);
            context.state.api.fetch_public_units()
                .then(resp => {
                    if (!resp.error && resp.length > 0) {
                        context.commit('units', resp);
                    } else {
                        this.toastGenericFail(resp);
                    }
                    context.commit('loading', false);
                })
                .catch(() => {
                    context.commit('loading', false);
                });
        }
    },
    getters: {
        isUserAuthenticated: state => {
          return !!state.user.id
        },
        isLoading: state => {
            return state.loading > 0
        },
        isReady: state => {
            // clinic and user are loaded - ready to load calendar
            return state.userInitialized && state.clinic.id;
        },
        currentWeekRange: state => {
            state.calendarWeekOffset += 0;
            if (state.calendars['fullCalendar'] && state.calendars['fullCalendar'].view) {
                return {
                    start: Vue.moment(state.calendars['fullCalendar'].view.activeStart),
                    end: Vue.moment(state.calendars['fullCalendar'].view.activeStart).add(state.clinic.weekends ? 6 : 4, 'days')
                };
            } else {
                return {
                    start: Vue.moment().startOf('week'),
                    end: Vue.moment().startOf('week').add(state.clinic.weekends ? 6 : 4, 'days')
                }
            }
        },
        currentClinicRole: state => {
            if (!state.user.roles || !state.clinic.id) return 0;
            if (state.user.roles.system) return 2;
            if (state.user.roles.units[state.clinic.department.unitId]) return 2;
            return state.user.roles.clinics[state.clinic.id] || 0
        },
        currentClinic: state => {
            return state.clinic;
        },
        currentReservation: state => {
            return state.reservation;
        },
        bookingDisabled: state => {
            // Prevent adding a booking at time slot in the past
            return state.event.extendedProps.startMoment.isBefore(state.moment())
        }
    }
})

<template>
    <div>
        <Nav></Nav>
        <section class="section">
            <div class="container">
                <div class="columns">
                    <div class="column has-text-centered">
                        <h1 class="title is-3">Navodila</h1> <br />
                        Navodila za uporabo so v pripravi.
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Nav from "../components/Nav";
    import Toaster from "../components/mixins/Toaster";

    export default {
        name: "Home",
        components: {Nav},
        mixins: [Toaster],
        data () {
            return {}
        },
        methods: {
        },
        computed: {
        }
    }
</script>

<style scoped lang="scss">
    .column .title.is-3 {
        color: lighten(#369f7e, 10);
        font-weight: 400;
    }
</style>

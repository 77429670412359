<template>
    <article class="announcement" :class="'message is-' + announcement.level">
        <header class="message-header" @click="minimize">
            <p>{{announcement.title}}</p>
            <div class="message-buttons">
                <span v-show="!isPublic" title="Uredi" @click="edit(announcement)"><b-icon icon="edit"></b-icon></span>
                <span v-show="minimized" title="Prikaži sporočilo"><b-icon icon="plus-square" size="is-small"></b-icon></span>
                <span v-show="!minimized" title="Skrij sporočilo"><b-icon icon="minus-square" size="is-small"></b-icon></span>
            </div>
        </header>
        <section class="message-body">
            <div class="media">
                <div class="media-content content" v-bind:class="{minimized: minimized}" v-html="announcement.content"></div>
            </div>
        </section>
    </article>

</template>

<script>
    export default {
        name: "Announcement",
        props:['announcement'],
        data() {
            return {
                isPublic: !(this.$store.state.user.id && this.$store.getters.currentClinicRole > 0),
                minimized: this.announcement.display === '0'
            }
        },
        methods: {
            minimize() {
                this.minimized = !this.minimized;
            },
            edit(announcement) {
                this.$store.commit('modal', 'announcement');
                this.$store.commit('announcement', announcement);
            }
        }
    }
</script>

<style lang="scss">
    .message-buttons {
        .fa-minus-square,
        .fa-plus-square,
        .fa-edit {
            font-size: 19px;
        }
    }

    .media-content.content.minimized {
        white-space: nowrap;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>

<style scoped lang="scss">
    .media-content {
        white-space: pre-wrap;
    }
    .message-header {
        cursor: pointer;
    }
    .message-buttons .icon {
        margin-left: 1em;
        cursor: pointer;
    }
    .announcement .message-body {
        max-height: 300px;
        overflow-y: auto;
    }
</style>

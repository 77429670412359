<template>
    <FullCalendar :ref="$parent.refName"
                  defaultView="timeGridWeek"
                  height="parent"

                  locale="sl"
                  :firstDay="1"
                  :plugins="$parent.calendarPlugins"
                  :buttonText="{today: 'danes'}"
                  :slotLabelInterval="'01:00'"
                  :slotLabelFormat="$parent.labelFormat"

                  :weekends="$parent.weekends"
                  :minTime="$parent.minTime"
                  :maxTime="$parent.maxTime"
                  :slotDuration="$parent.interval"

                  :header="$parent.header"
                  :allDaySlot="$parent.allDaySlot"
                  :columnHeader="$parent.columnHeader"
                  :columnHeaderFormat="$parent.columnHeaderFormat"

                  :nowIndicator="$parent.nowIndicator"

                  :selectable="$parent.selectable"
                  :selectOverlap="$parent.selectOverlap"

                  :eventSources="$parent.eventSources"
                  :eventRender="eventRender"
                  :editable="editable"
                  :eventOverlap="eventOverlap"
                  :eventAllow="eventAllow"

                  @eventDrop="eventEdit"
                  @eventResize="eventEdit"

                  @select="$parent.select"
                  @eventClick="$parent.eventClick"
    />
</template>

<script>
    import FullCalendar from '@fullcalendar/vue';
    export default {
        name: "CalendarComponent",
        components: {
            FullCalendar
        },
        props: ['editable'],
        methods: {
            eventEdit: function(event) {
                if (this.editable) {
                    this.$parent.eventDropOrResize(event);
                }
            },
            eventOverlap: function(stillEvent, movingEvent) {
                // Two slots can't overlap
                return !(stillEvent.id && movingEvent.id);
            },
            eventAllow(stillEvent) {
                // Slot can't be moved to the past
                return !this.$moment(stillEvent.start).isBefore(this.$moment(), 'day');
            },
            eventRender(info) {
                let extendedProps = info.event.extendedProps;
                let slot = extendedProps.slot;
                let title = info.el.getElementsByClassName("fc-content");
                let $fcTime = info.el.getElementsByClassName("fc-time")[0];

                if (extendedProps.isScheduleSlot && extendedProps.freeSlotCount > 1) {
                    $fcTime.innerHTML += `<span class="icon-users">${extendedProps.freeSlotCount} <i class="fas fa-users has-text-white"></i></span>`;
                }

                if (title.length <= 0 || !slot) {
                    return
                }

                let reservations = slot.reservations;
                if (slot.type !== 1 && slot.freeSlotCount > 1) {
                    $fcTime.innerHTML += `<span class="icon-users">${reservations.length}/${slot.freeSlotCount} <i class="fas fa-users has-text-white"></i></span>`;
                }

                if (reservations.length === 0) {
                    return
                }

                if (this.$store.getters.isUserAuthenticated) {
                    title[0].classList.add("reservation");
                    if (reservations.length > 1) {
                        let text = '<div class="hover-info">';
                        let printText = '<br /><div class="only-print el-note">';

                        for (let i = 0; i < reservations.length; i++) {
                            let r = reservations[i];
                            let patient = `<strong>${i + 1}) Ime:</strong> ${r.patientName}`;

                            text += patient + '<br />';
                            if (r.patientNote) {
                                patient += ` <strong>Opomba:</strong> ${r.patientNote}`;
                            }
                            printText += `${patient}<br />`;
                        }

                        title[0].innerHTML += text + '</div>';
                        title[0].innerHTML += printText + '</div>';
                    } else {
                        let reservation = reservations[0];

                        let patientName = reservation.patientName;
                        let patientNote = reservation.patientNote;
                        let patientFileNumber = reservation.patientFileNumber;
                        let patientPhone = reservation.patientPhone;
                        let optionalText = '';

                        if (patientFileNumber) optionalText += `<br/><strong>Št. kartoteke:</strong> ${patientFileNumber}`;
                        if (patientPhone) optionalText += `<br/><strong>Telefon:</strong> ${patientPhone}`;
                        if (patientNote) optionalText += `<br/><strong>Opomba:</strong> ${patientNote}`;

                        title[0].innerHTML += ` <div class="hover-info"><strong>Ime:</strong> ${patientName}${optionalText} </div>`;

                        if (reservation.attended) {
                            title[0].innerHTML += ' <span class="fa-stack has-text-primary"><i class="fas fa-circle fa-stack-2x"></i><i class="fas fa-check fa-stack-1x has-text-white"></i></span>'
                        }
                        if (patientNote) {
                            title[0].innerHTML += ` <div class='only-print el-note'><strong>Opomba:</strong> ${patientNote}</div>`;
                        }
                    }
                }
            }
        },
        mounted() {
            let cal = this.$refs[this.$parent.refName].getApi();
            this.$store.commit('calendar', {'name': this.$parent.refName, 'value': cal})
        }
    }
</script>

<style lang="scss">
    span.fa-stack {
        font-size: 0.5rem;
        position: absolute;
        right: 3px;
        top: 4px;
    }

    span.icon-users {
        font-size: 0.7rem;
        //position: absolute;
        float: right;
        right: 6px;
        top: 3px;
    }

    .fc-time-grid-event .fc-time {
        font-size: 0.9em !important;
    }

    .fc-time-grid-event .fc-title {
        font-size: 1.01em !important;
    }

    .fc-time-grid-event.fc-short .fc-time,
    .fc-time-grid-event.fc-short .fc-title {
        font-size: 0.95em !important;
    }
    .fc-time-grid-event.fc-short .fc-time {
        font-size: 0.95em !important;
    }

    .nurse .fc-event.fc-draggable.fc-resizable:hover .fc-content.reservation {
        overflow: visible;
    }

    .nurse .fc-content.reservation .hover-info {
        font-size: 1.1em;
        padding: 7px;
        width: 100%;
        line-height: 1.5;
        font-weight: 400;
        overflow: visible;
        display: none;
        background-color:rgb(255, 255, 255);
        color: #4a4a4a;
        position: absolute;
        top: 20px;
        left: 0;
        border-radius: 5px;
        border: 1px solid #ddd;
        white-space: break-spaces;
        word-break: break-all;
    }

    .nurse a.fc-time-grid-event.fc-event:hover {
        z-index: 50 !important;
        width: 100%;
        position: absolute;
    }

    .nurse a.fc-time-grid-event.fc-event:hover .fc-content.reservation .hover-info {
        display: block !important;
    }

    /*
    .nurse .fc-time-grid-event .fc-content {
        overflow: visible !important;
    }
    */

</style>

<template>
    <div>
        <div class="level">
            <div class="level-left"></div>
            <div class="level-right">
                <b-button class="button is-success" v-if="$store.state.user.roles.system" icon-left="plus" @click="newClinic">Nova ambulanta</b-button>
            </div>
        </div>

        <b-field>
            <b-input placeholder="Išči po imenu ambulante" type="search" icon="search" v-model="search_clinic"></b-input>
        </b-field>

        <b-table
            :data="clinics"
            :paginated="false"
            :sort-icon="'arrow-up'"
            :sort-icon-size="'is-small'"
            default-sort="title">
            <b-table-column field="title" label="Ime" v-slot="props" sortable>
                <a :href="'/'+props.row.id">{{ props.row.title }}</a>
            </b-table-column>
            <b-table-column field="email" label="E-naslov" v-slot="props" sortable>
                {{ props.row.email }}
            </b-table-column>
            <b-table-column field="unit" label="Enota" v-slot="props" sortable>
                {{ props.row.unit }}
            </b-table-column>
            <b-table-column v-slot="props">
                <b-button
                    class="button is-infon"
                    icon-pack="fas"
                    icon-left="pencil-alt"
                    @click="openClinicAdmin(props.row)">
                    Uredi
                </b-button>
            </b-table-column>
        </b-table>
        <b-modal :active.sync="$store.state.modals.clinicAdministration" has-modal-card>
            <ClinicAdministrationModal></ClinicAdministrationModal>
        </b-modal>
    </div>
</template>

<script>
    import {normalizeString} from "../../helpers";
    import ClinicAdministrationModal from "../../components/modals/ClinicAdministrationModal";
    export default {
        name: "Clinics",
        components: {ClinicAdministrationModal},
        data () {
            return {
                search_clinic: ''
            }
        },
        methods: {
            clinic(clinic) {
                if (clinic) {
                    return {
                        id: clinic.id,
                        title: clinic.title,
                        unitId: clinic.unitId,
                        departmentId: clinic.departmentId,
                        users: clinic.users
                    }
                } else {
                    return {
                        id: null,
                        title: '',
                        unitId: null,
                        departmentId: null,
                        users: []
                    }
                }
            },
            openClinicAdmin(clinic) {
                this.$store.commit('administratingClinic', this.clinic(clinic));
                this.$store.commit('modal', 'clinicAdministration');
            },
            newClinic() {
                this.openClinicAdmin(this.clinic())
            }
        },
        computed: {
            clinics() {
                return this.$store.state.clinics_search_list.filter((option) => {
                    return option.searchTitle.indexOf(normalizeString(this.search_clinic)) >= 0
                })
            }
        },
        mounted() {
            this.$store.dispatch('reloadUnits');
        }
    }
</script>

<style scoped>

</style>

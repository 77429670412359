<template>
    <div class="modal-card" :class="customClass">
        <header class="modal-card-head">
            <slot name="header"></slot>
        </header>

        <section class="modal-card-body">
            <slot></slot>
        </section>

        <footer class="modal-card-foot">
            <slot name="footer"></slot>
        </footer>
    </div>
</template>

<script>
    import Toaster from "../mixins/Toaster";
    export default {
        name: "Modal",
        mixins: [Toaster],
        props: ['customClass']
    }
</script>


<style scoped lang="scss">
    .modal-card input {
        font-size: 0.8em;
    }
    .modal-card .field.is-grouped.is-grouped-multiline {
        margin-bottom: 0.75rem;
    }
    .modal-card-head {
        flex-direction: column;
        align-items: baseline;
    }
    .modal-card-foot {
        justify-content: space-between;
    }

    .wide-modal {
        width: 900px;
    }

    @media screen and (max-width: 768px) {

        .modal-card .field.is-grouped .field {
            //width: 100%;
        }

        .modal-card-foot {
            flex-direction: column;

            button.button {
                width: 100%;
                margin: 0.2em 0;
            }
        }
    }
</style>


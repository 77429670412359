<template>
    <div>
        <div class="level">
            <div class="level-left"></div>
            <div class="level-right">
                <b-button class="button is-success" icon-left="plus" v-if="$store.state.user.roles.system" @click="newUnit">Ustvari novo enoto</b-button>
            </div>
        </div>

        <b-field>
            <b-input placeholder="Išči po imenu enote" type="search" icon="search" v-model="searchUnit"></b-input>
        </b-field>

        <b-table
            :data="units"
            :paginated="false"
            :sort-icon="'arrow-up'"
            :sort-icon-size="'is-small'"
            default-sort="title">
            <b-table-column field="title" label="Ime" v-slot="props" sortable>
                {{ props.row.title }}
            </b-table-column>
            <b-table-column v-slot="props">
                <b-button class="button is-info" icon-pack="fas" icon-left="pencil-alt" @click="openUnitAdmin(props.row)">
                    Uredi
                </b-button>
            </b-table-column>
        </b-table>

        <b-modal :active.sync="$store.state.modals.unitAdministration" has-modal-card>
            <UnitAdministrationModal @reload="loadData()"></UnitAdministrationModal>
        </b-modal>
    </div>
</template>

<script>
    import {normalizeString} from "@/helpers";
    import UnitAdministrationModal from "../modals/UnitAdministrationModal";
    export default {
        name: "Units",
        components: { UnitAdministrationModal },
        data () {
            return {
                searchUnit: ''
            }
        },
        methods: {
            openUnitAdmin(unit) {
                this.$store.commit('administratingUnit', unit);
                this.$store.commit('modal', 'unitAdministration');
            },
            newUnit() {
                this.openUnitAdmin({})
            },
            loadData() {
                this.$store.commit('loading', true);
                this.$store.state.api.fetch_public_units()
                    .then(resp => {
                        if (!resp.error && resp.length > 0) {
                            this.$store.commit('units', resp);
                        } else {
                            this.toastGenericFail(resp);
                        }
                        this.$store.commit('loading', false);
                    })
                    .catch(() => {
                        this.$store.commit('loading', false);
                    });
            }
        },
        computed: {
            units() {
                return this.$store.state.units.filter((option) => {
                    return option.searchTitle.indexOf(normalizeString(this.searchUnit)) >= 0
                })
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>

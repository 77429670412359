<template>
    <Modal>
        <template #header>
            <h2 class="title is-4">Izberite obdobje</h2>
        </template>

        <form @submit="send">
            <div class="columns">
                <div class="column">
                    <b-datepicker v-model="dates" inline range :unselectable-days-of-week="[0, 6]"></b-datepicker>
                </div>
                <div class="column">
                    <b-switch v-model="fullDay">Cel dan</b-switch>
                    <br/><br/>
                    <b-field label="Od" label-position="on-border" expanded>
                        <b-input type="time" v-model="timeMin" :disabled="fullDay"></b-input>
                    </b-field>
                    <b-field label="Do" label-position="on-border" expanded>
                        <b-input type="time" v-model="timeMax" :disabled="fullDay"></b-input>
                    </b-field>
                </div>
            </div>
        </form>

        <template #footer>
            <button class="button " type="button" @click="$parent.close()">Prekliči</button>
            <button class="button is-primary" @click="send">Nadaljuj</button>
        </template>
    </Modal>
</template>

<script>
    import Modal from "./Modal";
    import Toaster from "../mixins/Toaster";

    export default {
        name: 'TimespanPickerModal',
        components: { Modal },
        mixins: [Toaster],
        data() {
            return {
                dates: [],
                fullDay: true,
                timeMin: this.$store.state.clinic.timeMinFormatted,
                timeMax: this.$store.state.clinic.timeMaxFormatted

            }
        },
        methods: {
            send(e) {
                e.preventDefault();
                if (!this.formValid) {
                    this.formIncompleteError();
                    return;
                }

            }
        },
        computed: {
            formValid() {
                return this.dates.length > 0
            }
        }
    }
</script>

<template>
    <Modal>
        <template #header>
            <h1 class="title is-5">Urejanje obvestila</h1>
        </template>

        <form @submit="send">
            <b-field label="Naslov" label-position="on-border" expanded>
                <b-input type="text" v-model="announcement.title"></b-input>
            </b-field>

            <b-field label="Začetno stanje" label-position="on-border" expanded title="Prikaz obvestila, ko se stran odpre.">
                <b-select placeholder="Izberite možnost" v-model="announcement.display" expanded>
                    <option value="0">Zaprt</option>
                    <option value="1">Odprt</option>
                </b-select>
            </b-field>

            <b-field label="Barva" label-position="on-border" expanded>
                <b-select placeholder="Izberite barvo" v-model="announcement.level" expanded>
                    <option value="info">Modra</option>
                    <option value="success">Zelena</option>
                    <option value="warning">Rumena</option>
                    <option value="danger">Rdeča</option>
                </b-select>
            </b-field>

            <b-field label="Vsebina" label-position="on-border">
                <b-input type="textarea" v-model="announcement.content" rows="10" cols="80"></b-input>
            </b-field>
        </form>

        <template #footer>
            <button class="button" type="button" @click="$parent.close()">Prekliči</button>
            <b-button class="button is-danger" icon-pack="fas" icon-left="trash" type="button" @click="remove" v-if="announcement.id">Izbriši</b-button>
            <button class="button is-primary" @click="send">Shrani</button>
        </template>
    </Modal>
</template>

<script>
    import Modal from "./Modal";
    import Toaster from "../mixins/Toaster";

    export default {
        extends: Modal,
        name: 'BookModal',
        components: { Modal },
        mixins: [Toaster],
        props: [],
        data() {
            return {
                announcement: this.$store.state.announcement
            }
        },
        methods: {
            send(e) {
                e.preventDefault();

                this.$store.state.api.post_announcement(this.$store.state.clinic.id, this.announcement)
                    .then(resp => {
                        if (resp.id) {
                            this.$parent.close();
                            this.$router.go();
                        } else {
                            this.toastGenericFail(resp)
                        }
                    })

            },
            remove() {
                this.$buefy.dialog.confirm({
                    confirmText: 'Izbriši',
                    cancelText: 'Prekliči',
                    type: 'is-danger',
                    message: 'Ali ste prepričani, da želite izbrisati to obvestilo? Tega dejanja ni mogoče razveljaviti.',
                    onConfirm: () => this.$store.state.api.delete_announcement(this.$store.state.clinic.id, this.announcement.id)
                        .then(resp => {
                            if (resp && !resp.error) {
                                this.$parent.close();
                                this.$router.go();
                            } else {
                                this.toastGenericFail(resp)
                            }
                        })
                });
            }
        }
    }
</script>


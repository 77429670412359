<template>
    <div class="clinic-view">
        <section class="aside" v-if="$store.state.asideShow">
            <SideCal></SideCal>
        </section>
        <section class="main">
            <Nav></Nav>
            <div class="section" v-if="$store.state.clinic.id">
                <div class="container" :class="$store.state.print_class">
                    <span class="title is-4 print-only">{{$store.state.clinic.title}}<span class="title is-7 is-pulled-right"> {{$moment().format('D. M. YYYY, HH:mm')}}</span></span>
                    <Nurse v-if="$store.state.user.id && $store.getters.currentClinicRole > 0"></Nurse>
                    <Patient v-else></Patient>
                </div>
            </div>
            <div class="empty-content" v-else>
                <h2 class="title is-4" v-if="!$store.state.loading">Prišlo je do napake</h2>
            </div>
            <b-modal :active.sync="$store.state.modals.booking" has-modal-card>
                <BookModal></BookModal>
            </b-modal>
            <b-modal :active.sync="$store.state.modals.bookingMulti" has-modal-card>
                <BookMultiModal></BookMultiModal>
            </b-modal>
            <b-modal :active.sync="$store.state.modals.bookingMultiEdit" has-modal-card>
                <BookMultiEditModal></BookMultiEditModal>
            </b-modal>
            <b-modal :active.sync="$store.state.modals.reservation" has-modal-card>
                <ReservationModal></ReservationModal>
            </b-modal>
            <b-modal :active.sync="$store.state.modals.referralOrder" has-modal-card>
                <ReferralOrderModal></ReferralOrderModal>
            </b-modal>
            <b-loading :is-full-page="true" :active.sync="$store.getters.isLoading"></b-loading>
        </section>
    </div>
</template>

<script>
    import BookModal from '../components/modals/BookModal'
    import BookMultiModal from '../components/modals/BookMultiModal'
    import BookMultiEditModal from '../components/modals/BookMultiEditModal'
    import Nav from '../components/Nav'
    import Patient from "../components/clinic/Patient";
    import Nurse from "../components/clinic/Nurse";
    import ReservationModal from "../components/modals/ReservationModal";
    import ReferralOrderModal from "../components/modals/ReferralOrderModal";
    import SideCal from "../components/SideCal";

    export default {
        name: "Clinic",
        components: { SideCal, ReservationModal, Nurse, Patient, Nav, BookModal, BookMultiModal, BookMultiEditModal, ReferralOrderModal },
        data () {
            return {
                clinicSettingsOpen: false,
                bookingFormOpen: false,
                clinicDataReady: false
            }
        },
        methods: {
            getClinic () {
                this.$store.commit('loading', true);
                this.$store.state.api.fetch_public_clinic(this.$route.params.clinicId)
                    .then(resp => {
                        if (resp.id) {
                            this.$store.commit('clinic', resp);
                            if (!this.clinicDataReady) {
                                this.clinicDataReady = true
                            } else {
                                this.$store.dispatch('rebuildCalendar');
                            }
                        }
                        this.$store.commit('loading', false);
                    });
            },
            closeDayMenu () {
                if (this.$store.state.modals['day']) {
                    this.$store.commit('modal', 'day');
                }
            }
        },
        computed: {
            calendarKey() {
                return this.$store.state.calendarKey
            },
            dayMenuPos() {
                if (!this.$store.state.day) return '';
                return 'left: '+this.$store.state.day.left+'px; top: '+this.$store.state.day.top+'px;';
            }
        },
        created() {
            // Clear current clinic data in store
            this.$store.commit('clinic');

            // Fetch current clinic
            this.getClinic();

            if (this.$route.query.uuid) {
                if (this.$store.state.uuid.length > 0 && this.$route.query.uuid !== this.$store.state.uuid) {
                    this.$route.query.uuid = '';
                } else {
                    this.$store.commit('uuid', this.$route.query.uuid);
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .print-only {
        display: none;
    }
    .floating-menu {
        position: absolute;
        z-index: 100;
    }
    .no-margin {
        margin: 0;
    }
    .clinic-view {
        display: flex;
    }
    .main>.section {
        padding-bottom: 1rem;
    }
    section.aside {
        position: relative;
        width: 15em;
        height: 100vh;
        background-color: #fcfcfc;
        flex-shrink: 0;
        &>div {
            width: 15em;
            height: 100%;
            position: fixed;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            box-shadow: 1px 1px 15px #ddd;
        }
    }
    section.main {
        width: 100%;
    }
</style>

<style lang="scss">
    @import '../../node_modules/@fullcalendar/core/main.css';
    @import '../../node_modules/@fullcalendar/daygrid/main.css';
    @import '../../node_modules/@fullcalendar/timegrid/main.css';
    @import "../colors.scss";

    .large-modal .animation-content{
        width: 95%;
    }

    .fc {
        .fc-head table th {
            background-color: #f5f5f5;
            padding: 1em 0 1.2em 0;
            text-align: center;
        }
        table, table th, table th td {
            border: 0 hidden;
        }
        table tr td {
            border-left: 0 hidden;
            border-right: 0 hidden;
        }
        .busy {
            cursor: not-allowed;
        }
    }

    .fc .fc-button.fc-button-primary {
        background-color: $primary;
        border-color: $primary;

        &:focus,
        &:active,
        &:focus:active {
             box-shadow: none;
        }

        &:active {
            background-color: darken($primary, 10);
            border-color: darken($primary, 10);
        }
    }

    .fc .fc-time-grid-event.fc-event.reservation {
        left: 0 !important;
        right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        z-index: 10 !important;
    }

    .fc-toolbar.fc-header-toolbar {
        margin-bottom: 0.5em;
    }

    @media screen and (max-width: 768px) {
        .fc-toolbar.fc-header-toolbar {
            flex-direction: column-reverse;

            .fc-right {
                display: none;
            }
        }
    }

    @media print {
        @page {
            margin: 0.5cm;
            size: portrait;
        }
        nav,
        .aside,
        .fc-toolbar,
        .nurse .toolbar,
        .nurse .clinic-head,
        .patient .clinic-head {
            display: none;
        }
        .print-only {
            display: block !important;
        }

        .calendar-wrapper {
            height: inherit !important;
            overflow: visible;
        }

        .fc-time-grid-container {
            overflow: visible !important;
        }
        .title {
            margin-bottom: 0.5em !important;
        }
        .fc .fc-head table th {
            padding: 0;
        }
        .fc .fc-head table th.fc-axis {
            width: 0 !important;
        }
        .section {
            padding: 1rem 0.5rem;
        }
        .hide-days .fc { max-width: 6cm !important;}
        .hide-days.show-0 .fc { .fc-widget-header tr th:not(:nth-child(1)), .fc-content-skeleton tr td:not(:nth-child(1)) { display: none;        .hide-days .fc { max-width: 5cm;}}}
        .hide-days.show-1 .fc { .fc-widget-header tr th:not(:nth-child(2)), .fc-content-skeleton tr td:not(:nth-child(2)) { display: none;        .hide-days .fc { max-width: 5cm;}}}
        .hide-days.show-2 .fc { .fc-widget-header tr th:not(:nth-child(3)), .fc-content-skeleton tr td:not(:nth-child(3)) { display: none;        .hide-days .fc { max-width: 5cm;}}}
        .hide-days.show-3 .fc { .fc-widget-header tr th:not(:nth-child(4)), .fc-content-skeleton tr td:not(:nth-child(4)) { display: none;        .hide-days .fc { max-width: 5cm;}}}
        .hide-days.show-4 .fc { .fc-widget-header tr th:not(:nth-child(5)), .fc-content-skeleton tr td:not(:nth-child(5)) { display: none;        .hide-days .fc { max-width: 5cm;}}}
        .hide-days.show-5 .fc { .fc-widget-header tr th:not(:nth-child(6)), .fc-content-skeleton tr td:not(:nth-child(6)) { display: none;        .hide-days .fc { max-width: 5cm;}}}
        .hide-days.show-6 .fc { .fc-widget-header tr th:not(:nth-child(7)), .fc-content-skeleton tr td:not(:nth-child(7)) { display: none; }}
    }
</style>

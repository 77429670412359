import 'core-js'
import Vue from 'vue'
import Buefy from 'buefy'

import App from './App.vue'
import router from './router'
import store from './store'

import '@fortawesome/fontawesome-free/css/all.css'

Vue.config.productionTip = false;

Vue.use(Buefy, {
    defaultIconPack: 'fas'
});
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app');
